import { useThreads } from 'src/hooks';
import { ConversationRole, Message } from 'src/types';

import { AvatarImage } from 'src/components/AvatarImage';
import { UserImage } from 'src/components/UserImage';

const SVG_SIZE = 32;

interface MessageAuthorPictureProps {
  message: Message;
  canShowProgress?: boolean;
}

export const MessageAuthorPicture = ({
  message,
  canShowProgress,
}: MessageAuthorPictureProps) => {
  const { isSubmitHappened } = useThreads();

  const { role, persona } = message;

  const isUser = role === ConversationRole.USER;

  return (
    <div className="nj-message-author-picture">
      {isUser ? (
        <div className="nj-message-author-picture--icon">
          <UserImage size={SVG_SIZE} />
        </div>
      ) : (
        <AvatarImage persona={persona} size={SVG_SIZE} />
      )}

      {/* TODO(olha): check necessary of using metaHumanTalking from SubmitTrigger & create another handler to show progress */}
      {isSubmitHappened &&
        role !== ConversationRole.USER &&
        canShowProgress && (
          <span className="nj-message-author-picture--spinner" />
        )}
    </div>
  );
};
