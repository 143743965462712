import { useState, useRef, useEffect } from 'react';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import classNames from 'classnames';
import { Button } from 'src/components/Button';
import { useBreakpoint } from 'src/hooks';

const SVG_SIZE = 24;
const SCROLL_GAP = 250;

type TabsProps<T extends string> = {
  tabsList: T[];
  activeTab: T;
  onTabChange: (tab: T) => void;
  className?: string;
};

export const Tabs = <T extends string>({
  tabsList,
  activeTab,
  onTabChange,
  className,
}: TabsProps<T>) => {
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);

  const { isMobile } = useBreakpoint();

  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const checkScroll = () => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(
        container.scrollLeft < container.scrollWidth - container.clientWidth,
      );
    }
  };

  const handleScroll = (direction: 'left' | 'right') => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      const newScrollPosition =
        direction === 'right'
          ? container.scrollLeft + SCROLL_GAP
          : container.scrollLeft - SCROLL_GAP;

      container.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });

      checkScroll();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  useEffect(() => {
    const container = tabsContainerRef.current;

    if (container) {
      container.addEventListener('scroll', checkScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      checkScroll();
    }
  }, [isMobile]);

  return (
    <div className="nj-tabs--wrapper">
      {showLeftArrow && (
        <Button
          className="nj-tabs--arrow-button left"
          onClick={() => handleScroll('left')}
        >
          <CaretLeft size={SVG_SIZE} />
        </Button>
      )}

      <div ref={tabsContainerRef} className={classNames('nj-tabs', className)}>
        <nav className="nj-tabbed-layout--tabs">
          {tabsList.map((tab, index) => {
            return (
              <div
                className={classNames('nj-tabbed-layout--tab', {
                  selected: activeTab === tab,
                })}
                onClick={() => onTabChange(tab)}
                key={`tab-${tab}-${index}`}
              >
                {tab}
              </div>
            );
          })}
        </nav>
      </div>

      {showRightArrow && (
        <Button
          className="nj-tabs--arrow-button right"
          onClick={() => handleScroll('right')}
        >
          <CaretRight size={SVG_SIZE} />
        </Button>
      )}
    </div>
  );
};
