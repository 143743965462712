import { createApi } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'reduxjs-toolkit-persist';
import { baseConvEngineQuery } from './config';
import { addConversationMessage } from '../slices/conversationSlice';
import { Message, ApiTask } from '../../types';
import { tasksApi } from './tasksApi';

enum ConvEngineTags {
  ConvMessage = 'ConvMessage',
}

interface AddMessageConvEngineRequest {
  message: Message;
  taskId: string;
}

// https://conversation-engine.beta.myninja.ai/conv
export const convEngineApi = createApi({
  reducerPath: 'convEngineApi',
  tagTypes: [ConvEngineTags.ConvMessage],
  baseQuery: baseConvEngineQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints: (builder) => ({
    addMessageToUserConversation: builder.mutation<
      Message,
      AddMessageConvEngineRequest
    >({
      invalidatesTags: [ConvEngineTags.ConvMessage],
      query: ({ message, taskId }) => ({
        url: '/',
        method: 'POST',
        body: message,
      }),
      onQueryStarted: async ({ message, taskId }, { dispatch }) => {
        if (taskId === 'default') {
          dispatch(addConversationMessage(message));
        } else {
          dispatch(
            tasksApi.util.updateQueryData(
              'getTasksByUserId',
              taskId,
              (tasks) => {
                const updatedTasks = tasks.map((task: ApiTask) => {
                  if (task.task_id === taskId) {
                    return {
                      ...task,
                      messages: [
                        ...(task.conversation?.messages || []),
                        message,
                      ],
                    };
                  }
                  return task;
                });
                return updatedTasks;
              },
            ),
          );
        }
      },
    }),
  }),
});

export const { useAddMessageToUserConversationMutation } = convEngineApi;
