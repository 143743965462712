import { useState } from 'react';
import { Message } from 'src/types';
import { useSession, useThreads } from 'src/hooks';
import { useSendMessagePDUMutation } from 'src/store/services';
import { useAppDispatch, updateTaskChatMessage } from 'src/store';
import { toPDUMessage } from 'src/utils';
import { toast } from 'react-toastify';
import log from 'src/utils/logger';

type UpdateTaskMessageByPDUArgs = {
  successMessage: string;
  errorMessage: string;
};

export const useUpdateTaskMessageByPDU = ({
  successMessage,
  errorMessage,
}: UpdateTaskMessageByPDUArgs) => {
  const dispatch = useAppDispatch();

  const { appUser } = useSession();
  const { currentTask } = useThreads();
  const [sendPDUMessage] = useSendMessagePDUMutation();

  const [isSubmitted, setSubmitted] = useState<boolean>(false);

  const onSubmit = async (message: Message) => {
    if (!currentTask?.task_id) {
      return;
    }

    try {
      const envelope = toPDUMessage(JSON.stringify(message), appUser.user_id);
      await sendPDUMessage({
        message: envelope,
      }).unwrap();

      const updatedMessage = {
        ...message,
        payload: {
          ...message.payload,
          executed: true,
        },
      };

      dispatch(
        updateTaskChatMessage({
          task_id: currentTask?.task_id,
          user_id: appUser.user_id,
          message: updatedMessage as Message,
        }),
      );
      toast(successMessage);
      setSubmitted(true);
    } catch (e: unknown) {
      log.error(e);
      setSubmitted(false);
      toast(errorMessage);
    }
  };

  return { isSubmitted, onSubmit };
};
