import { DebugData, IntentClassifier, MatchingItem, InputTypes } from '../data';
import { isNumber, isString, isObject } from './general.guard';
import { ApiContact } from '../api.extended';
import log from 'src/utils/logger';

/**
 * Tests data against IntentClassifier data type.
 * @param data unknown
 * @returns boolean
 */
export function isIntentClassifier(data: unknown): data is IntentClassifier {
  const classifier = data as IntentClassifier;

  if (!('class_name' in classifier && isString(classifier.class_name))) {
    return false;
  }

  if (!('class_id' in classifier && isNumber(classifier.class_id))) {
    return false;
  }

  if (
    !('probabilities' in classifier && Array.isArray(classifier.probabilities))
  ) {
    return false;
  }

  for (const probability of classifier.probabilities) {
    if (!isNumber(probability)) {
      return false;
    }
  }

  if (!('classified' in classifier && isString(classifier.classified))) {
    return false;
  }

  return true;
}

/**
 * Tests data against DebugData type.
 * @param data unknown
 * @returns boolean
 */
export function isDebugData(data: unknown): data is DebugData {
  const debug = data as DebugData;

  if (!('API' in debug && isString(debug.API))) {
    return false;
  }

  if (
    !(
      'INTENT_CLASSIFIER' in debug &&
      isIntentClassifier(debug.INTENT_CLASSIFIER)
    )
  ) {
    return false;
  }

  if (!('SCHEDULE_PARSER' in debug && isString(debug.SCHEDULE_PARSER))) {
    return false;
  }

  if (!('GPT_INTENT' in debug && isString(debug.GPT_INTENT))) {
    return false;
  }

  return true;
}

/**
 * isMatchingItem() typeguards data to be of MatchingItem type.
 * @param data unknown
 * @returns boolean
 */
export function isMatchingItem(data: unknown): data is MatchingItem {
  const matchingItem = data as MatchingItem;

  if (!isObject(matchingItem)) {
    return false;
  }

  if (!('value' in matchingItem && isString(matchingItem.value))) {
    return false;
  }

  if (!('option' in matchingItem && isString(matchingItem.value))) {
    return false;
  }

  return true;
}

/**
 * isApiContact() tests data against ApiContact data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiContact(data: unknown): data is ApiContact {
  const contact = data as ApiContact;

  if (!('email' in contact && isString(contact.email))) {
    log.debug(
      `isApiContact > mandatory fields (email) missing or not a string in ${JSON.stringify(
        contact,
      )}`,
    );
    return false;
  }

  if (!('first_name' in contact && isString(contact.first_name))) {
    log.debug(`isApiContact > invalid first_name ${JSON.stringify(contact)}`);
    return false;
  }

  if ('last_name' in contact && !isString(contact.last_name)) {
    log.debug(`isApiContact > invalid last_name ${JSON.stringify(contact)}`);
    return false;
  }

  if ('color' in contact && !isString(contact.color)) {
    log.debug(`isApiContact > invalid color ${JSON.stringify(contact)}`);
    return false;
  }

  if ('avatar' in contact && !isString(contact.avatar)) {
    log.debug(`isApiContact > invalid avatar ${JSON.stringify(contact)}`);
    return false;
  }

  return true;
}

/**
 * isInputTypes() tests data against InputTypes data type.
 * @param data unknown
 * @returns boolean
 */
export function isInputTypes(data: unknown): data is InputTypes {
  return ['text', 'password', 'number', 'email'].includes(data as string);
}
