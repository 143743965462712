import { Globe } from '@phosphor-icons/react';
import { ChatModeSidePanel } from 'src/types';
import { useSession, useTheme } from 'src/hooks';
import { CommonNavigationButton } from '../CommonNavigationButton';

export const LanguageButton = () => {
  const { chatModeSidePanel } = useSession();

  const { isSettingsPanelExpanded } = useTheme();

  return (
    <CommonNavigationButton
      sidePanelType={ChatModeSidePanel.VOICE_LANGUAGE}
      title="Language"
      IconComponent={Globe}
      isButtonSelected={
        isSettingsPanelExpanded &&
        chatModeSidePanel === ChatModeSidePanel.VOICE_LANGUAGE
      }
    />
  );
};
