import { useEffect, useCallback, useContext } from 'react';
import classNames from 'classnames';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useContacts, useGoogleResourcesAccess, useVisible } from 'src/hooks';
import { KeyCodes } from 'src/types';
import { getInitials } from 'src/utils';
import { ContactsAccessDeniedModal } from 'src/components/ContactsAccessDeniedModal';
import { Icon } from 'src/components/Icon';
import { useQuickPickerControls } from '../../hooks/useQuickPickerControls';
import AuthContext from 'src/contexts/AuthContext';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';

const SVG_SIZE = 32;

interface QuickContactPickerProps {
  isOpen: boolean;
  cursorPosition: number;
  onClose: () => void;
  setCursorPosition: (position: number) => void;
}

export const QuickContactPicker = ({
  isOpen,
  cursorPosition,
  onClose,
  setCursorPosition,
}: QuickContactPickerProps) => {
  const { threadInputBoxValue, setThreadInputBoxValue } = useContext(
    ThreadInputBoxContext,
  );
  const { isGuestAccess } = useContext(AuthContext);
  const { canAccessContacts } = useGoogleResourcesAccess();

  const { contacts } = useContacts({
    cursorPosition,
    shouldSearch: isOpen,
  });

  const canConnectToGoogle = useCanConnectToGoogle();

  const {
    isVisible: isVisibleAccessDeniedModal,
    handleVisibilitySet: onOpenAccessDeniedModal,
    handleVisibilityRemove: onCloseAccessDeniedModal,
  } = useVisible();

  const handleContactClick = useCallback(
    (index: number) => {
      const contact = contacts[index];
      const insertValue = `${contact.first_name} ${contact.last_name} (${contact.email}) `;

      const lastAtPos = threadInputBoxValue.lastIndexOf(
        KeyCodes.AT,
        cursorPosition - 1,
      );

      const beforeCursor =
        lastAtPos >= 0
          ? threadInputBoxValue.substring(0, lastAtPos + 1)
          : threadInputBoxValue.substring(0, cursorPosition);

      const afterCursor = threadInputBoxValue.substring(cursorPosition);

      const newValue = `${beforeCursor}${insertValue}${afterCursor}`;

      setThreadInputBoxValue(newValue);
      setCursorPosition(beforeCursor.length + insertValue.length);
    },
    [
      cursorPosition,
      contacts,
      threadInputBoxValue,
      setThreadInputBoxValue,
      setCursorPosition,
    ],
  );

  const { selectedIndex } = useQuickPickerControls({
    options: contacts,
    isOpen,
    shouldFocusInputBox: true,
    onClose,
    onItemClick: handleContactClick,
  });

  useEffect(() => {
    if (canConnectToGoogle && !canAccessContacts && !isGuestAccess) {
      onOpenAccessDeniedModal();
    } else {
      onCloseAccessDeniedModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccessContacts, isGuestAccess, canConnectToGoogle]);

  const handleClose = () => {
    onCloseAccessDeniedModal();
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  if (isVisibleAccessDeniedModal) {
    return (
      <div className="nj-thread-input-box--quick-picker">
        <ContactsAccessDeniedModal onClose={handleClose} />
      </div>
    );
  }

  if (contacts.length === 0 || isGuestAccess) {
    return null;
  }

  return (
    <div className="nj-thread-input-box--quick-picker contacts" role="menu">
      {contacts.map((contact, index) => (
        <div
          key={index}
          className={classNames('nj-thread-input-box--quick-picker-item', {
            selected: index === selectedIndex,
          })}
          onMouseDown={(e) => {
            e.preventDefault();
            handleContactClick(index);
          }}
          data-testid="thread-input-box-quick-picker-item"
        >
          <Icon
            alt={getInitials(contact)}
            size={SVG_SIZE}
            // TODO(olha): There is a bug with changing color while searching. should fix later
            masterColor={contact.color}
          />

          <div>
            <p className="nj-thread-input-box--quick-picker-title">{`${contact.first_name} ${contact.last_name}`}</p>
            <p className="nj-thread-input-box--quick-picker-subtitle">
              {contact.email}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
