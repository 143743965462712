import { useMemo, useCallback, useContext } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  userChatsState,
  useAppSelector,
  useAppDispatch,
  tasksApi,
  addMessageToConversationChat,
  addMessageToTaskChat,
  addConversationChat,
  updateUserChatsState,
  addTaskToConversationChat,
  UserChatsState,
  fetchNextTopConversations,
  fetchFilteredConversationTasks,
  setThreadStatus,
  sessionState,
  replaceTaskChatTemporaryMessageId,
  replaceConversationChatTemporaryMessageId,
} from 'src/store';
import {
  MatchingItem,
  TaskState,
  Conversation,
  ApiMinimumTask,
  ApiTaskSelectable,
  Message,
  SortOrder,
  ChatsTasksFilter,
  ConversationsByPageRequest,
  ChatsFilter,
  ConversationState,
  isMessage,
  GTMEvent,
} from 'src/types';
import { ThreadsStatusRegistryPayload } from 'src/store/slices/userChatsSlice';
import { useSendMessagePDUMutation } from 'src/store/services';
import { getTaskDisplayDate, escapeRegExp, sendGTMEvent } from 'src/utils';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_CHAT_NAME,
  DEFAULT_PAGE_SIZE,
} from 'src/constants';
import { useSession } from 'src/hooks';
import { toPDUMessage } from 'src/utils';
import log from 'src/utils/logger';
import { DEFAULT_CHAT_ID } from 'src/constants';

dayjs.extend(utc);

/**
 * useThreads provides functionality to manage chat windows.
 * @returns
 */
export const useThreads = () => {
  const { debugMode } = useContext(DebugContext);
  const {
    currentTaskId,
    appUser,
    chatsPageSize = DEFAULT_PAGE_SIZE,
    chatsFilter,
  } = useSession();

  const {
    conversations = [],
    tasks = [],
    pageToken,
    avatarSpeech,
    cameFrom,
    shouldAnimate,
    threadsStatusRegistry,
    isFetchingConversation,
  } = useAppSelector(userChatsState);

  const { currentConversationId } = useAppSelector(sessionState);

  const ignoreMessages =
    threadsStatusRegistry[currentConversationId || DEFAULT_CHAT_ID]
      ?.ignoreMessages;
  const isSubmitHappened =
    threadsStatusRegistry[currentConversationId || DEFAULT_CHAT_ID]
      ?.isSubmitHappened;

  const [sendPDUMessage] = useSendMessagePDUMutation();

  const dispatch = useAppDispatch();

  // todo: re-write in thunk language
  const [updateTask] = tasksApi.useUpdateTaskMutation();
  const [updateTaskField] = tasksApi.useUpdateTaskFieldMutation();

  const updateThreadStatus = ({
    threadId,
    statusRegistry,
  }: ThreadsStatusRegistryPayload) => {
    dispatch(
      setThreadStatus({
        threadId,
        statusRegistry,
      }),
    );
  };

  /**
   * Uses socket layer to transmit a message to BE
   * @param message
   */
  const addMessageToThread = async (message: Message) => {
    const { conversation_id, ...rest } = message;

    const apiMessage = {
      conversation_id:
        conversation_id === DEFAULT_CHAT_ID ? '' : conversation_id,
      ...rest,
    };

    const envelope = toPDUMessage(JSON.stringify(apiMessage), appUser?.user_id);

    if (debugMode) {
      log.debug(
        `addMessageToThread >\n`,
        `Message > message.conversation_id = ${message.conversation_id}\n`,
        `Message > message.task_id = ${message.task_id}\n`,
      );
    }

    const temporaryMessageId = `new-message-${dayjs().unix()}`;

    const newMessage = {
      ...message,
      message_id: temporaryMessageId,
    };

    if (message.task_id) {
      dispatch(addMessageToTaskChat(newMessage));
    } else if (message.conversation_id) {
      dispatch(addMessageToConversationChat(newMessage));
    }

    const currentConversationId = conversation_id || DEFAULT_CHAT_ID;
    updateThreadStatus({
      threadId: currentConversationId,
      statusRegistry: { ignoreMessages: false, isSubmitHappened: true },
    });
    try {
      const result = await sendPDUMessage({
        message: envelope,
        apiContext: {
          conversation_id: apiMessage.conversation_id,
        },
      }).unwrap();

      const messageFromPDU = JSON.parse(result.payload);

      if (!isMessage(messageFromPDU)) {
        log.error(`Incorrect data type for socket ${result.payload}`);
        return;
      }

      sendGTMEvent(GTMEvent.USER_SENT_QUERY, {
        message_id: messageFromPDU.message_id,
      });

      if (message.task_id) {
        dispatch(
          replaceTaskChatTemporaryMessageId({
            temporaryMessageId,
            newMessage: messageFromPDU,
          }),
        );
      } else if (message.conversation_id) {
        dispatch(
          replaceConversationChatTemporaryMessageId({
            temporaryMessageId,
            newMessage: messageFromPDU,
          }),
        );
      }
    } catch (error) {
      log.error('Request error:', error);
      updateThreadStatus({
        threadId: currentConversationId,
        statusRegistry: { ignoreMessages: false, isSubmitHappened: false },
      });
    }
  };

  /**
   * Creates a new default thread on button click (+ new chat)
   * inside redux store which later gets replaced with a new
   * conversation after we route a message with no
   * conversation_id via socket.
   */
  const createNewDefaultThread = () => {
    dispatch(
      addConversationChat({
        conversation_id: DEFAULT_CHAT_ID,
        user_id: appUser.user_id,
        timestamp: new Date().toISOString(),
        messages: [] as Message[],
        is_top_conversation: true,
        conversation_hash: DEFAULT_CHAT_NAME,
        tasks: [] as ApiMinimumTask[],
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      }),
    );
  };

  /**
   * Sorts all chat conversations by timestamp &
   * has no filters applied after it has loaded
   * from the endpoint.
   */
  const sortedConversations = useMemo(() => {
    const tmp: Conversation[] = Array.isArray(conversations)
      ? conversations
      : [];

    const sorted: Conversation[] = [...tmp].sort(
      (a: Conversation, b: Conversation) =>
        new Date(b.timestamp || new Date()).getTime() -
        new Date(a.timestamp || new Date()).getTime(),
    );

    const filtered =
      chatsFilter !== ChatsFilter.ALL_AND_ARCHIVED
        ? sorted.filter(
            (c: Conversation) => c.state !== ConversationState.ARCHIVED,
          )
        : sorted;

    return filtered;
  }, [conversations, chatsFilter]);

  /**
   * Returns conversation by id
   */
  const selectConversationById = useCallback(
    (conversationId: string) => {
      return sortedConversations.find(
        (conversation: Conversation) =>
          conversation.conversation_id === conversationId,
      );
    },
    [sortedConversations],
  );

  /**
   * Provides sorted list of tasks from conversations.
   * Useful for searching through all tasks accumulated
   * from all conversations from given page.
   */
  const sortedConversationTasks = useMemo(() => {
    const extractedTasks: ApiMinimumTask[] = conversations.reduce(
      (acc: ApiMinimumTask[], conversation: Conversation) => {
        if (conversation.tasks) {
          return [...acc, ...conversation.tasks];
        }
        return acc;
      },
      [],
    );

    // spread in order to avoid mutation & typescript error
    const sorted = [...extractedTasks].sort(
      (a: ApiMinimumTask, b: ApiMinimumTask) =>
        new Date(b.created_at || new Date()).getTime() -
        new Date(a.created_at || new Date()).getTime(),
    );
    return sorted;
  }, [conversations]);

  /**
   * Selects a small version of the task from the combined tasks list.
   */
  const selectConversationTaskById = useCallback(
    (taskId: string | undefined) => {
      return sortedConversationTasks.find(
        (task: ApiMinimumTask) => task.task_id === taskId,
      );
    },
    [sortedConversationTasks],
  );

  /**
   * Selects a full api task if it was loaded into the slice.
   * Not every task will be there due to lazy loading of every task.
   */
  const selectTaskById = useCallback(
    (taskId: string | undefined) => {
      return tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === taskId,
      );
    },
    [tasks],
  );

  /**
   * Obtains tasks that we can search through, used by chat form.
   * @param search string
   * @returns MatchingItem[]
   */
  const getMatchingTasks = (search = '') => {
    // Items need to be sorted by date in order to display correctly in helper items
    const hashedList: MatchingItem[] = sortedConversationTasks.map(
      (task: ApiMinimumTask) => {
        const createdDate = dayjs(
          task.created_at || new Date().toDateString(),
        ).format(DEFAULT_DATE_FORMAT);
        return {
          value: `${task.task_hash?.replace(/^#/, '')}`,
          option: `${task.task_hash?.replace(/^#/, '')}`,
          date: getTaskDisplayDate(createdDate),
        };
      },
    );

    if (search === '') return hashedList.slice(0, 5);

    const tasksList = hashedList
      .filter((item: MatchingItem) => {
        const regExp = new RegExp(
          `^${escapeRegExp(search).toLowerCase()}`,
          'gi',
        );
        return item.value.toLowerCase().match(regExp);
      })
      .slice(0, 5);

    return tasksList.length > 0 ? tasksList : hashedList.slice(0, 5);
  };

  /**
   * Selects a conversation thread.
   */
  const currentThread = useMemo(() => {
    return sortedConversations.find(
      (conversation: Conversation) =>
        conversation.conversation_id === currentConversationId,
    );
  }, [sortedConversations, currentConversationId]);

  /**
   * Selects a task if task id was defined, and it  exists
   * in state.tasks redux state.
   */
  const currentTask = useMemo(() => {
    return selectTaskById(currentTaskId);
  }, [selectTaskById, currentTaskId]);

  /**
   * Re-loads chat messages based on a selection
   * made inside conversations/tasks list, on clicking
   * respective items.
   */
  const chatMessages = useMemo(
    () => currentThread?.messages || [],
    [currentThread],
  );

  /**
   * Discerns whether current loading state of
   * the chat is a task view or a conversation thread.
   */
  const isTaskThread = useMemo(() => {
    return !!currentTaskId;
  }, [currentTaskId]);

  // todo: review & adjust as needed per new paradigm
  const markTaskAsDone = async (taskId: string) => {
    try {
      await updateTaskField({
        userId: appUser.user_id,
        taskId: taskId,
        task: {
          state: TaskState.HALTING,
        },
      });
    } catch (error: unknown) {
      log.error(error);
    }
  };

  // archives task
  const archiveTask = async (taskId: string) => {
    try {
      await updateTaskField({
        userId: appUser.user_id,
        taskId: taskId,
        task: {
          state: TaskState.ARCHIVED,
        },
      });
    } catch (error: unknown) {
      log.error(error);
    }
  };

  // stop task by sending STOPPING signal
  const stopTask = async (taskId: string) => {
    try {
      await updateTaskField({
        userId: appUser.user_id,
        taskId: taskId,
        task: {
          state: TaskState.STOPPING,
        },
      });
    } catch (error: unknown) {
      log.error(error);
    }
  };

  // change task state
  const updateTaskState = useCallback(
    async (taskId: string, state: TaskState) => {
      try {
        await updateTaskField({
          userId: appUser.user_id,
          taskId: taskId,
          task: {
            state,
          },
        });
      } catch (error: unknown) {
        log.error(error);
      }
    },
    [appUser.user_id, updateTaskField],
  );

  // used to display navigation of the right panel
  const isThreadProgressing = useMemo(() => {
    if (!currentThread) {
      return false;
    }

    // no tasks in the thread
    // means it is progressing
    if (!currentThread.tasks || currentThread.tasks.length === 0) {
      return true;
    }

    // if at least one of the tasks
    // has defined conditions, it is
    // a progressing thread
    return currentThread?.tasks.some(
      (task: ApiMinimumTask) =>
        task.state !== TaskState.ARCHIVED &&
        task.state !== TaskState.FAILED &&
        task.state !== TaskState.DONE,
    );
  }, [currentThread]);

  // BE can provide a signal for this
  // part, until then we will program
  const hasThreadCompleted = useMemo(() => {
    if (!currentThread) {
      return false;
    }

    // no tasks in the thread means the thread
    // has not even started
    if (!currentThread.tasks || currentThread.tasks.length === 0) {
      return false;
    }

    // Every task needs to be completed
    // in one way or the other
    return currentThread.tasks.every(
      (task: ApiMinimumTask) =>
        task.state === TaskState.ARCHIVED ||
        task.state === TaskState.FAILED ||
        task.state === TaskState.DONE,
    );
  }, [currentThread]);

  // to display task view right panel menus correctly
  const isTaskProgressing = useMemo(() => {
    if (!currentTask) {
      return false;
    }

    return (
      currentTask.state === TaskState.IN_PROGRESS ||
      currentTask.state === TaskState.BLOCKED
    );
  }, [currentTask]);

  // to display task view right panel menus correctly
  const hasTaskCompleted = useMemo(() => {
    if (!currentTask) {
      return false;
    }

    return (
      currentTask.state === TaskState.DONE ||
      currentTask.state === TaskState.STOPPED ||
      currentTask.state === TaskState.STOPPING
    );
  }, [currentTask]);

  // to be used in the right panel
  const hasTaskFailed = useMemo(() => {
    if (!currentTask) {
      return false;
    }

    return currentTask?.state === TaskState.FAILED;
  }, [currentTask]);

  // if it is an empty state
  const isEmptyThread = useMemo(() => {
    return !currentTaskId && !currentConversationId;
  }, [currentTaskId, currentConversationId]);

  // map chats filter to filter state with tasks
  const getTasksFilterFromChatsFilter = useCallback(
    (filter: ChatsFilter): ChatsTasksFilter => {
      switch (filter) {
        case ChatsFilter.BLOCKED:
          return ChatsTasksFilter.BLOCKED;
        case ChatsFilter.DONE:
          return ChatsTasksFilter.DONE;
        case ChatsFilter.IN_PROGRESS:
          return ChatsTasksFilter.IN_PROGRESS;
        case ChatsFilter.FAILED:
          return ChatsTasksFilter.FAILED;
        case ChatsFilter.STOPPED:
          return ChatsTasksFilter.STOPPED;
        default:
          return ChatsTasksFilter.NONE;
      }
    },
    [],
  );

  // load conversations with or without archive
  const loadConversations = useCallback(
    async ({ reload = true, includeArchived = false }) => {
      await dispatch(
        fetchNextTopConversations({
          userId: appUser.user_id,
          limit: chatsPageSize,
          sortOrder: SortOrder.DESC,
          pageToken: reload ? '' : pageToken,
          includeArchived,
          reload,
        } as ConversationsByPageRequest),
      );
    },
    [dispatch, appUser.user_id, pageToken, chatsPageSize],
  );

  // load conversations with filtered tasks
  const loadConversationTasks = useCallback(
    async ({ filter = ChatsTasksFilter.NONE, reload = true }) => {
      if (filter === ChatsTasksFilter.NONE) {
        return;
      }
      await dispatch(
        fetchFilteredConversationTasks({
          userId: appUser.user_id,
          sortOrder: SortOrder.ASC,
          filterState: filter,
          pageToken: reload ? '' : pageToken,
          limit: chatsPageSize,
          reload,
        } as ConversationsByPageRequest),
      );
    },
    [dispatch, appUser.user_id, pageToken, chatsPageSize],
  );

  // get conversations list with applied filters
  // start: use first page load, reload: wipe prev conversations
  const getConversationsList = useCallback(
    async ({ reload = true, filter = ChatsFilter.ALL }) => {
      if (
        filter === ChatsFilter.ALL ||
        filter === ChatsFilter.ALL_AND_ARCHIVED
      ) {
        const includeArchived = filter === ChatsFilter.ALL ? false : true;
        await loadConversations({ reload, includeArchived });
      } else {
        const chatsTasksFilter = getTasksFilterFromChatsFilter(filter);
        await loadConversationTasks({ reload, filter: chatsTasksFilter });
      }
    },
    [loadConversations, loadConversationTasks, getTasksFilterFromChatsFilter],
  );

  // wrapper around conversatoin with task to add a minimum task
  const updateConversationWithTask = (task: Partial<ApiTaskSelectable>) => {
    dispatch(addTaskToConversationChat(task));
  };

  // wrapper around updating chats state directly as an object
  const updateChatsState = (newState: Partial<UserChatsState>) => {
    dispatch(updateUserChatsState(newState));
  };

  const shouldShowPillars = useMemo(() => {
    // when conversation is not loaded, or
    // when thread is default with zero messages
    if (
      (!currentConversationId || currentConversationId === DEFAULT_CHAT_ID) &&
      chatMessages.length === 0
    ) {
      return true;
    }

    return false;
  }, [currentConversationId, chatMessages]);

  return {
    chatMessages,
    conversations: sortedConversations,
    currentTask,
    currentThread,
    selectedConversationId: currentConversationId,
    selectedTaskId: currentTaskId,
    shouldAnimate,
    tasks: sortedConversationTasks,
    pageToken,
    cameFrom,
    isTaskThread,
    isThreadProgressing,
    hasThreadCompleted,
    isTaskProgressing,
    hasTaskCompleted,
    hasTaskFailed,
    isEmptyThread,
    ignoreMessages,
    isSubmitHappened,
    shouldShowPillars,
    isFetchingConversation,
    loadConversations,
    loadConversationTasks,
    getConversationsList,
    addMessageToThread,
    archiveTask,
    getMatchingTasks,
    markTaskAsDone,
    createNewDefaultThread,
    selectTaskById,
    selectConversationTaskById,
    selectConversationById,
    updateConversationWithTask,
    updateChatsState,
    updateTaskField,
    updateTask,
    stopTask,
    updateTaskState,
    updateThreadStatus,
    // TODO(olha): messageToAvatar is deprecated
    messageToAvatar: avatarSpeech,
  };
};
