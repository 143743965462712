import { useState, Fragment } from 'react';
import { PlayCircle } from '@phosphor-icons/react';
import { QuickTourModal } from 'src/pages/AuthPages/components/QuickTourModal';
import { useBreakpoint } from 'src/hooks';

const SVG_SIZE = 20;
const SVG_SIZE_MOBILE = 24;

export const QuickTour = () => {
  const [showQuickTourModal, setIsShowQuickTourModal] = useState(false);
  const { isMobileOrTablet } = useBreakpoint();
  return (
    <Fragment>
      <button
        className="nj-random-pillars--blend--footer-button"
        onClick={() => setIsShowQuickTourModal(true)}
      >
        <PlayCircle
          size={isMobileOrTablet ? SVG_SIZE_MOBILE : SVG_SIZE}
          weight="fill"
        />
      </button>

      <QuickTourModal
        isOpen={showQuickTourModal}
        onClose={() => setIsShowQuickTourModal(false)}
      />
    </Fragment>
  );
};
