import classNames from 'classnames';
import { Warning, X } from '@phosphor-icons/react';
import { useBreakpoint, useSession } from 'src/hooks';
import { useBanner } from 'src/hooks/useBanner';
import { useContext, useEffect } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { UserTierStatus } from 'src/types';

const SVG_SIZE = 24;
const SVG_SIZE_MOBILE = 20;

export const InsufficientCreditsBanner = () => {
  const { appUser, isProTier } = useSession();
  const { isTabletAndUp } = useBreakpoint();
  const { onChangeIsVisibleUpdatePlanModal } = useContext(SessionContext);
  const { updateBannerType } = useBanner();

  useEffect(() => {
    if (isProTier && appUser?.tier_status === UserTierStatus.OK) {
      updateBannerType(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProTier, appUser?.tier_status]);

  const handleRenewEarlyClick = () => {
    onChangeIsVisibleUpdatePlanModal(true);
  };

  return (
    <div
      className={classNames('nj-common-banner', {
        error: true,
      })}
    >
      <div className="nj-common-banner--left-side-wrapper">
        <Warning size={SVG_SIZE} weight="fill" />
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {isTabletAndUp ? 'Insufficient credits' : 'Insufficient funds'}
          </h5>
          {isTabletAndUp && (
            <p className="nj-common-banner--description ellipsis">
              Your task cannot be processed due to insufficient credits. Renew
              your subscription early to continue access to PRO features.
            </p>
          )}
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        <button
          type="button"
          className="nj-button nj-banner--renew-button"
          onClick={handleRenewEarlyClick}
          disabled={!!appUser?.stripe_info?.renew_early_pending_subscription}
        >
          Add credits
        </button>
        <button
          className="nj-common-banner--button-close"
          onClick={() => updateBannerType(null)}
        >
          <X size={isTabletAndUp ? SVG_SIZE : SVG_SIZE_MOBILE} />
        </button>
      </div>
    </div>
  );
};
