import { useMemo } from 'react';
import { ActionStatue, Action } from 'src/types';

interface LatestProgressActionProps {
  actions: Action[];
}

// TODO(olha): duplicate from bubbles. Please, use from Flat design
export const LatestProgressAction = ({
  actions,
}: LatestProgressActionProps) => {
  const allCompleted = useMemo(
    () => actions.every((action) => action.status === ActionStatue.COMPLETED),
    [actions],
  );

  if (allCompleted) {
    const lastAction = actions.slice(-1)[0];
    return (
      <p className="nj-task-scratchpad--progress-card-action-item ellipsis">
        {lastAction?.action}
      </p>
    );
  }

  const firstInprogressAction =
    actions.find((item) => item.status !== ActionStatue.COMPLETED)?.action ||
    '';

  return (
    <p className="nj-task-scratchpad--progress-card-action-item ellipsis">
      {firstInprogressAction}
    </p>
  );
};
