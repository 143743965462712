import {
  setActiveSettingsTab,
  settingsState,
  useAppDispatch,
  useAppSelector,
} from 'src/store';
import { SettingsTabs } from 'src/store/slices/settingsSlice';

export const useSettings = () => {
  const { activeSettingsTab } = useAppSelector(settingsState);

  const dispatch = useAppDispatch();

  const changeSelectedSettingsTab = (tab: SettingsTabs) => {
    dispatch(setActiveSettingsTab(tab));
  };

  return {
    activeSettingsTab,
    changeSelectedSettingsTab,
  };
};
