import { useGoogleResourcesAccess } from 'src/hooks';
import { Plus } from '@phosphor-icons/react';
import GoogleLogo from 'src/images/logos/google-logo.svg';
import React from 'react';
import { useLogout } from 'src/hooks/useLogout';
import { useCanConnectToGoogle } from 'src/hooks/useCanConnectToGoogle';

const CONTACT_LIST = [
  {
    name: 'Sarah Jones',
    initials: 'SJ',
    email: 'sara@gmail.com',
    color: '#12C892',
  },
  {
    name: 'Jane Smith',
    initials: 'JS',
    email: 'jane.smith@gmail.com',
    color: '#F2895D',
  },
  {
    name: 'John Doe',
    initials: 'JD',
    email: 'john.doe@gmail.com',
    color: '#F55379',
  },
];

interface ContactsAccessDeniedModalProps {
  onClose: () => void;
}

export const ContactsAccessDeniedModal = ({
  onClose,
}: ContactsAccessDeniedModalProps) => {
  const { logoutWithRedirectToSignUp } = useLogout();

  const canConnectToGoogle = useCanConnectToGoogle();

  const { connectContacts } = useGoogleResourcesAccess();

  const handleConnectClick = () => {
    connectContacts();
    onClose();
  };

  return (
    <div
      className="nj-access-denied"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <div className="nj-access-denied--header">
        <h5 className="nj-access-denied--title">
          {canConnectToGoogle
            ? 'Grant access to Contacts'
            : 'Grant access to contacts with Google'}
        </h5>
        <p className="nj-access-denied--description">
          Quickly find the right person without having to type it all out
        </p>
      </div>

      <div className="nj-access-denied--list">
        {CONTACT_LIST.map(({ name, email, color, initials }) => (
          <div className="nj-access-denied--list-item" key={email}>
            <span
              style={{ backgroundColor: color }}
              className="nj-access-denied--icon"
            >
              {initials}
            </span>
            <div>
              <p className="nj-access-denied--list-item-title">{name}</p>
              <p className="nj-access-denied--list-item-description">{email}</p>
            </div>
          </div>
        ))}
      </div>

      {canConnectToGoogle ? (
        <button
          type="button"
          onClick={handleConnectClick}
          className="nj-access-denied--button"
        >
          <Plus size={20} />
          Connect
        </button>
      ) : (
        <button
          className="nj-auth-sign-in-up-button nj-google-button"
          onClick={logoutWithRedirectToSignUp}
        >
          <div className="nj-google-button-state"></div>
          <div className="nj-google-button-content-wrapper">
            <div>
              <img
                className="nj-auth-sign-in-up-button-logo"
                src={GoogleLogo}
                alt="Google"
              />
            </div>
            <span className="nj-google-button-contents">
              Sign up with Google
            </span>
            <span style={{ display: 'none' }}>Sign up with Google</span>
          </div>
        </button>
      )}
    </div>
  );
};
