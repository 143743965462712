import classNames from 'classnames';

type BasicButtonProps = {
  isSubmit?: boolean;
  className?: string;
  selected?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  rest?: { [key: string]: React.ReactNode };
  onClick?: () => void;
};

/**
 * BasicButton component creates a button with styles,
 * has selected state, can have children components.
 */
export const BasicButton = ({
  isSubmit,
  className,
  selected,
  children,
  disabled = false,
  onClick,
  ...rest
}: BasicButtonProps) => {
  const buttonClasses = classNames(className, {
    selected: selected,
  });
  return isSubmit ? (
    <button
      type="submit"
      className={buttonClasses}
      disabled={disabled}
      {...rest}
    >
      <div className="container">{children}</div>
    </button>
  ) : (
    <button
      className={buttonClasses}
      {...rest}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="container">{children}</div>
    </button>
  );
};
