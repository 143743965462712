import logoHorizontal from 'src/images/logos/logo-dark.svg';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type HeaderProps = {
  rightSide?: ReactNode;
};
export const Header = ({ rightSide }: HeaderProps) => {
  return (
    <header className="nj-auth-header">
      <Link to="/" className="nj-header-logo">
        <img src={logoHorizontal} alt="Logo" />
      </Link>
      {rightSide}
    </header>
  );
};
