import { useState, useContext, useEffect, useMemo, useCallback } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import classNames from 'classnames';
import { X } from '@phosphor-icons/react';
import { Modal } from 'react-responsive-modal';
import { useSession } from 'src/hooks';
import { NotificationActionType, NotificationType } from 'src/types';
import { Button } from 'src/components/Button';

const SVG_SIZE = 20;

export const NotificationModal = () => {
  const { notification, removeNotification } = useSession();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { rootContainerRef } = useContext(ForwardRefContext);

  useEffect(() => {
    if (notification) {
      setIsOpen(true);
    }
  }, [notification]);

  const handleClose = useCallback(() => {
    removeNotification();
    setIsOpen(false);
  }, [removeNotification]);

  const notificationClasses = classNames('nj-modal--notification', {
    error: notification?.type === NotificationType.ERROR,
    warning: notification?.type === NotificationType.WARNING,
    status: notification?.type === NotificationType.STATUS,
    alert: notification?.type === NotificationType.ALERT,
  });

  const actionButton = useMemo(() => {
    switch (notification?.action) {
      case NotificationActionType.DISMISS:
        return (
          <Button
            className="nj-button nj-button--primary"
            onClick={handleClose}
          >
            Dismiss
          </Button>
        );
      default:
        return <></>;
    }
  }, [notification?.action, handleClose]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      container={rootContainerRef?.current || document.body}
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: notificationClasses,
        closeIcon: 'nj-video-avatar-modal-closeButton',
      }}
      closeIcon={<X size={SVG_SIZE} />}
      center
    >
      <div className="nj-notification">
        {notification?.title && <h5>{notification?.title}</h5>}
        {notification?.notification && <p>{notification?.notification}</p>}
        {actionButton}
      </div>
    </Modal>
  );
};
