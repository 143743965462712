import { sessionState, useAppDispatch, useAppSelector } from 'src/store';
import { BannerType } from 'src/types';
import { setBannerType } from 'src/store/slices/sessionSlice';

export const useBanner = () => {
  const dispatch = useAppDispatch();

  const { bannerType } = useAppSelector(sessionState);

  const updateBannerType = (bannerType: BannerType | null) => {
    dispatch(setBannerType(bannerType));
  };

  return {
    bannerType,
    updateBannerType,
  };
};
