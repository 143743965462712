import { useContext, useEffect, useRef } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import throttle from 'lodash/throttle';

interface useAutoScrollByResizeArgs {
  containerRef: React.RefObject<HTMLElement> | null;
  messageListLength: number;
}

const BOTTOM_SCROLL_THRESHOLD = 100;

export const useAutoScrollByResize = ({
  containerRef,
  messageListLength,
}: useAutoScrollByResizeArgs) => {
  const { threadMessageListAnchorRef, threadMessageListRef } =
    useContext(ForwardRefContext);

  const resizeObserver = useRef<ResizeObserver | null>(null);
  const userScrolledUp = useRef<boolean>(false);

  useEffect(() => {
    if (!containerRef?.current || !threadMessageListRef) {
      return;
    }

    if (resizeObserver.current) {
      return;
    }

    const throttledScrollToBottom = throttle(() => {
      if (threadMessageListAnchorRef?.current) {
        threadMessageListAnchorRef.current.onScrollToBottomMessageList(0);
      }
    }, 200);

    const observerCallback = (entries: ResizeObserverEntry[]) => {
      const entry = entries.find(
        (entry) => entry.target === containerRef.current,
      );

      if (!entry) {
        return;
      }

      const shouldScrollToBottom =
        threadMessageListAnchorRef?.current && !userScrolledUp.current;

      if (shouldScrollToBottom) {
        throttledScrollToBottom();
      }
    };

    resizeObserver.current = new ResizeObserver(observerCallback);
    resizeObserver.current.observe(containerRef.current);

    return () => {
      resizeObserver.current?.disconnect();
      resizeObserver.current = null;
    };
  }, [
    threadMessageListAnchorRef,
    containerRef,
    threadMessageListRef,
    // (olha): we need it for trigger effect
    messageListLength,
  ]);

  useEffect(() => {
    const element = threadMessageListRef?.current;
    if (!element) {
      return;
    }

    const handler = () => {
      const scrollPosition = element.scrollTop + element.clientHeight;
      const scrollHeight = element.scrollHeight;
      const isNearBottom =
        scrollPosition >= scrollHeight - BOTTOM_SCROLL_THRESHOLD;
      const isUserScrolled = element.scrollTop >= 0;

      userScrolledUp.current = !isNearBottom && isUserScrolled;
    };

    element.addEventListener('scroll', handler);

    return () => {
      element.removeEventListener('scroll', handler);
    };
  }, [
    threadMessageListRef,
    // (olha): we need it for trigger effect
    messageListLength,
  ]);
};
