import { Header } from 'src/pages/AuthPages/components/Header';
import { FormProvider } from 'src/components/FormProvider';
import { FormInput } from 'src/components/FormInput';
import { ErrorMessageBlock } from 'src/pages/AuthPages/components/ErrorMessageBlock';
import atlasSmile from 'src/images/login/atlas_smile.png';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IS_USER_PASSED_PASSWORD_PAGE } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { CompanyLinksSet } from 'src/pages/AuthPages/components/CompanyLinksSet';
import { env } from 'src/env';

enum TemporaryPasswordField {
  PASSWORD = 'password',
}

interface FormData {
  [TemporaryPasswordField.PASSWORD]: string;
}

export const TemporaryPasswordScreen = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const methods = useForm<FormData>({
    defaultValues: {
      [TemporaryPasswordField.PASSWORD]: '',
    },
  });

  const { handleSubmit, reset } = methods;

  const submitForm = async (data: FormData) => {
    setErrorMessage('');
    if (
      data[TemporaryPasswordField.PASSWORD] ===
      env.REACT_APP_TEMPORARY_PASSWORD
    ) {
      localStorage.setItem(IS_USER_PASSED_PASSWORD_PAGE, '1');
      navigate('/');
    } else {
      setErrorMessage('Oops! The password is incorrect. Try again.');
    }
    reset();
  };

  return (
    <div className="nj-auth-layout nj-beta">
      <Header />
      <main className="nj-auth-content">
        <h1 className="nj-auth-header-blue-gradient">Meet Ninja</h1>
        <h2 className="nj-auth-header-small">Your Autonomous AI Agent</h2>
        <h3 className="nj-auth-header-secondary">Enter password</h3>
        <FormProvider<FormData> methods={methods}>
          <form
            onSubmit={handleSubmit(submitForm)}
            className="nj-auth-form"
            autoComplete="off"
          >
            <div className="nj-auth-form--field">
              <FormInput
                name={TemporaryPasswordField.PASSWORD}
                type="password"
                className="nj-auth-form--input"
                placeholder="Enter Password"
                required={true}
                hasErrorBorder={!!errorMessage}
              />
            </div>
            <button className="nj-auth-form--submit-button" type="submit">
              Enter
            </button>
            {!!errorMessage && (
              <ErrorMessageBlock errorMessage={errorMessage} />
            )}
          </form>
        </FormProvider>
      </main>

      <div className="nj-auth-temporary-password-footer">
        <p className="nj-auth-temporary-password-footer-label">
          Learn more about Ninja
        </p>
        <CompanyLinksSet />
      </div>
      <img className="nj-auth-background-image" src={atlasSmile} alt="Ninja" />
    </div>
  );
};
