import { createApi } from '@reduxjs/toolkit/query/react';
import { baseMetahumanQuery } from './config';
import { AvatarOption } from 'src/types';

enum MetahumanTags {
  Options = 'Options',
}

export const metahumanApi = createApi({
  reducerPath: 'metahumanApi',
  tagTypes: [MetahumanTags.Options],
  baseQuery: baseMetahumanQuery,
  keepUnusedDataFor: 60 * 60 * 10,
  endpoints: (build) => ({
    getMetahumanOptions: build.query<AvatarOption[], void>({
      query: () => '/getoptions',
      providesTags: () => [MetahumanTags.Options],
    }),
    killAvatarProcesses: build.mutation<void, void>({
      query: () => ({
        url: 'kill',
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetMetahumanOptionsQuery, useKillAvatarProcessesMutation } =
  metahumanApi;
