import { useMemo, ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormContext, useWatch } from 'react-hook-form';
import classNames from 'classnames';
import { Check, Icon as IconType } from '@phosphor-icons/react';

const SVG_SIZE = 12;

interface FormSwitchProps {
  name: string;
  onChangeHandler?: (value: boolean) => void;
  isDisabled?: boolean;
  Icon?: IconType;
  className?: string;
}

export const FormSwitch = ({
  name,
  onChangeHandler,
  isDisabled = false,
  Icon = Check,
  className,
}: FormSwitchProps) => {
  const uniqueId = useMemo(() => uuidv4(), []);

  const { register, control } = useFormContext();
  const fieldValues = useWatch({ control });

  const value = useMemo(() => fieldValues[name], [fieldValues, name]);

  return (
    <div
      className={classNames('nj-form-switch', className, {
        switchOn: !!value,
        disabled: isDisabled,
      })}
    >
      <input
        id={uniqueId}
        className="nj-form-switch--input"
        type="checkbox"
        {...register(name)}
        onChange={(data: ChangeEvent<HTMLInputElement>) => {
          register(name).onChange(data);
          if (onChangeHandler) {
            onChangeHandler(data.target.checked);
          }
        }}
        disabled={isDisabled}
      />
      <label htmlFor={uniqueId} className="nj-form-switch--label">
        <span className="nj-form-switch--slider">
          {!!value && <Icon weight="bold" size={SVG_SIZE} />}
        </span>
      </label>
    </div>
  );
};
