import { createApi } from '@reduxjs/toolkit/query/react';
import { baseCombusQuery } from './config';
import { PDUMessage, ApiContext } from 'src/types';
import {
  X_NINJA_CONVERSATION_ID_HEADER,
  X_NINJA_TASK_ID_HEADER,
} from 'src/constants/api';

enum SocketApiTags {
  SocketApi = 'SocketApi',
}

export const socketApi = createApi({
  reducerPath: 'socketApi',
  baseQuery: baseCombusQuery,
  tagTypes: [SocketApiTags.SocketApi],
  endpoints: (builder) => ({
    sendMessagePDU: builder.mutation<
      PDUMessage,
      { message: PDUMessage; apiContext?: ApiContext }
    >({
      query: ({ message, apiContext }) => ({
        url: '/v1/messages/',
        method: 'POST',
        body: message,
        headers: {
          ...(apiContext?.conversation_id && {
            [X_NINJA_CONVERSATION_ID_HEADER]: apiContext.conversation_id,
          }),
          ...(apiContext?.task_id && {
            [X_NINJA_TASK_ID_HEADER]: apiContext.task_id,
          }),
        },
      }),
    }),
  }),
});

export const { useSendMessagePDUMutation } = socketApi;
