/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum TaskSkill {
  CHITCHAT = 'chitchat',
  FUN = 'fun',
  OUTREACH = 'outreach',
  PHISHING = 'phishing',
  RESEARCH = 'research',
  CODING = 'coding',
  RESERVATION = 'reservation',
  SCHEDULING = 'scheduling',
  STATUS = 'status',
  SUMMARIZE = 'summarize',
  UNDEFINED = 'undefined',
  IMAGE = 'image',
}
