import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelFeedback } from 'src/types';
import { RootState } from '../index';
import { PURGE } from 'reduxjs-toolkit-persist';

interface FeedbackData extends ModelFeedback {
  messageId: string;
}

type FeedbackState = {
  feedbackData: FeedbackData | null;
};

const initialState = {
  feedbackData: null,
};

const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: initialState as FeedbackState,
  reducers: {
    setFeedbackData: (
      state,
      { payload }: PayloadAction<FeedbackData | null>,
    ) => {
      state.feedbackData = payload;
    },
    resetFeedbackData: (state) => {
      state.feedbackData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const feedbackState = (state: RootState) =>
  state.feedback as FeedbackState;

export const { setFeedbackData, resetFeedbackData } = feedbackSlice.actions;

export default feedbackSlice.reducer;
