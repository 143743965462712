import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { tasksBaseUrl } from '../constants';
import {
  TasksByPageResponse,
  ConversationsByPageRequest,
  isApiTaskSelectable,
  isPartialApiTaskList,
  UserTaskId,
  UserApiTask,
  isConversationList,
  SortOrder,
} from 'src/types';
import { getErrorMessage } from 'src/utils';
import { get_access_token } from 'src/utils';

/**
 * fetchNextPageTasks() thunk fetches tasks by page token.
 * GET /v2/users/${userId}/tasks?sort_order=desc&limit=5&page_token=xxx
 * ATTN: deprecating
 */
export const fetchNextPageTasks = createAsyncThunk(
  'tasks/fetchNextPageTasks',
  async ({
    userId,
    limit = 50,
    sortOrder = SortOrder.ASC,
    pageToken = '',
  }: ConversationsByPageRequest) => {
    const token = await get_access_token();
    const URL = `${tasksBaseUrl}/users/${userId}/tasks?sort_order=${sortOrder}&limit=${limit}&page_token=${pageToken}`;
    const request = new Request(URL, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json = await response.json();
        const { page_token = '', data = [] } = json;
        if (isPartialApiTaskList(data)) {
          return {
            pageToken: page_token,
            tasks: data,
          } as TasksByPageResponse;
        } else {
          throw new Error(
            `Fetched tasks list is not in the correct type format: ${response}.`,
          );
        }
      } else {
        throw new Error(`GET ${URL} failed to fetch response from the server.`);
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);

/**
 * fetchTaskById() thunk fetches task by id.
 * GET - /v2/tasks/${taskId}
 * ATTN: fetch with retries is blocking archival of a task,
 * and prevents lazy loading of tasks per previous ticket.
 */
export const fetchTaskById = createAsyncThunk(
  'tasks/fetchTaskById',
  async ({ taskId, userId }: UserTaskId) => {
    const URL = `${tasksBaseUrl}/users/${userId}/tasks/${taskId}?show_messages=true`;

    /*try {
      const data = await customFetchWithRetry(URL);
      if (isApiTaskSelectable(data)) {
        return data;
      } else {
        throw new Error(`Fetched task is not of the correct type.`);
      }
    } catch (error) {
      const message = isError(error)
        ? error.message
        : `Failed to fetch task ${taskId} from Tasks API.`;
      return rejectWithValue({ message });
    }*/

    const token = await get_access_token();
    const request = new Request(URL, {
      method: 'get',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json = await response.json();

        if (isApiTaskSelectable(json)) {
          return json;
        } else {
          throw new Error(
            `Fetched task is not of the correct type: ${response}.`,
          );
        }
      } else {
        throw new Error(`GET ${URL} failed to fetch a task from the server.`);
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);

/**
 * fetchUpdateTaskById() updates task by id at tasks api.
 * PUT - /v2/tasks/${taskId}
 */
export const fetchUpdateTaskById = createAsyncThunk(
  'tasks/updateTaskById',
  async (update: UserApiTask) => {
    const token = await get_access_token();
    const URL = `${tasksBaseUrl}/users/${update.userId}/tasks/${update.task.task_id}`;

    const request = new Request(URL, {
      method: 'PATCH',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
      body: JSON.stringify(update.task),
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json = await response.json();
        if (isApiTaskSelectable(json)) {
          return json;
        } else {
          throw new Error(
            `Updated task and it came in the wrong format: ${response}.`,
          );
        }
      } else {
        throw new Error(`PUT ${URL} failed to update a task on the server.`);
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);

/**
 * fetchFilteredConversationTasks() filteres conversations by state of the tasks.
 * GET - /v2/tasks?user_id={userid}&sort_order=asc&filter_state=done&limit=50
 */
export const fetchFilteredConversationTasks = createAsyncThunk(
  'tasks/filteredConverationsWithTasks',
  async ({
    userId,
    sortOrder,
    filterState,
    limit,
    pageToken,
    reload,
  }: ConversationsByPageRequest) => {
    const token = await get_access_token();
    const URL = `${tasksBaseUrl}/users/${userId}/tasks?sort_order=${sortOrder}&filter_state=${filterState}&limit=${limit}&page_token=${pageToken}`;

    const request = new Request(URL, {
      method: 'GET',
      mode: 'cors',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      referrerPolicy: 'no-referrer',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      if (response.ok) {
        const json = await response.json();
        if (isConversationList(json.data)) {
          return {
            pageToken: json.page_token,
            conversations: json.data,
            reload: reload,
          };
        } else {
          throw new Error(
            `Filtered conversations list arrived in the wrong format: ${response}.`,
          );
        }
      } else {
        throw new Error(
          `GET ${URL} failed to load filtered conversations with tasks.`,
        );
      }
    } catch (error: unknown) {
      const message = getErrorMessage(error);
      return isRejectedWithValue(message);
    }
  },
);
