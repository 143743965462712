import { isString, isBoolean, isNumber, isObject } from './general.guard';
import {
  MessageChannel,
  ConversationRole,
  ApiMinimumTask,
  TaskSkill,
  TaskState,
  Message,
  ResearchDataReferenceImage,
  ResearchDataReferenceVideo,
  ResearchData,
  ResearchDataReferenceWebPage,
  FeedbackType,
  ProgressUpdate,
  Action,
  ResearchDataReferenceCode,
  ResearchDataReferenceLLM,
  MessageType,
  OperationType,
  MessageMetadataResearchMetrics,
  ResearchResponseMetrics,
  MessageMetadataResearchReferenceCode,
  MessageMetadataResearchReferenceLLM,
  MessageMetadataResearchReferencesImageVideo,
  MessageMetadataResearchReferencesWebPage,
  MessageMetadataResearchStatusUpdate,
  MessageMetadataResearchSummary,
  MessageMetadataSchedulerMetrics,
  SchedulerCreateEventMetrics,
  CalendarCard,
  CalendarCardEvent,
  ChoicesCard,
  EmailCard,
  EmailCollaborator,
  ResearchCardData,
  ResearchSearchResult,
  DeepResearchResult,
  AiResult,
  SearchWebPageReference,
  SearchImageReference,
  SearchVideoReference,
  ResearchCard,
  AiContent,
  Choice,
  Choices,
  AiReference,
  ResearchAi,
  ResearchDataReferenceText,
  ResearchTaskCreationCard,
  SchedulerCreationCard,
  CreateMeetingCard,
  TextInputCard,
  TextInput,
  ErrorPayload,
  ChitChatCard,
} from '../api';
import { CodeTaskCreationCard } from 'src/types/models/CodeTaskCreationCard';
import { CodeCard } from 'src/types/models/CodeCard';
import { isDebugMetadata } from './api.debug.guard';
import { isProposalConfirmationCard } from './api.proposalconfirmation.guard';
import log from 'src/utils/logger';
import { ExternalModelReference, ImageGenerationItem } from '../general';
import { ImageCard } from 'src/types/models/ImageCard';

/**
 * ResearchDataReferenceImage() tests data against ResearchDataReferenceImage data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceImage(
  data: unknown,
): data is ResearchDataReferenceImage {
  const researchImage = data as ResearchDataReferenceImage;

  if (!isObject(researchImage)) {
    log.debug(
      `isResearchDataReferenceImage > not an object ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  if (!('url' in researchImage && isString(researchImage.url))) {
    log.debug(
      `isResearchDataReferenceImage > invalid url ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  if ('kind' in researchImage && researchImage.kind !== 'image') {
    log.debug(
      `isResearchDataReferenceImage > invalid kind ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  if (!('source' in researchImage && isString(researchImage.source))) {
    log.debug(
      `isResearchDataReferenceImage > invalid source ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  if (!('title' in researchImage && isString(researchImage.title))) {
    log.debug(
      `isResearchDataReferenceImage > invalid title ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  if (!('height' in researchImage && isString(researchImage.height))) {
    log.debug(
      `isResearchDataReferenceImage > invalid height ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  if (!('width' in researchImage && isString(researchImage.width))) {
    log.debug(
      `isResearchDataReferenceImage > invalid width ${JSON.stringify(
        researchImage,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceVideo() tests data against ResearchDataReferenceVideo data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceVideo(
  data: unknown,
): data is ResearchDataReferenceVideo {
  const researchVideo = data as ResearchDataReferenceVideo;

  if (!isObject(researchVideo)) {
    log.debug(
      `isResearchDataReferenceVideo > not an object ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if (!('url' in researchVideo && isString(researchVideo.url))) {
    log.debug(
      `isResearchDataReferenceVideo > invalid url ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if ('kind' in researchVideo && researchVideo.kind !== 'video') {
    log.debug(
      `isResearchDataReferenceVideo > invalid kind ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if (
    !('displayed_url' in researchVideo && isString(researchVideo.displayed_url))
  ) {
    log.debug(
      `isResearchDataReferenceVideo > invalid displayed_url ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if (!('duration' in researchVideo && isString(researchVideo.duration))) {
    log.debug(
      `isResearchDataReferenceVideo > invalid duration ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if (!('snippet' in researchVideo && isString(researchVideo.snippet))) {
    log.debug(
      `isResearchDataReferenceVideo > invalid snippet ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if (!('thumbnail' in researchVideo && isString(researchVideo.thumbnail))) {
    log.debug(
      `isResearchDataReferenceVideo > invalid thumbnail ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  if (!('title' in researchVideo && isString(researchVideo.title))) {
    log.debug(
      `isResearchDataReferenceVideo > invalid title ${JSON.stringify(
        researchVideo,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * ResearchDataReferenceText() tests data against ResearchDataReferenceText data type.
 * todo: this type got removed at BE, follow up
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceText(
  data: unknown,
): data is ResearchDataReferenceText {
  const researchText = data as ResearchDataReferenceText;

  if (!isObject(researchText)) {
    log.debug(
      `isResearchDataReferenceText > not an object ${JSON.stringify(
        researchText,
      )}`,
    );
    return false;
  }

  if (!('url' in researchText && isString(researchText.url))) {
    log.debug(
      `isResearchDataReferenceText > invalid url ${JSON.stringify(
        researchText,
      )}`,
    );
    return false;
  }

  if (
    !(
      'kind' in researchText &&
      isString(researchText.kind) &&
      researchText.kind === 'text'
    )
  ) {
    log.debug(
      `isResearchDataReferenceText > invalid kind ${JSON.stringify(
        researchText,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceWebPage() tests data against ResearchDataReferenceWebPage data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceWebPage(
  data: unknown,
): data is ResearchDataReferenceWebPage {
  const researchWebPage = data as ResearchDataReferenceWebPage;

  if (!isObject(researchWebPage)) {
    log.debug(
      `isResearchDataReferenceWebPage > not an object ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (
    !(
      'kind' in researchWebPage &&
      isString(researchWebPage.kind) &&
      researchWebPage.kind === 'web_page'
    )
  ) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid kind ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (!('snippet' in researchWebPage && isString(researchWebPage.snippet))) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid snippet ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (!('title' in researchWebPage && isString(researchWebPage.title))) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid title ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (!('url' in researchWebPage && isString(researchWebPage.url))) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid url ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (
    'favicon_url' in researchWebPage &&
    !isString(researchWebPage.favicon_url)
  ) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid favicon_url ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (
    'website_title' in researchWebPage &&
    !isString(researchWebPage.website_title)
  ) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid website_title ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  if (
    'website_domain' in researchWebPage &&
    !isString(researchWebPage.website_domain)
  ) {
    log.debug(
      `isResearchDataReferenceWebPage > invalid website_domain ${JSON.stringify(
        researchWebPage,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceCode() guards data against ResearchDataReferenceCode type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDataReferenceCode(
  data: unknown,
): data is ResearchDataReferenceCode {
  const researchCode = data as ResearchDataReferenceCode;

  if (!isObject(researchCode)) {
    log.debug(
      `isResearchDataReferenceCode > not an object ${JSON.stringify(
        researchCode,
      )}`,
    );
    return false;
  }

  if (
    'kind' in researchCode &&
    !(isString(researchCode.kind) && researchCode.kind === 'code')
  ) {
    log.debug(
      `isResearchDataReferenceCode > invalid kind ${JSON.stringify(
        researchCode,
      )}`,
    );
    return false;
  }

  if (!('content' in researchCode && isString(researchCode.content))) {
    log.debug(
      `isResearchDataReferenceCode > invalid content ${JSON.stringify(
        researchCode,
      )}`,
    );
    return false;
  }

  if (!('source' in researchCode && isString(researchCode.content))) {
    log.debug(
      `isResearchDataReferenceCode > invalid source ${JSON.stringify(
        researchCode,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchDataReferenceLLM() type guards data against ResearchDataReferenceLLM.
 * @param data unknonwn
 * @returns boolean
 */
export function isResearchDataReferenceLLM(
  data: unknown,
): data is ResearchDataReferenceLLM {
  const researchLLM = data as ResearchDataReferenceLLM;

  if (!isObject(researchLLM)) {
    return false;
  }

  if ('kind' in researchLLM && researchLLM.kind !== 'llm') {
    log.debug(
      `isResearchDataReferenceLLM > invalid kind ${JSON.stringify(
        researchLLM,
      )}`,
    );
    return false;
  }

  if (!('content' in researchLLM && isString(researchLLM.content))) {
    log.debug(
      `isResearchDataReferenceLLM > invalid content ${JSON.stringify(
        researchLLM,
      )}`,
    );
    return false;
  }

  if (!('source' in researchLLM && isString(researchLLM.source))) {
    log.debug(
      `isResearchDataReferenceLLM > invalid source ${JSON.stringify(
        researchLLM,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchData() tests data against ResearchData data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchData(data: unknown): data is ResearchData {
  const research = data as ResearchData;

  if (!isObject(research)) {
    log.debug(`isResearchData > not an object ${JSON.stringify(research)}`);
    return false;
  }

  if (!('payload_type' in research && isString(research.payload_type))) {
    return false;
  }

  if (
    !['research', 'research-code', 'research-llm'].includes(
      research.payload_type,
    )
  ) {
    return false;
  }

  if (!('query' in research && isString(research.query))) {
    log.debug(`isResearchData > invalid query ${JSON.stringify(research)}`);
    return false;
  }

  if (!('summary' in research && isString(research.summary))) {
    log.debug(`isResearchData > invalid summary ${JSON.stringify(research)}`);
    return false;
  }

  if (!('references' in research && Array.isArray(research.references))) {
    log.debug(
      `isResearchData > invalid references ${JSON.stringify(research)}`,
    );
    return false;
  }

  for (const reference of research.references) {
    if (
      !(
        isResearchDataReferenceLLM(reference) ||
        isResearchDataReferenceWebPage(reference) ||
        isResearchDataReferenceVideo(reference) ||
        isResearchDataReferenceImage(reference) ||
        isResearchDataReferenceCode(reference)
      )
    ) {
      log.debug(
        `isResearchData > invalid reference ${JSON.stringify(
          reference,
        )} in ${JSON.stringify(research)}`,
      );
      return false;
    }
  }

  return true;
}

/**
 * isAction() tests data against progress update type.
 * @param data unknown
 * @returns boolean
 */
export function isAction(data: unknown): data is Action {
  const actionData = data as Action;

  if ('action' in actionData && !isString(actionData.action)) {
    log.debug(`isAction > invalid action in ${JSON.stringify(actionData)}`);
    return false;
  }

  if ('status' in actionData && !isString(actionData.action)) {
    log.debug(`isAction > invalid status in ${JSON.stringify(actionData)}`);
    return false;
  }

  return true;
}

/**
 * isProgressUpdate() tests data against progress update type.
 * @param data unknown
 * @returns boolean
 */
export function isProgressUpdate(data: unknown): data is ProgressUpdate {
  const progress = data as ProgressUpdate;

  if (!isObject(progress)) {
    log.debug(`isProgressUpdate > not an object ${JSON.stringify(progress)}`);
    return false;
  }

  if (
    'payload_type' in progress &&
    progress.payload_type !== 'progress-update'
  ) {
    return false;
  }

  if ('actions' in progress) {
    if (!Array.isArray(progress.actions)) {
      log.debug(
        `isProgressUpdate > invalid actions ${JSON.stringify(progress)}`,
      );
      return false;
    }

    for (const action of progress.actions) {
      if (!isAction(action)) {
        log.debug(
          `isProgressUpdate > invalid action ${JSON.stringify(
            action,
          )} in ${JSON.stringify(progress)}`,
        );
        return false;
      }
    }
  }

  return true;
}

/**
 * isTextInput() guards data against TextInput type.
 * @param data unknown
 * @returns boolean
 */
export function isTextInput(data: unknown): data is TextInput {
  const input = data as TextInput;

  if (!isObject(input)) {
    log.debug(`isTextInput > not an object ${JSON.stringify(input)}`);
    return false;
  }

  if ('type' in input && !isString(input.type)) {
    log.debug(`isTextInput > not a string type ${JSON.stringify(input)}`);
    return false;
  }

  if ('label' in input && !isString(input.label)) {
    log.debug(`isTextInput > not a string label ${JSON.stringify(input)}`);
    return false;
  }

  if ('value' in input && !isString(input.value)) {
    log.debug(`isTextInput > not a string value ${JSON.stringify(input)}`);
    return false;
  }

  if (!('mandatory' in input && isBoolean(input.mandatory))) {
    log.debug(`isTextInput > not a boolean mandatory ${JSON.stringify(input)}`);
    return false;
  }

  return true;
}

/**
 * isTextInputCard() guards data against TextInputCard type.
 * attn: not relevant link & version ommitted
 * @param data unknown
 * @returns boolean
 */
export function isTextInputCard(data: unknown): data is TextInputCard {
  const inputcard = data as TextInputCard;

  if (!isObject(inputcard)) {
    log.debug(`isTextInputCard > not an object ${JSON.stringify(inputcard)}`);
    return false;
  }

  if (
    'payload_type' in inputcard &&
    inputcard.payload_type !== 'text-input-card'
  ) {
    return false;
  }

  if ('executed' in inputcard && !isBoolean(inputcard.executed)) {
    log.debug(
      `isTextInputCard > not a boolean executed ${JSON.stringify(inputcard)}`,
    );
    return false;
  }

  if ('input_id' in inputcard && !isString(inputcard.input_id)) {
    log.debug(
      `isTextInputCard > not a string input_id ${JSON.stringify(inputcard)}`,
    );
    return false;
  }

  if ('description' in inputcard && !isString(inputcard.description)) {
    log.debug(
      `isTextInputCard > not a string description ${JSON.stringify(inputcard)}`,
    );
    return false;
  }

  if ('questions' in inputcard && !Array.isArray(inputcard.questions)) {
    log.debug(
      `isTextInputCard > not an array questions ${JSON.stringify(inputcard)}`,
    );
    return false;
  }

  for (const question of inputcard.questions) {
    if (!isTextInput(question)) {
      log.debug(
        `isTextInputCard > invalid question ${JSON.stringify(question)}`,
      );
      return false;
    }
  }

  return true;
}

/**
 * isMessage() checks data against Message type.
 * @param data unknown
 * @returns boolean
 */
export function isMessage(data: unknown): data is Message {
  const message = data as Message;

  if (!isObject(message)) {
    log.debug(`isMessage > not an object ${JSON.stringify(message)}`);
    return false;
  }

  if ('message_id' in message && !isString(message.message_id)) {
    log.debug(`isMessage > invalid message_id ${JSON.stringify(message)}`);
    return false;
  }

  if ('conversation_id' in message && !isString(message.conversation_id)) {
    log.debug(`isMessage > invalid conversation_id ${JSON.stringify(message)}`);
    return false;
  }

  if ('timestamp' in message && !isString(message.timestamp)) {
    log.debug(`isMessage > invalid timestamp ${JSON.stringify(message)}`);
    return false;
  }

  if (!('user_id' in message && isString(message.user_id))) {
    log.debug(`isMessage > invalid user_id ${JSON.stringify(message)}`);
    return false;
  }

  if (!('from_user_id' in message && isString(message.from_user_id))) {
    log.debug(`isMessage > invalid from_user_id ${JSON.stringify(message)}`);
    return false;
  }

  if (!('to_user_id' in message && isString(message.to_user_id))) {
    log.debug(`isMessage > invalid to_user_id ${JSON.stringify(message)}`);
    return false;
  }

  if (
    'channel' in message &&
    !(
      message.channel && Object.values(MessageChannel).includes(message.channel)
    )
  ) {
    log.debug(`isMessage > invalid channel ${JSON.stringify(message)}`);
    return false;
  }

  if (
    !(
      'role' in message &&
      Object.values(ConversationRole).includes(message.role)
    )
  ) {
    log.debug(`isMessage > invalid role ${JSON.stringify(message)}`);
    return false;
  }

  if (!('content' in message && isString(message.content))) {
    log.debug(`isMessage > invalid content ${JSON.stringify(message)}`);
    return false;
  }

  if (
    'response_metadata' in message &&
    message.response_metadata !== null &&
    !isResearchData(message.response_metadata)
  ) {
    log.debug(
      `isMessage > invalid response_metadata ${JSON.stringify(message)}`,
    );
    return false;
  }

  if (
    'message_type' in message &&
    !(
      isString(message.message_type) &&
      Object.values(MessageType).includes(message.message_type)
    )
  ) {
    log.debug(`isMessage > invalid message_type ${JSON.stringify(message)}`);
    return false;
  }

  if (
    'operation_type' in message &&
    !(
      isString(message.operation_type) &&
      Object.values(OperationType).includes(message.operation_type)
    )
  ) {
    log.debug(`isMessage > invalid operation_type ${JSON.stringify(message)}`);
    return false;
  }

  // todo: add CODE_TASK_CREATION_CARD type
  if (
    'payload' in message &&
    !(
      isProgressUpdate(message.payload) ||
      isCalendarCard(message.payload) ||
      isEmailCard(message.payload) ||
      isChoicesCard(message.payload) ||
      isResearchData(message.payload) ||
      isResearchCard(message.payload) ||
      isResearchTaskCreationCard(message.payload) ||
      isSchedulerCreationCard(message.payload) ||
      isCodeCard(message.payload) ||
      isCodeTaskCreationCard(message.payload) ||
      isCreateMeetingCard(message.payload) ||
      isTextInputCard(message.payload) ||
      isErrorCard(message.payload) ||
      isChitChatCard(message.payload) ||
      isProposalConfirmationCard(message.payload) ||
      isImageGenerationCard(message.payload)
    )
  ) {
    log.debug(`isMessage > invalid payload ${JSON.stringify(message)}`);
    return false;
  }

  if (
    'metadata' in message &&
    !(
      isDebugMetadata(message.metadata) ||
      isMessageMetadataResearchMetrics(message.metadata) ||
      isMessageMetadataResearchReferenceCode(message.metadata) ||
      isMessageMetadataResearchReferenceLLM(message.metadata) ||
      isMessageMetadataResearchReferencesImageVideo(message.metadata) ||
      isMessageMetadataResearchReferencesWebPage(message.metadata) ||
      isMessageMetadataResearchStatusUpdate(message.metadata) ||
      isMessageMetadataResearchSummary(message.metadata) ||
      isMessageMetadataSchedulerMetrics(message.metadata)
    )
  ) {
    log.debug(`isMessage > invalid metadata ${JSON.stringify(message)}`);
    return false;
  }

  if ('is_read' in message && !isBoolean(message.is_read)) {
    log.debug(`isMessage > invalid is_read ${JSON.stringify(message)}`);
    return false;
  }

  if ('is_final_answer' in message && !isBoolean(message.is_final_answer)) {
    log.debug(`isMessage > invalid is_final_answer ${JSON.stringify(message)}`);
    return false;
  }

  if ('task_id' in message && !isString(message.task_id)) {
    log.debug(`isMessage > invalid task_id ${JSON.stringify(message)}`);
    return false;
  }

  if ('tag' in message && !isString(message.tag)) {
    log.debug(`isMessage > invalid tag ${JSON.stringify(message)}`);
    return false;
  }

  if (
    'feedback_type' in message &&
    !(
      message.feedback_type === undefined ||
      Object.values(FeedbackType).includes(message.feedback_type)
    )
  ) {
    log.debug(`isMessage > invalid feedback_type ${JSON.stringify(message)}`);
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchReferencesWebPage() tests data against
 * MessageMetadataResearchReferencesWebPage data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferencesWebPage(
  data: unknown,
): data is MessageMetadataResearchReferencesWebPage {
  const webpage = data as MessageMetadataResearchReferencesWebPage;

  if (!isObject(webpage)) {
    log.debug(
      `isMessageMetadataResearchReferencesWebPage > not an object ${JSON.stringify(
        webpage,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in webpage &&
    webpage.payload_type !== 'research-references-web-page'
  ) {
    return false;
  }

  if (!('data' in webpage && Array.isArray(webpage.data))) {
    log.debug(
      `isMessageMetadataResearchReferencesWebPage > invalid data ${JSON.stringify(
        webpage,
      )}`,
    );
    return false;
  }

  for (const page of webpage.data) {
    if (!isResearchDataReferenceWebPage(page)) {
      log.debug(
        `isMessageMetadataResearchReferencesWebPage > invalid page ${JSON.stringify(
          page,
        )} in ${JSON.stringify(webpage)}`,
      );
      return false;
    }
  }

  return true;
}

/**
 * isMessageMetadataResearchStatusUpdate()
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchStatusUpdate(
  data: unknown,
): data is MessageMetadataResearchStatusUpdate {
  const update = data as MessageMetadataResearchStatusUpdate;

  if (!isObject(update)) {
    log.debug(
      `isMessageMetadataResearchStatusUpdate > not an object ${JSON.stringify(
        update,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in update &&
    update.payload_type !== 'research-status-update'
  ) {
    return false;
  }

  if (!('data' in update && isString(update.data))) {
    log.debug(
      `isMessageMetadataResearchStatusUpdate > invalid data ${JSON.stringify(
        update,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchSummary()
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchSummary(
  data: unknown,
): data is MessageMetadataResearchSummary {
  const summary = data as MessageMetadataResearchSummary;

  if (!isObject(summary)) {
    log.debug(
      `isMessageMetadataResearchSummary > not an object ${JSON.stringify(
        summary,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in summary &&
    summary.payload_type !== 'research-summary'
  ) {
    return false;
  }

  if (!('data' in summary && isString(summary.data))) {
    log.debug(
      `isMessageMetadataResearchSummary > invalid data ${JSON.stringify(
        summary,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageMetadataSchedulerMetrics() test data against
 * MessageMetadataSchedulerMetrics data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataSchedulerMetrics(
  data: unknown,
): data is MessageMetadataSchedulerMetrics {
  const metrics = data as MessageMetadataSchedulerMetrics;

  if (!isObject(metrics)) {
    log.debug(
      `isMessageMetadataSchedulerMetrics > not an object ${JSON.stringify(
        metrics,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in metrics &&
    metrics.payload_type !== 'scheduler-metrics'
  ) {
    return false;
  }

  if (!('data' in metrics && isSchedulerCreateEventMetrics(metrics.data))) {
    log.debug(
      `isMessageMetadataSchedulerMetrics > invalid data ${JSON.stringify(
        metrics,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isSchedulerCreateEventMetrics() test data against
 * SchedulerCreateEventMetrics data type.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulerCreateEventMetrics(
  data: unknown,
): data is SchedulerCreateEventMetrics {
  const scheduler = data as SchedulerCreateEventMetrics;

  if (!('num_attendees' in scheduler && isNumber(scheduler.num_attendees))) {
    log.debug(
      `isSchedulerCreateEventMetrics > invalid num_attendees ${JSON.stringify(
        scheduler,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchReferencesImageVideo() test data against
 * MessageMetadataResearchReferencesImageVideo data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferencesImageVideo(
  data: unknown,
): data is MessageMetadataResearchReferencesImageVideo {
  const imageVideo = data as MessageMetadataResearchReferencesImageVideo;

  if (!isObject(imageVideo)) {
    log.debug(
      `isMessageMetadataResearchReferencesImageVideo > not an object ${JSON.stringify(
        imageVideo,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in imageVideo &&
    imageVideo.payload_type !== 'research-references-image-video'
  ) {
    return false;
  }

  if (!('data' in imageVideo && Array.isArray(imageVideo.data))) {
    log.debug(
      `isMessageMetadataResearchReferencesImageVideo > invalid data ${JSON.stringify(
        imageVideo,
      )}`,
    );
    return false;
  }

  for (const media of imageVideo.data) {
    if (
      !(
        isResearchDataReferenceImage(media) ||
        isResearchDataReferenceVideo(media)
      )
    ) {
      log.debug(
        `isMessageMetadataResearchReferencesImageVideo > invalid media ${JSON.stringify(
          media,
        )} in ${JSON.stringify(imageVideo)}`,
      );
      return false;
    }
  }

  return true;
}

/**
 * isMessageMetadataResearchReferenceLLM() tests against
 * MessageMetadataResearchReferenceLLM data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferenceLLM(
  data: unknown,
): data is MessageMetadataResearchReferenceLLM {
  const llm = data as MessageMetadataResearchReferenceLLM;

  if (!isObject(llm)) {
    log.debug(
      `isMessageMetadataResearchReferenceLLM > not an object ${JSON.stringify(
        llm,
      )}`,
    );
    return false;
  }

  if (
    !('payload_type' in llm && llm.payload_type !== 'research-reference-llm')
  ) {
    return false;
  }

  if (!('data' in llm && isResearchDataReferenceLLM(llm.data))) {
    log.debug(
      `isMessageMetadataResearchReferenceLLM > invalid data ${JSON.stringify(
        llm,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchReferenceCode() test against
 * MessageMetadataResearchReferenceCode data type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchReferenceCode(
  data: unknown,
): data is MessageMetadataResearchReferenceCode {
  const code = data as MessageMetadataResearchReferenceCode;

  if (!isObject(code)) {
    log.debug(
      `isMessageMetadataResearchReferenceCode > not an object ${JSON.stringify(
        code,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in code &&
    code.payload_type !== 'research-reference-code'
  ) {
    return false;
  }

  if (!('data' in code && isResearchDataReferenceCode(code.data))) {
    log.debug(
      `isMessageMetadataResearchReferenceCode > invalid data ${JSON.stringify(
        code,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isMessageMetadataResearchMetric() test data against
 * MessageMetadataResearchMetric type.
 * @param data unknown
 * @returns boolean
 */
export function isMessageMetadataResearchMetrics(
  data: unknown,
): data is MessageMetadataResearchMetrics {
  const metadataMetric = data as MessageMetadataResearchMetrics;

  if (!isObject(metadataMetric)) {
    log.debug(
      `isMessageMetadataResearchMetrics > not an object ${JSON.stringify(
        metadataMetric,
      )}`,
    );
    return false;
  }

  if (
    'payload_type' in metadataMetric &&
    metadataMetric.payload_type !== 'research-metrics'
  ) {
    return false;
  }

  if (
    !(
      'data' in metadataMetric && isResearchResponseMetrics(metadataMetric.data)
    )
  ) {
    log.debug(
      `isMessageMetadataResearchMetrics > invalid data ${JSON.stringify(
        metadataMetric,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchResponseMetrics() test data against ResearchResponseMetrics.
 * @param data unknown
 * @returns boolean
 */
export function isResearchResponseMetrics(
  data: unknown,
): data is ResearchResponseMetrics {
  const metrics = data as ResearchResponseMetrics;

  if (
    !('words_written_count' in metrics && isNumber(metrics.words_written_count))
  ) {
    log.debug(
      `isResearchResponseMetrics > words_written_count ${JSON.stringify(
        metrics,
      )}`,
    );
    return false;
  }

  if (!('words_read_count' in metrics && isNumber(metrics.words_read_count))) {
    log.debug(
      `isResearchResponseMetrics > words_read_count ${JSON.stringify(metrics)}`,
    );
    return false;
  }

  return true;
}

/**
 * isCalendarCard() tests data against CalendarCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isCalendarCard(data: unknown): data is CalendarCard {
  const calendar = data as CalendarCard;

  if (!isObject(calendar)) {
    log.debug(`isCalendarCard > not an object ${JSON.stringify(calendar)}`);
    return false;
  }

  if ('payload_type' in calendar && calendar.payload_type !== 'calendar-card') {
    return false;
  }

  if (!('dates' in calendar && isObject(calendar.dates))) {
    log.debug(
      `isCalendarCard > not an object dates ${JSON.stringify(calendar)}`,
    );
    return false;
  }

  for (const value of Object.values(calendar.dates)) {
    if (!Array.isArray(value)) {
      log.debug(`isCalendarCard > not an array dates ${JSON.stringify(value)}`);
      return false;
    }

    // attn: we will maybe need to omit deep testing
    // not to have double loops
    for (const valueItem of value) {
      if (!isCalendarCardEvent(valueItem)) {
        log.debug(
          `isCalendarCard > invalid event ${JSON.stringify(valueItem)}`,
        );
        return false;
      }
    }
  }

  return true;
}

/**
 * isCalendarCardEvent() tests data
 * @param data unknown
 * @returns boolean
 */
export function isCalendarCardEvent(data: unknown): data is CalendarCardEvent {
  const calendarEvent = data as CalendarCardEvent;

  if (!('event_id' in calendarEvent && isString(calendarEvent.event_id))) {
    log.debug(
      `isCalendarCardEvent > invalid event_id ${JSON.stringify(calendarEvent)}`,
    );
    return false;
  }

  if (!('title' in calendarEvent && isString(calendarEvent.title))) {
    log.debug(
      `isCalendarCardEvent > invalid title ${JSON.stringify(calendarEvent)}`,
    );
    return false;
  }

  if (!('start' in calendarEvent && isString(calendarEvent.start))) {
    log.debug(
      `isCalendarCardEvent > invalid start ${JSON.stringify(calendarEvent)}`,
    );
    return false;
  }

  if (!('end' in calendarEvent && isString(calendarEvent.end))) {
    log.debug(
      `isCalendarCardEvent > invalid end ${JSON.stringify(calendarEvent)}`,
    );
    return false;
  }

  if ('action' in calendarEvent && !isString(calendarEvent.action)) {
    log.debug(
      `isCalendarCardEvent > invalid action ${JSON.stringify(calendarEvent)}`,
    );
    return false;
  }

  if (
    'is_recurring' in calendarEvent &&
    !isBoolean(calendarEvent.is_recurring)
  ) {
    log.debug(
      `isCalendarCardEvent > invalid is_recurring ${JSON.stringify(
        calendarEvent,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isEmailCollaborator() tests data against EmailCollaborator/EmailAddress data type.
 * We import EmailAddress as EmailCollaborator due to clash with EmailAddress from AWS types.
 * @param data unknown
 * @returns boolean
 */
export function isEmailCollaborator(data: unknown): data is EmailCollaborator {
  const collaborator = data as EmailCollaborator;

  if (!isObject(collaborator)) {
    log.debug(
      `isEmailCollaborator > not an object ${JSON.stringify(collaborator)}`,
    );
    return false;
  }

  if (
    !('email_address' in collaborator && isString(collaborator.email_address))
  ) {
    log.debug(
      `isEmailCollaborator > invalid email_address ${JSON.stringify(
        collaborator,
      )}`,
    );
    return false;
  }

  if ('contact_uri' in collaborator && !isString(collaborator.contact_uri)) {
    log.debug(
      `isEmailCollaborator > invalid contact_uri ${JSON.stringify(
        collaborator,
      )}`,
    );
    return false;
  }

  if (
    !('display_name' in collaborator && isString(collaborator.display_name))
  ) {
    log.debug(
      `isEmailCollaborator > invalid display_name ${JSON.stringify(
        collaborator,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isEmailCard() tests data against EmailCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isEmailCard(data: unknown): data is EmailCard {
  const emailCard = data as EmailCard;

  if (!isObject(emailCard)) {
    log.debug(`isEmailCard > not an object ${JSON.stringify(emailCard)}`);
    return false;
  }

  if ('payload_type' in emailCard && emailCard.payload_type !== 'email-card') {
    return false;
  }

  if (!('sender' in emailCard && isEmailCollaborator(emailCard.sender))) {
    log.debug(`isEmailCard > invalid sender ${JSON.stringify(emailCard)}`);
    return false;
  }

  if (!('subject' in emailCard && isString(emailCard.subject))) {
    log.debug(`isEmailCard > invalid subject ${JSON.stringify(emailCard)}`);
    return false;
  }

  if (!('timestamp' in emailCard && isString(emailCard.timestamp))) {
    log.debug(`isEmailCard > invalid timestamp ${JSON.stringify(emailCard)}`);
    return false;
  }

  if (!('body' in emailCard && isString(emailCard.body))) {
    log.debug(`isEmailCard > invalid body ${JSON.stringify(emailCard)}`);
    return false;
  }

  if (!('to' in emailCard && Array.isArray(emailCard.to))) {
    log.debug(`isEmailCard > not an array to ${JSON.stringify(emailCard)}`);
    return false;
  }

  for (const email of emailCard.to) {
    if (!isEmailCollaborator(email)) {
      log.debug(`isEmailCard > invalid to email ${JSON.stringify(emailCard)}`);
      return false;
    }
  }

  if (!('cc' in emailCard && Array.isArray(emailCard.cc))) {
    log.debug(`isEmailCard > not an array cc ${JSON.stringify(emailCard)}`);
    return false;
  }

  for (const email of emailCard.cc) {
    if (!isEmailCollaborator(email)) {
      log.debug(`isEmailCard > invalid cc email ${JSON.stringify(emailCard)}`);
      return false;
    }
  }

  if ('thread_id' in emailCard && !isString(emailCard.thread_id)) {
    log.debug(`isEmailCard > invalid thread_id ${JSON.stringify(emailCard)}`);
    return false;
  }

  return true;
}

/**
 * isSearchWebPageReference() test data against SearchWebPageReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isSearchWebPageReference(
  data: unknown,
): data is SearchWebPageReference {
  const webReference = data as SearchWebPageReference;

  if (!isObject(webReference)) {
    return false;
  }

  if (webReference === null) {
    return false;
  }

  if (!('url' in webReference && isString(webReference.url))) {
    log.debug(
      `isSearchWebPageReference > invalid url ${JSON.stringify(webReference)}`,
    );
    return false;
  }

  if (!('title' in webReference && isString(webReference.title))) {
    log.debug(
      `isSearchWebPageReference > invalid title ${JSON.stringify(
        webReference,
      )}`,
    );
    return false;
  }

  if (!('snippet' in webReference && isString(webReference.snippet))) {
    log.debug(
      `isSearchWebPageReference > invalid snippet ${JSON.stringify(
        webReference,
      )}`,
    );
    return false;
  }

  if ('favicon' in webReference && !isString(webReference.favicon)) {
    log.debug(
      `isSearchWebPageReference > invalid favicon ${JSON.stringify(
        webReference,
      )}`,
    );
    return false;
  }

  if ('source' in webReference && !isString(webReference.source)) {
    log.debug(
      `isSearchWebPageReference > invalid source ${JSON.stringify(
        webReference,
      )}`,
    );
    return false;
  }

  if ('domain' in webReference && !isString(webReference.domain)) {
    log.debug(
      `isSearchWebPageReference > invalid domain ${JSON.stringify(
        webReference,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isSearchImageReference() test data against SearchImageReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isSearchImageReference(
  data: unknown,
): data is SearchImageReference {
  const imageReference = data as SearchImageReference;

  if (!('url' in imageReference && isString(imageReference.url))) {
    log.debug(
      `isSearchImageReference > invalid url ${JSON.stringify(imageReference)}`,
    );
    return false;
  }

  if (!('title' in imageReference && isString(imageReference.title))) {
    log.debug(
      `isSearchImageReference > invalid title ${JSON.stringify(
        imageReference,
      )}`,
    );
    return false;
  }

  if (!('height' in imageReference && isNumber(imageReference.height))) {
    log.debug(
      `isSearchImageReference > invalid height ${JSON.stringify(
        imageReference,
      )}`,
    );
    return false;
  }

  if (!('width' in imageReference && isNumber(imageReference.width))) {
    log.debug(
      `isSearchImageReference > invalid width ${JSON.stringify(
        imageReference,
      )}`,
    );
    return false;
  }

  if (!('source' in imageReference && isString(imageReference.source))) {
    log.debug(
      `isSearchImageReference > invalid source ${JSON.stringify(
        imageReference,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isSearchVideoReference() tests against SearchVideoReference data type.
 * @param data unknown
 * @returns boolean
 */
export function isSearchVideoReference(
  data: unknown,
): data is SearchVideoReference {
  const videoReference = data as SearchVideoReference;

  if (!('url' in videoReference && isString(videoReference.url))) {
    return false;
  }

  if (!('title' in videoReference && isString(videoReference.title))) {
    return false;
  }

  if ('height' in videoReference && !isNumber(videoReference.height)) {
    return false;
  }

  if ('width' in videoReference && !isNumber(videoReference.width)) {
    return false;
  }

  if (
    !(
      'displayed_url' in videoReference &&
      isString(videoReference.displayed_url)
    )
  ) {
    return false;
  }

  if (!('duration' in videoReference && isString(videoReference.duration))) {
    return false;
  }

  if (!('snippet' in videoReference && isString(videoReference.snippet))) {
    return false;
  }

  if (!('thumbnail' in videoReference && isString(videoReference.thumbnail))) {
    return false;
  }

  return true;
}

/**
 * isSearchResult() tests data against SearchResult data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchSearchResult(
  data: unknown,
): data is ResearchSearchResult {
  const searchResult = data as ResearchSearchResult;

  if ('summary' in searchResult && !isString(searchResult.summary)) {
    log.debug(
      `isResearchSearchResult > invalid summary ${JSON.stringify(
        searchResult,
      )}`,
    );
    return false;
  }

  if ('web_page_references' in searchResult) {
    if (!Array.isArray(searchResult.web_page_references)) {
      log.debug(
        `isResearchSearchResult > not an array web_page_references ${JSON.stringify(
          searchResult,
        )}`,
      );
      return false;
    }

    for (const reference of searchResult.web_page_references) {
      if (!isSearchWebPageReference(reference)) {
        log.debug(
          `isResearchSearchResult > not a web page reference ${JSON.stringify(
            reference,
          )} in ${JSON.stringify(searchResult)}`,
        );
        return false;
      }
    }
  }

  if ('image_references' in searchResult) {
    if (!Array.isArray(searchResult.image_references)) {
      log.debug(
        `isResearchSearchResult > not an array image_references ${JSON.stringify(
          searchResult,
        )}`,
      );
      return false;
    }

    for (const reference of searchResult.image_references) {
      if (!isSearchImageReference(reference)) {
        log.debug(
          `isResearchSearchResult > not an image reference ${JSON.stringify(
            reference,
          )} in ${JSON.stringify(searchResult)}`,
        );
        return false;
      }
    }
  }

  if ('video_references' in searchResult) {
    if (!Array.isArray(searchResult.video_references)) {
      log.debug(
        `isResearchSearchResult > not an array video_references ${JSON.stringify(
          searchResult,
        )}`,
      );
      return false;
    }

    for (const reference of searchResult.video_references) {
      if (!isSearchVideoReference(reference)) {
        log.debug(
          `isResearchSearchResult > not a video reference ${JSON.stringify(
            reference,
          )} in ${JSON.stringify(searchResult)}`,
        );
        return false;
      }
    }
  }

  return true;
}

/**
 * isSearchResult() tests data against DeepSearchResult data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchDeepSearchResult(
  data: unknown,
): data is DeepResearchResult {
  const searchResult = data as DeepResearchResult;

  if ('summary' in searchResult && !isString(searchResult.summary)) {
    log.debug(
      `isResearchDeepSearchResult > invalid summary ${JSON.stringify(
        searchResult,
      )}`,
    );
    return false;
  }

  if ('web_page_references' in searchResult) {
    if (!Array.isArray(searchResult.web_page_references)) {
      log.debug(
        `isResearchDeepSearchResult > invalid web_page_references ${JSON.stringify(
          searchResult,
        )}`,
      );
      return false;
    }

    for (const reference of searchResult.web_page_references) {
      if (!isSearchWebPageReference(reference)) {
        log.debug(
          `isResearchDeepSearchResult > invalid web_page_references ${JSON.stringify(
            searchResult,
          )}`,
        );
        return false;
      }
    }
  }

  if ('image_references' in searchResult) {
    if (!Array.isArray(searchResult.image_references)) {
      return false;
    }

    for (const reference of searchResult.image_references) {
      if (!isSearchImageReference(reference)) {
        log.debug(
          `isResearchDeepSearchResult > invalid image_references ${JSON.stringify(
            searchResult,
          )}`,
        );
        return false;
      }
    }
  }

  if ('video_references' in searchResult) {
    if (!Array.isArray(searchResult.video_references)) {
      log.debug(
        `isResearchDeepSearchResult > invalid video_references ${JSON.stringify(
          searchResult,
        )}`,
      );
      return false;
    }

    for (const reference of searchResult.video_references) {
      if (!isSearchVideoReference(reference)) {
        log.debug(
          `isResearchDeepSearchResult > invalid video_references ${JSON.stringify(
            searchResult,
          )}`,
        );
        return false;
      }
    }
  }

  return true;
}

/**
 * isAiReference() tests data against AiReference data type.
 * todo: this type got removed at BE, follow up
 * @param data unknown
 * @returns boolean
 */
export function isAiReference(data: unknown): data is AiReference {
  const aiReference = data as AiReference;

  if (
    !(
      'source' in aiReference &&
      Object.values(ResearchAi).includes(aiReference.source)
    )
  ) {
    log.debug(`isAiReference > invalid source ${JSON.stringify(aiReference)}`);
    return false;
  }

  if (!('content' in aiReference && isString(aiReference.content))) {
    log.debug(`isAiReference > invalid content ${JSON.stringify(aiReference)}`);
    return false;
  }

  return true;
}

/**
 * isAiResult() tests data against AiResult data type.
 * Att: metadata_info is only needed by BE & not required in FE.
 * @param data unknown
 * @returns boolean
 */
export function isAiResult(data: unknown): data is AiResult {
  const aiResult = data as AiResult;

  if ('summary' in aiResult && !isString(aiResult.summary)) {
    log.debug(`isAiResult > invalid summary ${JSON.stringify(aiResult)}`);
    return false;
  }

  if ('references' in aiResult) {
    if (!isObject(aiResult.references)) {
      log.debug(`isAiResult > invalid references ${JSON.stringify(aiResult)}`);
      return false;
    }

    for (const reference of Object.values(aiResult.references)) {
      if (!isAiContent(reference)) {
        log.debug(`isAiResult > invalid reference ${JSON.stringify(aiResult)}`);
        return false;
      }
    }
  }

  // att: we are not using metadata_info and omitting it here
  return true;
}

/**
 * isResearchCardData() tests data against ResearchCardData data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchCardData(data: unknown): data is ResearchCardData {
  const researchCard = data as ResearchCardData;

  if ('query' in researchCard && !isString(researchCard.query)) {
    log.debug(
      `isResearchCardData > invalid query ${JSON.stringify(researchCard)}`,
    );
    return false;
  }

  if (
    'search_result' in researchCard &&
    !isResearchSearchResult(researchCard.search_result)
  ) {
    log.debug(
      `isResearchCardData > invalid search_result ${JSON.stringify(
        researchCard,
      )}`,
    );
    return false;
  }

  if (
    'deep_research_result' in researchCard &&
    !isResearchDeepSearchResult(researchCard.deep_research_result)
  ) {
    log.debug(
      `isResearchCardData > invalid deep_research_result ${JSON.stringify(
        researchCard,
      )}`,
    );
    return false;
  }

  if (
    'generative_ai_result' in researchCard &&
    !isAiResult(researchCard.generative_ai_result)
  ) {
    log.debug(
      `isResearchCardData > invalid generative_ai_result ${JSON.stringify(
        researchCard,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isResearchCard() test data against ResearchCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchCard(data: unknown): data is ResearchCard {
  const researchCard = data as ResearchCard;

  if (!isObject(researchCard)) {
    log.debug(`isResearchCard > not an object ${JSON.stringify(researchCard)}`);
    return false;
  }

  if (
    'payload_type' in researchCard &&
    researchCard.payload_type !== 'research-card'
  ) {
    return false;
  }

  if (!('data' in researchCard && isResearchCardData(researchCard.data))) {
    log.debug(`isResearchCard > invalid data ${JSON.stringify(researchCard)}`);
    return false;
  }

  return true;
}

/**
 * isResearchTaskCreationCard() tests data against ResearchTaskCreationCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isResearchTaskCreationCard(
  data: unknown,
): data is ResearchTaskCreationCard {
  const researchTaskCreationCard = data as ResearchTaskCreationCard;

  if (!isObject(researchTaskCreationCard)) {
    return false;
  }

  if (
    'payload_type' in researchTaskCreationCard &&
    researchTaskCreationCard.payload_type !== 'research-task-creation-card'
  ) {
    return false;
  }

  if (
    'research_card' in researchTaskCreationCard &&
    !isResearchCard(researchTaskCreationCard.research_card)
  ) {
    return false;
  }

  if (
    !(
      'task_id' in researchTaskCreationCard &&
      isString(researchTaskCreationCard.task_id)
    )
  ) {
    return false;
  }

  if (
    !(
      'task_hash' in researchTaskCreationCard &&
      isString(researchTaskCreationCard.task_hash)
    )
  ) {
    return false;
  }

  if (
    !(
      'task_query' in researchTaskCreationCard &&
      isString(researchTaskCreationCard.task_query)
    )
  ) {
    return false;
  }

  if (
    'progress' in researchTaskCreationCard &&
    !isProgressUpdate(researchTaskCreationCard.progress)
  ) {
    return false;
  }

  return true;
}

export function isChitChatCard(data: unknown): data is ChitChatCard {
  const chitChatCard = data as ChitChatCard;

  if (!isObject(chitChatCard)) {
    return false;
  }

  if (
    'payload_type' in chitChatCard &&
    chitChatCard.payload_type !== 'chit-chat-card'
  ) {
    return false;
  }

  return true;
}

export function isImageGenerationCard(data: unknown): data is ImageCard {
  const imageCard = data as ImageCard;

  if (!isObject(imageCard)) {
    return false;
  }

  if ('payload_type' in imageCard && imageCard.payload_type !== 'image-card') {
    return false;
  }

  return true;
}

/**
 * isErrorCard() tests data against ErrorPayload data type.
 * @param data unknown
 * @returns boolean
 */
export function isErrorCard(data: unknown): data is ErrorPayload {
  const errorCard = data as ErrorPayload;

  if (!isObject(errorCard)) {
    log.debug(`isErrorCard > not an object ${JSON.stringify(errorCard)}`);
    return false;
  }

  if (
    'payload_type' in errorCard &&
    errorCard.payload_type !== 'error-message'
  ) {
    return false;
  }

  return true;
}

/**
 * isCodeCard() test data against CodeCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isCodeCard(data: unknown): data is CodeCard {
  const codeCard = data as CodeCard;

  if (!isObject(codeCard)) {
    return false;
  }

  if ('payload_type' in codeCard && codeCard.payload_type !== 'code-card') {
    return false;
  }

  return true;
}

/**
 * isCodeTaskCreationCardCard() tests data against CodeTaskCreationCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isCodeTaskCreationCard(
  data: unknown,
): data is CodeTaskCreationCard {
  const codeTaskCreationCard = data as CodeTaskCreationCard;

  if (!isObject(codeTaskCreationCard)) {
    return false;
  }

  if (
    'payload_type' in codeTaskCreationCard &&
    codeTaskCreationCard.payload_type !== 'code-task-creation-card'
  ) {
    return false;
  }

  if (
    !(
      'task_id' in codeTaskCreationCard &&
      isString(codeTaskCreationCard.task_id)
    )
  ) {
    log.debug(
      `isCodeTaskCreationCard > task_id not a string in ${JSON.stringify(
        codeTaskCreationCard,
      )}`,
    );
    return false;
  }

  if (
    !(
      'task_hash' in codeTaskCreationCard &&
      isString(codeTaskCreationCard.task_hash)
    )
  ) {
    log.debug(
      `isCodeTaskCreationCard > task_hash not a string in ${JSON.stringify(
        codeTaskCreationCard,
      )}`,
    );
    return false;
  }

  if (
    !(
      'task_query' in codeTaskCreationCard &&
      isString(codeTaskCreationCard.task_query)
    )
  ) {
    log.debug(
      `isCodeTaskCreationCard > task_query not a string in ${JSON.stringify(
        codeTaskCreationCard,
      )}`,
    );
    return false;
  }

  if (
    'progress' in codeTaskCreationCard &&
    !isProgressUpdate(codeTaskCreationCard.progress)
  ) {
    log.debug(
      `isCodeTaskCreationCard > not a progress update in ${JSON.stringify(
        codeTaskCreationCard,
      )}`,
    );
    return false;
  }

  if (
    'code_card' in codeTaskCreationCard &&
    !isCodeCard(codeTaskCreationCard.code_card)
  ) {
    log.debug(
      `isCodeTaskCreationCard > not a code_card in ${JSON.stringify(
        codeTaskCreationCard,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isCreateMeetingCard() tests data against CreateMeetingCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isCreateMeetingCard(data: unknown): data is CreateMeetingCard {
  const codeTaskCreationCard = data as CreateMeetingCard;

  if (!isObject(codeTaskCreationCard)) {
    return false;
  }

  if (
    'payload_type' in codeTaskCreationCard &&
    codeTaskCreationCard.payload_type !== 'creating-meeting-card'
  ) {
    return false;
  }

  return true;
}

/**
 * isSchedulerCreationCard() tests data against SchedulerCreationCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isSchedulerCreationCard(
  data: unknown,
): data is SchedulerCreationCard {
  const schedulerCreationCard = data as SchedulerCreationCard;

  if (!isObject(schedulerCreationCard)) {
    return false;
  }

  if (
    'payload_type' in schedulerCreationCard &&
    schedulerCreationCard.payload_type !== 'scheduler-task-creation-card'
  ) {
    return false;
  }

  // todo: add all missing properties, incomplete

  return true;
}

/**
 * isAiContent() tests data against AiContent data type
 * @param data unknown
 * @returns boolean
 */
export function isAiContent(data: unknown): data is AiContent {
  const aiContent = data as AiContent;

  if ('content' in aiContent && !isString(aiContent.content)) {
    log.debug(`isAiContent > invalid content ${JSON.stringify(aiContent)}`);
    return false;
  }

  return true;
}

/**
 * isChoices() tests data against Choices data type.
 * @param data unknown
 * @returns boolean
 */
export function isChoices(data: unknown): data is Choices {
  const choices = data as Choices;

  if ('multi_select' in choices && !isBoolean(choices.multi_select)) {
    log.debug(`isChoices > invalid multi_select ${JSON.stringify(choices)}`);
    return false;
  }

  if ('select_key' in choices && !isString(choices.select_key)) {
    log.debug(`isChoices > invalid select_key ${JSON.stringify(choices)}`);
    return false;
  }

  if ('choice_list' in choices) {
    if (!Array.isArray(choices.choice_list)) {
      log.debug(`isChoices > not an array ${JSON.stringify(choices)}`);
      return false;
    }

    for (const choice of choices.choice_list) {
      if (!isChoice(choice)) {
        log.debug(`isChoices > invalid choice ${JSON.stringify(choices)}`);
        return false;
      }
    }
  }

  return true;
}

/**
 * isChoice() typeguards Choice data type.
 * @param data unknown
 * @returns boolean
 */
export function isChoice(data: unknown): data is Choice {
  const choice = data as Choice;

  if ('title' in choice && !isString(choice.title)) {
    log.debug(`isChoice > invalid title ${JSON.stringify(choice)}`);
    return false;
  }

  if ('description' in choice && !isString(choice.description)) {
    log.debug(`isChoice > invalid description ${JSON.stringify(choice)}`);
    return false;
  }

  if ('choices' in choice && !isChoices(choice.choices)) {
    log.debug(`isChoice > invalid choices ${JSON.stringify(choice)}`);
    return false;
  }

  if ('selected' in choice && !isBoolean(choice.selected)) {
    log.debug(`isChoice > invalid selected ${JSON.stringify(choice)}`);
    return false;
  }

  if ('select_value' in choice && !isString(choice.select_value)) {
    log.debug(`isChoice > invalid select_value ${JSON.stringify(choice)}`);
    return false;
  }

  return true;
}

/**
 * isChoicesCard() tests data against ChoicesCard data type.
 * @param data unknown
 * @returns boolean
 */
export function isChoicesCard(data: unknown): data is ChoicesCard {
  const choicesCard = data as ChoicesCard;

  if (!isObject(choicesCard)) {
    log.debug(`isChoicesCard > not an object ${JSON.stringify(choicesCard)}`);
    return false;
  }

  if (
    'payload_type' in choicesCard &&
    choicesCard.payload_type !== 'choices-card'
  ) {
    return false;
  }

  if ('description' in choicesCard && !isString(choicesCard.description)) {
    log.debug(
      `isChoicesCard > invalid description ${JSON.stringify(choicesCard)}`,
    );
    return false;
  }

  if (!('link' in choicesCard && isString(choicesCard.link))) {
    log.debug(`isChoicesCard > invalid link ${JSON.stringify(choicesCard)}`);
    return false;
  }

  if ('choices' in choicesCard && !isChoices(choicesCard.choices)) {
    log.debug(`isChoicesCard > invalid choices ${JSON.stringify(choicesCard)}`);
    return false;
  }

  if ('executed' in choicesCard && !isBoolean(choicesCard.executed)) {
    log.debug(
      `isChoicesCard > invalid executed ${JSON.stringify(choicesCard)}`,
    );
    return false;
  }

  return true;
}

/**
 * isApiMinimumTask() tests data against ApiMinimumTask data type.
 * @param data unknown
 * @returns boolean
 */
export function isApiMinimumTask(data: unknown): data is ApiMinimumTask {
  const taskMinimum = data as ApiMinimumTask;

  if (!isObject(taskMinimum)) {
    log.debug(
      `isApiMinimumTask > not an object ${JSON.stringify(taskMinimum)}`,
    );
    return false;
  }

  if ('task_id' in taskMinimum && !isString(taskMinimum.task_id)) {
    log.debug(
      `isApiMinimumTask > invalid task_id ${JSON.stringify(taskMinimum)}`,
    );
    return false;
  }

  if (!('user_id' in taskMinimum && isString(taskMinimum.user_id))) {
    log.debug(`isApiMinimumTask > mandatory fields (user_id) missing`);
    return false;
  }

  if ('task_hash' in taskMinimum && !isString(taskMinimum.task_hash)) {
    log.debug(
      `isApiMinimumTask > invalid task_hash ${JSON.stringify(taskMinimum)}`,
    );
    return false;
  }

  if (
    'state' in taskMinimum &&
    !(
      isString(taskMinimum.state) &&
      Object.values(TaskState).includes(taskMinimum.state)
    )
  ) {
    log.debug(
      `isApiMinimumTask > invalid state ${JSON.stringify(taskMinimum)}`,
    );
    return false;
  }

  if (
    !(
      'skill' in taskMinimum &&
      isString(taskMinimum.skill) &&
      Object.values(TaskSkill).includes(taskMinimum.skill)
    )
  ) {
    log.debug(
      `isApiMinimumTask > invalid skill ${JSON.stringify(taskMinimum)}`,
    );
    return false;
  }

  if (
    'requires_attention' in taskMinimum &&
    !isBoolean(taskMinimum.requires_attention)
  ) {
    log.debug(
      `isApiMinimumTask > invalid requires_attention ${JSON.stringify(
        taskMinimum,
      )}`,
    );
    return false;
  }

  if (
    'conversation_id' in taskMinimum &&
    !isString(taskMinimum.conversation_id)
  ) {
    log.debug(
      `isApiMinimumTask > invalid conversation_id ${JSON.stringify(
        taskMinimum,
      )}`,
    );
    return false;
  }

  if ('created_at' in taskMinimum && !isString(taskMinimum.created_at)) {
    log.debug(
      `isApiMinimumTask > invalid created_at ${JSON.stringify(taskMinimum)}`,
    );
    return false;
  }

  if ('updated_at' in taskMinimum && !isString(taskMinimum.updated_at)) {
    log.debug(
      `isApiMinimumTaskList > invalid updated_at ${JSON.stringify(
        taskMinimum,
      )}`,
    );
    return false;
  }

  return true;
}

/**
 * isApiMinimumTaskList() tests data
 * @param data unknown
 * @returns boolean
 */
export function isApiMinimumTaskList(data: unknown): data is ApiMinimumTask[] {
  const list = data as ApiMinimumTask[];

  if (!Array.isArray(list)) {
    log.debug(`isApiMinimumTaskList > not an array ${JSON.stringify(list)}`);
    return false;
  }

  for (const task of list) {
    if (!isApiMinimumTask(task)) {
      log.debug(`isApiMinimumTaskList > invalid task ${JSON.stringify(task)}`);
      return false;
    }
  }

  return true;
}

export type CreationCardWithTaskId =
  | ResearchTaskCreationCard
  | CodeTaskCreationCard
  | SchedulerCreationCard;

/**
 * Checks if the given payload contains a 'task_id' property.
 * @param payload unknown
 * @returns boolean
 */
export function hasTaskId(payload: unknown): payload is CreationCardWithTaskId {
  return (
    payload !== null && typeof payload === 'object' && 'task_id' in payload
  );
}

/**
 * Determines whether a message has a payload that includes a creation task ID.
 * @param message Message
 * @returns boolean
 */
export function isMessageWithCreationTaskId(
  message: Message,
): message is Message & { payload: CreationCardWithTaskId } {
  return message.payload !== undefined && hasTaskId(message.payload);
}

export function isExternalModelReference(
  data: unknown,
): data is ExternalModelReference {
  const externalModelReference = data as SearchVideoReference;

  if (!isObject(externalModelReference)) {
    return false;
  }

  if (externalModelReference === null) {
    return false;
  }

  if (!('url' in externalModelReference)) {
    return false;
  }

  if (!('title' in externalModelReference)) {
    return false;
  }

  if (!('icon' in externalModelReference)) {
    return false;
  }

  if (!('content' in externalModelReference)) {
    return false;
  }

  return true;
}

export function isImageGeneratedItem(
  data: unknown,
): data is ImageGenerationItem {
  const imageGenerationItem = data;

  if (!isObject(imageGenerationItem)) {
    return false;
  }

  if (imageGenerationItem === null) {
    return false;
  }

  if (!('url' in imageGenerationItem)) {
    return false;
  }

  if (!('title' in imageGenerationItem)) {
    return false;
  }

  if (!('icon' in imageGenerationItem)) {
    return false;
  }

  return true;
}
