import { useCallback, useContext } from 'react';
import classNames from 'classnames';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useCommands, useSession } from 'src/hooks';
import { useQuickPickerControls } from '../../hooks/useQuickPickerControls';

interface QuickCommandPickerProps {
  isOpen: boolean;
  onClose: () => void;
  setCursorPosition: (position: number) => void;
}

export const QuickCommandPicker = ({
  isOpen,
  onClose,
  setCursorPosition,
}: QuickCommandPickerProps) => {
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { appUser } = useSession();

  const { matchingCommands } = useCommands({
    shouldSearch: isOpen,
  });

  const handleCommandClick = useCallback(
    (index: number) => {
      const command = matchingCommands[index].title;
      const insertValue = `${command} `;
      setThreadInputBoxValue(insertValue);
      setCursorPosition(insertValue.length);
    },
    [matchingCommands, setThreadInputBoxValue, setCursorPosition],
  );

  const { selectedIndex } = useQuickPickerControls({
    options: matchingCommands,
    isOpen,
    shouldFocusInputBox: true,
    onClose,
    onItemClick: handleCommandClick,
  });

  const commands = appUser?.settings?.scheduler?.is_capable
    ? matchingCommands
    : matchingCommands.filter((item) => item.title !== '/scheduler');

  if (!isOpen || commands.length === 0) {
    return null;
  }

  return (
    <div
      onClick={onClose}
      className="nj-thread-input-box--quick-picker contacts"
      role="menu"
    >
      {commands.map(({ title, description }, index) => (
        <div
          className={classNames('nj-thread-input-box--quick-picker-item', {
            selected: index === selectedIndex,
          })}
          key={title}
          onMouseDown={(e) => {
            e.preventDefault();
            handleCommandClick(index);
          }}
        >
          <div>
            <p className="nj-thread-input-box--quick-picker-title bold">
              {title}
            </p>
            <p className="nj-thread-input-box--quick-picker-title">
              {description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};
