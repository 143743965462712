import { useContext } from 'react';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { PaperPlaneRight } from '@phosphor-icons/react';
import styles from './QuestionItem.module.scss';
import { useSubmitUserInput } from 'src/hooks';

const SVG_SIZE = 20;

interface RelatedQuestionsItemProps {
  question: string;
}

export const QuestionItem = ({ question }: RelatedQuestionsItemProps) => {
  const { setThreadInputBoxValue } = useContext(ThreadInputBoxContext);
  const { onSubmitUserInput } = useSubmitUserInput();

  const handleClick = () => {
    setThreadInputBoxValue(question);

    onSubmitUserInput(question);
  };

  return (
    <button onClick={handleClick} className={styles.root}>
      <span className={styles.content}>{question}</span>

      <span className={styles.icon}>
        <PaperPlaneRight size={SVG_SIZE} />
      </span>
    </button>
  );
};
