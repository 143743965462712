import { createApi } from '@reduxjs/toolkit/query/react';
import { baseNondebugFeedbackQuery } from './config';
import {
  PositiveTaskFeedbackRequest,
  NegativeTaskFeedbackRequest,
  PositiveMessageFeedbackRequest,
  NegativeMessageFeedbackRequest,
} from 'src/types';
import { CreateFeedbackRequest } from 'src/types/models/CreateFeedbackRequest';
import { PatchFeedbackRequest } from 'src/types/models/PatchFeedbackRequest';
import { FeedbackResponse } from 'src/types/models/FeedbackResponse';
import { DeleteFeedbackRequest } from 'src/types/models/DeleteFeedbackRequest';

export const nondebugFeedbackApi = createApi({
  reducerPath: 'nondebugFeedbackApi',
  baseQuery: baseNondebugFeedbackQuery,
  endpoints: (build) => ({
    createFeedback: build.mutation<FeedbackResponse, CreateFeedbackRequest>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
    }),
    updateFeedback: build.mutation<void, PatchFeedbackRequest>({
      query: (body) => ({
        url: `/${body.feedback_id}`,
        method: 'PATCH',
        body,
      }),
    }),
    deleteFeedback: build.mutation<void, DeleteFeedbackRequest>({
      query: (body) => ({
        url: `/${body.feedback_id}`,
        method: 'DELETE',
        body,
      }),
    }),
    sendPositiveNondebugFeedback: build.mutation<
      void,
      PositiveTaskFeedbackRequest
    >({
      query: (body) => ({
        url: '/positive_task_feedback',
        method: 'POST',
        body,
      }),
    }),
    sendNegativeNondebugFeedback: build.mutation<
      void,
      NegativeTaskFeedbackRequest
    >({
      query: (body) => ({
        url: '/negative_task_feedback',
        method: 'POST',
        body,
      }),
    }),
    sendPositivePerMessageFeedback: build.mutation<
      void,
      PositiveMessageFeedbackRequest
    >({
      query: (body) => ({
        url: '/positive_message_feedback',
        method: 'POST',
        body,
      }),
    }),
    sendNegativePerMessageFeedback: build.mutation<
      void,
      NegativeMessageFeedbackRequest
    >({
      query: (body) => ({
        url: '/negative_message_feedback',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSendPositiveNondebugFeedbackMutation,
  useSendNegativeNondebugFeedbackMutation,
  useSendPositivePerMessageFeedbackMutation,
  useSendNegativePerMessageFeedbackMutation,
  useCreateFeedbackMutation,
  useUpdateFeedbackMutation,
  useDeleteFeedbackMutation,
} = nondebugFeedbackApi;
