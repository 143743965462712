/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ErrorCode {
  QUOTA_EXCEPTION = 'quota_exception',
  SKILL_NOT_SUPPORTED = 'skill_not_supported',
}
