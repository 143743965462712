import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tasksApi } from '../services';
import { fetchNextPageTasks, fetchTaskById } from '../thunks';
import {
  ApiTask,
  ApiTaskSelectable,
  Message,
  TaskChannel,
  TaskSkill,
  FeedbackType,
  OperationType,
} from 'src/types';
import { RootState } from '../index';
import { DEFAULT_AGENT } from 'src/constants';
import { recursiveDeepMerge } from 'src/utils';
import { TasksByPageResponse } from 'src/types';
import { PURGE } from 'reduxjs-toolkit-persist';

type UserTasks = Partial<ApiTaskSelectable>[];

interface UserTasksState {
  tasks: UserTasks;
  pageToken?: string;
}

const initialState: UserTasksState = {
  tasks: [] as Partial<ApiTaskSelectable>[],
};

interface FeedbackTypeData {
  task_id: string;
  feedback_type: FeedbackType;
}

// TODO(olha): looks like deprecated. Needs to be checked
export const userTasksSlice = createSlice({
  name: 'userTasks',
  initialState,
  reducers: {
    updateTaskMessage: (
      state,
      action: PayloadAction<{
        task_id: string;
        user_id: string;
        message: Partial<Message>;
      }>,
    ) => {
      const focusTask = state.tasks.find(
        (task) => task.task_id === action.payload.task_id,
      );

      if (!focusTask) return state;

      const { message_id } = action.payload.message;
      const focusMessage = focusTask?.conversation?.messages?.find(
        (message) => message.message_id === message_id,
      );

      if (!focusMessage) return state;

      const updatedFocusMessage = {
        ...focusMessage,
        ...action.payload.message,
      };

      const updatedFocusTaskMessages = (
        focusTask?.conversation?.messages || []
      ).map((message) => {
        return message.message_id === message_id
          ? updatedFocusMessage
          : message;
      });

      focusTask.conversation = {
        ...(focusTask?.conversation || {}),
        user_id: action.payload.user_id,
        messages: updatedFocusTaskMessages,
      };

      const updatedTasks = state.tasks.map((task) => {
        return task.task_id === action.payload.task_id ? focusTask : task;
      });

      state.tasks = updatedTasks;
    },
    markMessagesIsRead: (
      state,
      action: PayloadAction<{
        task_id: string;
        user_id: string;
        is_read: boolean;
      }>,
    ) => {
      const { task_id, is_read, user_id } = action.payload;
      const taskToUpdate = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (taskToUpdate) {
        const messagesToMark = (taskToUpdate?.conversation?.messages || []).map(
          (message: Message) => {
            return {
              ...message,
              is_read,
            };
          },
        );

        taskToUpdate.conversation = {
          ...(taskToUpdate?.conversation || {}),
          user_id,
          messages: messagesToMark,
        };

        const updatedTasks: Partial<ApiTaskSelectable>[] = [];
        for (const task of state.tasks) {
          if (task.task_id === task_id) {
            updatedTasks.push(taskToUpdate);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },
    updateFeedbackType: (state, action: PayloadAction<FeedbackTypeData>) => {
      const updatedTasks = state.tasks.map(
        (task: Partial<ApiTaskSelectable>) => {
          if (task.task_id === action.payload.task_id) {
            return {
              ...task,
              feedback_type: action.payload.feedback_type,
            };
          }
          return task;
        },
      );

      return { ...state, tasks: updatedTasks };
    },
    addNewTask: (state, action: PayloadAction<ApiTaskSelectable>) => {
      state.tasks.push(action.payload);
    },
    addMessageToTaskByConversationId: (
      state,
      action: PayloadAction<Message>,
    ) => {
      const conversation_id = action.payload.conversation_id;
      const updatedTasks: Partial<ApiTaskSelectable>[] = [];

      for (const taskItem of state.tasks) {
        const last_conversation_id = taskItem.conversation?.conversation_id;
        if (last_conversation_id === conversation_id) {
          const conversation = taskItem.conversation;
          conversation?.messages?.push(action.payload);
          updatedTasks.push({
            ...taskItem,
            conversation,
          });
        } else {
          updatedTasks.push(taskItem);
        }
      }

      state.tasks = updatedTasks;
    },
    replaceMessageInTaskByTaskId: (state, action: PayloadAction<Message>) => {
      const { task_id, message_id } = action.payload;
      const updatedTasks: Partial<ApiTaskSelectable>[] = [];

      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );
      const messageIdExists = focusTask?.conversation?.messages?.some(
        (message: Message) => message.message_id === message_id,
      );

      if (focusTask) {
        const focusTaskMessages: Message[] =
          focusTask?.conversation?.messages || [];

        const updatedFocusTaskMessages = messageIdExists
          ? focusTaskMessages.map((message: Message) => {
              if (message.message_id === message_id) {
                return action.payload;
              }
              return message;
            })
          : [...focusTaskMessages, action.payload];

        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id: action.payload.user_id,
          messages: updatedFocusTaskMessages,
        };

        for (const task of state.tasks) {
          if (task_id === task.task_id) {
            updatedTasks.push(focusTask);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },
    appendMessageInTaskByTaskId: (state, action: PayloadAction<Message>) => {
      const { task_id, user_id, message_id, operation_type } = action.payload;

      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (!focusTask || operation_type !== OperationType.APPEND) {
        return state;
      }

      const updatedTasks: Partial<ApiTaskSelectable>[] = [];
      const focusTaskMessages: Message[] =
        focusTask?.conversation?.messages || [];

      const updatableMessage = focusTaskMessages?.find(
        (message: Message) => message.message_id === message_id,
      );

      const mergedMessagePayload = recursiveDeepMerge(
        updatableMessage?.payload || {},
        action.payload?.payload || {},
      );

      const updatedMessage = {
        ...updatableMessage,
        ...action.payload,
        payload: mergedMessagePayload,
      };

      focusTask.conversation = {
        ...(focusTask?.conversation || {}),
        user_id,
        messages: focusTaskMessages.map((message: Message) => {
          if (message.message_id === action.payload.message_id) {
            return updatedMessage;
          }
          return message;
        }),
      };

      for (const task of state.tasks) {
        if (task_id === task.task_id) {
          updatedTasks.push(focusTask);
        } else {
          updatedTasks.push(task);
        }
      }

      state.tasks = updatedTasks;
    },
    createMessageInTaskByTaskId: (state, action: PayloadAction<Message>) => {
      const { task_id, operation_type, user_id } = action.payload;
      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (!focusTask || operation_type !== OperationType.CREATE) {
        return state;
      }

      const focusTaskMessages: Message[] =
        focusTask?.conversation?.messages || [];
      const updatedFocusTaskMessages = [...focusTaskMessages, action.payload];

      focusTask.conversation = {
        ...(focusTask?.conversation || {}),
        user_id,
        messages: updatedFocusTaskMessages,
      };

      const updatedTasks: Partial<ApiTaskSelectable>[] = [];
      for (const task of state.tasks) {
        if (task_id === task.task_id) {
          updatedTasks.push(focusTask);
        } else {
          updatedTasks.push(task);
        }
      }

      state.tasks = updatedTasks;
    },
    addMessageToTaskByTaskId: (state, action: PayloadAction<Message>) => {
      const { task_id } = action.payload;
      const updatedItems: Partial<ApiTaskSelectable>[] = [];
      if (task_id) {
        for (const taskItem of state.tasks) {
          const {
            conversation: currentTaskConversation,
            task_id: currentTaskId,
          } = taskItem;
          if (currentTaskId === task_id) {
            const newConversation = currentTaskConversation || {
              conversation_id: action.payload.conversation_id,
              user_id: taskItem.user_id || action.payload.from_user_id,
              timestamp: new Date().toISOString(),
              messages: [],
            };

            if (!newConversation.messages) {
              newConversation.messages = [action.payload];
            } else {
              newConversation.messages = [
                ...newConversation.messages,
                action.payload,
              ];
            }

            taskItem.conversation = newConversation;
            updatedItems.push({
              ...taskItem,
              conversation: newConversation,
            });
          } else {
            updatedItems.push(taskItem);
          }
        }

        state.tasks = updatedItems;
      }
    },
    updateTaskMessageFeedbackType: (
      state,
      action: PayloadAction<{
        task_id: string;
        user_id: string;
        message_id: string;
        feedback_type: FeedbackType;
      }>,
    ) => {
      const updatedTasks: Partial<ApiTaskSelectable>[] = [];
      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) =>
          task.task_id === action.payload.task_id,
      );

      if (focusTask) {
        const focusTaskMessages: Message[] =
          focusTask?.conversation?.messages || [];

        const updatedFocusTaskMessages = focusTaskMessages.map(
          (message: Message) => {
            if (message.message_id === action.payload.message_id) {
              return {
                ...message,
                ...action.payload,
              };
            }
            return message;
          },
        );

        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id: action.payload.user_id,
          messages: updatedFocusTaskMessages,
        };

        for (const task of state.tasks) {
          if (action.payload.task_id === task.task_id) {
            updatedTasks.push(focusTask);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },
    updateTaskStateByTaskId: (
      state,
      action: PayloadAction<Partial<ApiTask>>,
    ) => {
      const { task_id, state: taskState } = action.payload;
      if (task_id) {
        let taskExists = false;
        const updatedItems: Partial<ApiTaskSelectable>[] = state.tasks.map(
          (task) => {
            if (task_id === task.task_id) {
              taskExists = true;
              return {
                ...task,
                state: taskState,
              };
            }
            return {
              ...task,
            };
          },
        );

        if (!taskExists && action.payload.task_id) {
          updatedItems.push({
            ...action.payload,
            channel: action.payload.channel || TaskChannel.WEB_APP,
            skill: action.payload.skill || TaskSkill.RESEARCH,
            agent_id: action.payload.agent_id || DEFAULT_AGENT.user_id,
            user_id: action.payload.user_id || '',
          });
        }

        // attn: if we want UI to show updates
        // return statement here must be
        return { ...state, tasks: updatedItems };
      }
    },
    removeAllMessagesFromTaskByTaskId: (
      state,
      action: PayloadAction<{ task_id: string; user_id: string }>,
    ) => {
      const updatedTasks: Partial<ApiTaskSelectable>[] = [];

      const { task_id, user_id } = action.payload;
      const focusTask = state.tasks.find(
        (task: Partial<ApiTaskSelectable>) => task.task_id === task_id,
      );

      if (focusTask) {
        focusTask.conversation = {
          ...(focusTask?.conversation || {}),
          user_id,
          messages: [],
        };

        for (const task of state.tasks) {
          if (task.task_id === task_id) {
            updatedTasks.push(focusTask);
          } else {
            updatedTasks.push(task);
          }
        }

        state.tasks = updatedTasks;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNextPageTasks.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        const { tasks = [], pageToken = '' } =
          action.payload as TasksByPageResponse;
        if (pageToken === state.pageToken) {
          return state;
        }

        return {
          ...state,
          tasks: [...state.tasks, ...tasks],
          pageToken,
        };
      })
      .addCase(fetchTaskById.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        const apiTask = action.payload as ApiTaskSelectable;

        const updatedTasks = state.tasks.map(
          (task: Partial<ApiTaskSelectable>) => {
            if (task.task_id === apiTask.task_id) {
              return {
                ...task,
                ...apiTask,
              };
            }
            return task;
          },
        );

        return {
          ...state,
          tasks: updatedTasks,
        };
      })
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(
        tasksApi.endpoints.getTaskById.matchFulfilled,
        (state, action) => {
          const updatedTasks = state.tasks.map(
            (task: Partial<ApiTaskSelectable>) => {
              if (task.task_id === action.payload.task_id) {
                return { ...task, ...action.payload };
              }
              return task;
            },
          );
          return { ...state, tasks: updatedTasks };
        },
      )
      // todo: test that all messages become unread in a task
      // attn: this cannot be done well if we don't have partial tasks
      .addMatcher(
        tasksApi.endpoints.markAllReadTaskMessages.matchFulfilled,
        (state, action) => {
          const foundTask = state.tasks.find(
            (task: Partial<ApiTaskSelectable>) =>
              task.task_id === action.payload,
          );
          if (!foundTask) return state;

          const foundTaskMessages = foundTask?.conversation?.messages?.map(
            (message: Message) => {
              return { ...message, is_read: true };
            },
          );

          if (
            !foundTaskMessages ||
            foundTaskMessages.length === 0 ||
            !foundTask.user_id
          ) {
            return state;
          }

          foundTask.conversation = {
            ...(foundTask.conversation || {}),
            user_id: foundTask.user_id,
            messages: foundTaskMessages,
          };

          const updatedTasks = state.tasks.map(
            (task: Partial<ApiTaskSelectable>) => {
              if (task.task_id === foundTask?.task_id) {
                return foundTask;
              }
              return task;
            },
          );

          return { ...state, tasks: updatedTasks };
        },
      )
      // todo: test that all messages become unread in a task
      // attn: this cannot be done well if we don't have partial tasks
      .addMatcher(
        tasksApi.endpoints.markAllUnreadTaskMessages.matchFulfilled,
        (state, action) => {
          const foundTask = state.tasks.find(
            (task: Partial<ApiTaskSelectable>) =>
              task.task_id === action.payload,
          );
          if (!foundTask) return state;

          const foundTaskMessages = foundTask?.conversation?.messages?.map(
            (message: Message) => {
              return { ...message, is_read: false };
            },
          );

          if (
            !foundTaskMessages ||
            foundTaskMessages.length === 0 ||
            !foundTask.user_id
          ) {
            return state;
          }

          foundTask.conversation = {
            ...(foundTask.conversation || {}),
            user_id: foundTask.user_id,
            messages: foundTaskMessages,
          };

          const updatedTasks = state.tasks.map(
            (task: Partial<ApiTaskSelectable>) => {
              if (task.task_id === foundTask?.task_id) {
                return foundTask;
              }
              return task;
            },
          );

          return { ...state, tasks: updatedTasks };
        },
      )
      .addMatcher(
        tasksApi.endpoints.getTasksByUserId.matchFulfilled,
        (state, action) => {
          return { ...state, tasks: action.payload };
        },
      )
      // att: just in case query while we are migrating to tasks thunks
      .addMatcher(
        tasksApi.endpoints.getNextPageTasksByUserId.matchFulfilled,
        (state, action) => {
          const { tasks: newPageTasks, pageToken } = action.payload;
          return {
            ...state,
            tasks: [...state.tasks, ...newPageTasks],
            pageToken,
          };
        },
      )
      .addMatcher(
        tasksApi.endpoints.updateTask.matchFulfilled,
        (state, action) => {
          const updatedTasks: Partial<ApiTaskSelectable>[] = [];
          const { task_id } = action.payload;

          for (const taskItem of state.tasks) {
            if (task_id === taskItem.task_id) {
              updatedTasks.push({
                ...taskItem,
                ...action.payload,
              });
            } else {
              updatedTasks.push(taskItem);
            }
          }

          return { ...state, tasks: updatedTasks };
        },
      )
      .addMatcher(
        tasksApi.endpoints.updateTaskField.matchFulfilled,
        (state, action) => {
          const updatedTasks: Partial<ApiTaskSelectable>[] = [];
          const { task_id } = action.payload;

          for (const taskItem of state.tasks) {
            if (task_id === taskItem.task_id) {
              updatedTasks.push({
                ...taskItem,
                ...action.payload,
              });
            } else {
              updatedTasks.push(taskItem);
            }
          }

          return { ...state, tasks: updatedTasks };
        },
      );
  },
});

export const userTasksState = (state: RootState) =>
  state.userTasks as UserTasksState;

export const {
  addNewTask,
  updateTaskMessage,
  updateTaskStateByTaskId,
  removeAllMessagesFromTaskByTaskId,
  updateFeedbackType,
  addMessageToTaskByConversationId,
  addMessageToTaskByTaskId,
  createMessageInTaskByTaskId,
  replaceMessageInTaskByTaskId,
  appendMessageInTaskByTaskId,
  markMessagesIsRead,
  updateTaskMessageFeedbackType,
} = userTasksSlice.actions;

export default userTasksSlice.reducer;
