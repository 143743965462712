import { useEffect, useContext } from 'react';
import AudioContext from 'src/contexts/AudioContext';
import ThreadInputBoxContext from 'src/contexts/ThreadInputBoxContext';
import { useThreads } from 'src/hooks';
import { SendMessageButton } from '../SendMessageButton';
import { StopResponseButton } from '../StopResponseButton';

interface SubmitTriggerProps {
  isSubmitDisabled: boolean;
}

export const SubmitTrigger = ({ isSubmitDisabled }: SubmitTriggerProps) => {
  const { stopResponseTriggerRef } = useContext(AudioContext);
  const { showReplaceTooltip } = useContext(ThreadInputBoxContext);
  const { isSubmitHappened } = useThreads();

  useEffect(() => {
    stopResponseTriggerRef?.current?.stopResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isSubmitHappened ? (
        <StopResponseButton />
      ) : (
        <SendMessageButton
          isDisabled={isSubmitDisabled || showReplaceTooltip}
        />
      )}
    </>
  );
};
