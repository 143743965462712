import classNames from 'classnames';
import { CommunicationToneID, CommunicationToneOption } from 'src/types';
import { Persona } from 'src/types/models/Persona';
import { useAvatarPreferences, useSession, useUserData } from 'src/hooks';
import { UpsellMessage } from 'src/components/UpsellMessage';

type ConversationalToneSectionProps = {
  value?: CommunicationToneOption;
  onChange?: (value: string) => void;
  isShowUpsellMessage?: boolean;
};

export const ConversationalToneSection = ({
  value,
  onChange,
  isShowUpsellMessage,
}: ConversationalToneSectionProps) => {
  const { updateUserData } = useUserData();
  const { appUser, isOpenTier } = useSession();
  const { communicationTonesOptions, selectedCommunicationTone } =
    useAvatarPreferences();

  const selectedToneTitle = value
    ? value.title
    : selectedCommunicationTone?.title || '';
  const selectedToneDescription = value
    ? value.description
    : selectedCommunicationTone?.description || '';

  const handleChangeCommunicationTone = (communicationToneId: string) => {
    updateUserData(
      {
        persona: {
          ...appUser.persona,
          communication_tone_id: communicationToneId as CommunicationToneID,
        } as Persona,
      },
      true,
    );
  };

  return (
    <div className="nj-avatar-select-tooltip-communication-tones-wrapper">
      <div className="nj-avatar-select-tooltip-communication-tones-selected-value">
        <h5 className="nj-avatar-select-tooltip-communication-tones-selected-value-title">
          {selectedToneTitle}
        </h5>
        <p className="nj-avatar-select-tooltip-communication-tones-selected-value-description">
          {selectedToneDescription}
        </p>
      </div>
      <div className="nj-avatar-select-tooltip-communication-tones-list-wrapper">
        {communicationTonesOptions.map((item) => (
          <button
            key={`communication_tone_${item.id}`}
            className={classNames(
              'nj-avatar-select-tooltip-communication-tones-item',
              {
                selected: value
                  ? value.id === item.id
                  : selectedCommunicationTone?.id === item.id,
              },
            )}
            onClick={() =>
              onChange
                ? onChange(item.id)
                : handleChangeCommunicationTone(item.id)
            }
            disabled={
              !(
                appUser?.settings?.features?.persona_tone
                  ?.available_persona_tones || []
              ).includes(item.id)
            }
          >
            {item.title}
          </button>
        ))}
      </div>
      {isOpenTier && isShowUpsellMessage && (
        <UpsellMessage description="Get access to additional tone profiles" />
      )}
    </div>
  );
};
