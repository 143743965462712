import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AppUser,
  GetUserExternalModelsSimplifiedResponse,
  LocationRequest,
  UserSettingsExternalModel,
} from 'src/types';
import { baseTasksQuery } from './config';

export interface UpdateUserRequest {
  id: string;
  body: Partial<AppUser>;
}

export interface UpdateUserLocationRequest {
  user_id: string;
  body: LocationRequest;
}

export enum UsersTags {
  Users = 'Users',
  ExternalModels = 'ExternalModels',
}

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: [UsersTags.Users, UsersTags.ExternalModels],
  baseQuery: baseTasksQuery,
  endpoints: (builder) => ({
    getUserById: builder.query<AppUser, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
      providesTags: (result, error, id) => {
        return [{ type: UsersTags.Users, id }];
      },
    }),
    getUserExternalModels: builder.query<
      Record<string, UserSettingsExternalModel>,
      string
    >({
      query: (id) => ({
        url: `/users/${id}/settings/external_models/simplified`,
      }),
      providesTags: () => {
        return [UsersTags.ExternalModels];
      },
      transformResponse: (
        response: GetUserExternalModelsSimplifiedResponse,
      ): Record<string, UserSettingsExternalModel> => {
        return response.data.reduce(
          (acc, item) => {
            acc[item.model] = item;
            return acc;
          },
          {} as Record<string, UserSettingsExternalModel>,
        );
      },
    }),
    updateUser: builder.mutation<void, UpdateUserRequest>({
      query: ({ id, body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          usersApi.util.updateQueryData('getUserById', id, (draft) => ({
            ...draft,
            ...patch.body,
          })),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    updateUserLocation: builder.mutation<void, UpdateUserLocationRequest>({
      query: ({ user_id, body }) => ({
        url: `/users/${user_id}/location`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useUpdateUserLocationMutation,
  useGetUserExternalModelsQuery,
} = usersApi;
