import { CodeSourceTypes, CodeSourceTypesLabels } from 'src/types';
import { Icon } from 'src/components/Icon';

const SVG_SIZE = 24;

// TODO(olha): should be deprecated
export const sourcesSet: Record<
  CodeSourceTypes,
  { title: string; icon: JSX.Element; order: number; ninjaModel: boolean }
> = {
  [CodeSourceTypes.NINJA_CODER_1]: {
    title: CodeSourceTypesLabels.NINJA_CODER_1,
    icon: <Icon type="ninjaIcon" size={SVG_SIZE} />,
    order: 1,
    ninjaModel: true,
  },

  [CodeSourceTypes.OPEN_AI]: {
    title: CodeSourceTypesLabels.OPEN_AI,
    icon: <Icon type="chatGPTLogo" size={SVG_SIZE} />,
    order: 2,
    ninjaModel: false,
  },

  [CodeSourceTypes.GOOGLE]: {
    title: CodeSourceTypesLabels.GOOGLE,
    icon: <Icon type="googleGeminiProLogo" size={SVG_SIZE} />,
    order: 3,
    ninjaModel: false,
  },

  [CodeSourceTypes.ANTHROPIC]: {
    title: CodeSourceTypesLabels.ANTHROPIC,
    icon: <Icon type="claudeLogo" size={SVG_SIZE} />,
    order: 4,
    ninjaModel: false,
  },
};
