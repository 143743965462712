import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTasksQuery } from './config';
import { SavingsInsightsResponse, TaskSkill } from 'src/types';

enum SavingsTags {
  Savings = 'Savings',
}

interface SavingsResponse {
  saved_seconds: number;
  completed_tasks_count: number;
}

interface SavingsRequest {
  userId: string;
  since?: string;
}

interface SavingsInsightsRequest {
  userId: string;
  interval: string;
  since?: string;
  to?: string;
}

interface SavedTimePerTaskRequest {
  userId: string;
  since?: string;
  to?: string;
}

interface TaskSavedTime {
  task_id: string;
  task_hash?: string;
  skill: TaskSkill;
  time_saved: number;
}

interface SavedTimePerTaskResponse {
  saved_seconds: number;
  completed_tasks_count: number;
  tasks: TaskSavedTime[];
}

export const savingsApi = createApi({
  reducerPath: 'savingsApi',
  tagTypes: [SavingsTags.Savings],
  baseQuery: baseTasksQuery,
  endpoints: (builder) => ({
    // GET /v2/users/{user_id}/saved_time?since=
    getSavings: builder.query<SavingsResponse, SavingsRequest>({
      query: ({ userId, since }: SavingsRequest) => ({
        url: `/users/${userId}/saved_time`,
        method: 'GET',
        params: { since },
      }),
      providesTags: [SavingsTags.Savings],
    }),
    // GET /v2/users/{user_id}/time_saving_insights?interval=yearly&since=&to=
    getSavingsInsights: builder.query<
      SavingsInsightsResponse,
      SavingsInsightsRequest
    >({
      query: ({ userId, interval, since, to }: SavingsInsightsRequest) => ({
        url: `/users/${userId}/time_saving_insights`,
        method: 'GET',
        params: { interval, since, to },
      }),
    }),
    // GET /v2/users/{user_id}/time_saved?debug_mode=true
    getSavedTimePerTask: builder.query<
      SavedTimePerTaskResponse,
      SavedTimePerTaskRequest
    >({
      query: ({ userId, since, to }) => ({
        url: `/users/${userId}/saved_time`,
        method: 'GET',
        params: { debug_mode: true, since, to },
      }),
    }),
  }),
});

export const {
  useGetSavingsQuery,
  useGetSavingsInsightsQuery,
  useGetSavedTimePerTaskQuery,
} = savingsApi;
