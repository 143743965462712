import { lightMode, darkMode } from 'src/constants';
import ReactSelect from 'react-select';
import { FieldValues, Controller, useFormContext } from 'react-hook-form';
import { getCustomStyles } from './selectHelper';
import { useTheme } from 'src/hooks';

export interface SelectOption<T = unknown> {
  value: string | number | T;
  label: string | JSX.Element;
  customConfig?: {
    hasBorderBottom?: boolean;
  };
  isDisabled?: boolean;
}

interface SelectProps<T> extends FieldValues {
  name: string;
  options: SelectOption<T>[];
  isSearchable?: boolean;
  isDisabled?: boolean;
  onChangeHandler?: (data: T) => void;
  isShort?: boolean;
}

export const Select = <T,>({
  name,
  options,
  isSearchable = false,
  isDisabled = false,
  onChangeHandler,
  isShort = false,
  ...rest
}: SelectProps<T>) => {
  const { control } = useFormContext();
  const { isDarkTheme } = useTheme();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <ReactSelect
          defaultOptions
          options={options}
          theme={isDarkTheme ? darkMode : lightMode}
          isSearchable={isSearchable}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={(data: SelectOption) => {
            onChange(data.value);
            if (onChangeHandler) {
              onChangeHandler(data.value as T);
            }
          }}
          value={options.find((item) => item.value === value) || null}
          styles={getCustomStyles<SelectOption>({ isShort, isDarkTheme })}
          isDisabled={isDisabled}
          {...rest}
        />
      )}
    />
  );
};
