import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  Conversation,
  Message,
  MessageChunk,
  MessageFooter,
  isResearchData,
  FeedbackType,
  OperationType,
} from 'src/types';
import { conversationApi } from '../services';
import { RootState } from '../index';
import {
  removeReferencesInBrackets,
  recursiveDeepMerge,
  getStreamableAvatarChunks,
} from 'src/utils';
import { Feedback } from 'src/types/models/Feedback';
import { PURGE } from 'reduxjs-toolkit-persist';

dayjs.extend(utc);

interface MessageFeedbackUpdate {
  message_id: string;
  feedback_type: FeedbackType;
}

interface MessageFeedback {
  taskId?: string;
  message_id: string;
  feedback?: Feedback;
}

interface ConversationState extends Conversation {
  messageToStream?: string;
  messageToStreamAfter?: string;
}

const initialState = {
  messageToStream: '',
  messageToStreamAfter: '',
} as ConversationState;

/**
 * Creates conversation slice to keep track of all messages in the main thread.
 */
// TODO(olha): deprecated
export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setMessageToStream: (state, action: PayloadAction<string>) => {
      state.messageToStream = action.payload;
    },
    // TODO(ella): Refactor to macro queue for Avatar next time
    setMessageToStreamAfter: (state, action: PayloadAction<string>) => {
      state.messageToStreamAfter = action.payload;
    },
    addConversation: (state, action: PayloadAction<Conversation>) => {
      return action.payload;
    },
    updateConversationId: (state, action: PayloadAction<string>) => {
      state.conversation_id = action.payload;
    },
    setDisableConversationSubmit: (state, action: PayloadAction<boolean>) => {
      return { ...state, isDisableConversationSubmit: action.payload };
    },
    // operation_type = CREATE creates a new message
    createConversationMessage: (state, action: PayloadAction<Message>) => {
      const { operation_type } = action.payload;

      if (operation_type !== OperationType.CREATE) {
        return state;
      }

      const { messageToStream = '' } = getStreamableAvatarChunks(
        action.payload,
      );

      return {
        ...state,
        messageToStream,
        messages: [...(state?.messages || []), action.payload],
      };
    },
    // operation_type = REPLACE replaces message payload with the new payload
    replaceConversationMessage: (state, action: PayloadAction<Message>) => {
      const { message_id } = action.payload;
      const idExists = state.messages?.some(
        (msg: Message) => msg.message_id === message_id,
      );

      if (idExists) {
        const updatedMessages = state.messages ? state.messages : [];
        return {
          ...state,
          messages: updatedMessages.map((msg) => {
            if (msg.message_id === message_id) {
              return action.payload;
            }
            return msg;
          }),
        };
      }

      return {
        ...state,
        // attn: no blocking required
        isDisableConversationSubmit: false,
        messages: [...(state?.messages || []), action.payload],
      };
    },
    // operation_type = APPEND deeply merges arriving payload objects
    appendConversationMessage: (state, action: PayloadAction<Message>) => {
      const { message_id, operation_type, is_final_answer } = action.payload;
      const updatableMessage = state.messages?.find(
        (msg: Message) => msg.message_id === message_id,
      );

      if (!updatableMessage || operation_type !== OperationType.APPEND) {
        return state;
      }

      // obtain streamable message summary / content
      const { messageToStream = '' } = getStreamableAvatarChunks(
        action.payload,
      );

      const updatedMessages = state.messages ? state.messages : [];
      const updatedPayload = recursiveDeepMerge(
        updatableMessage?.payload || {},
        action.payload?.payload || {},
      );

      // prevent overriding of the final answer being true
      const isFinalAnswer = is_final_answer
        ? is_final_answer
        : updatableMessage.is_final_answer;

      return {
        ...state,
        messageToStream,
        isDisableConversationSubmit: !isFinalAnswer,
        messages: updatedMessages.map((msg) => {
          if (msg.message_id === message_id) {
            return {
              ...updatableMessage,
              ...action.payload,
              is_final_answer: isFinalAnswer,
              payload: updatedPayload,
            };
          }
          return msg;
        }),
      };
    },
    // non-operation type addition of the message by conv id
    addConversationMessageByConversationId: (
      state,
      action: PayloadAction<Message>,
    ) => {
      const { conversation_id } = action.payload;
      const idExists = state.messages?.some(
        (msg: Message) => msg.conversation_id === conversation_id,
      );

      if (idExists) {
        const updatedMessages = state.messages ? state.messages : [];
        return {
          ...state,
          isDisableConversationSubmit: false,
          messages: [...updatedMessages, action.payload],
        };
      }

      return state;
    },
    // non-streaming, non operation-type addition routine by id
    addConversationMessage: (state, action: PayloadAction<Message>) => {
      const updatedMessages = state.messages ? state.messages : [];

      const { response_metadata } = action.payload;

      let messageToStream = '';
      if (isResearchData(response_metadata)) {
        messageToStream = removeReferencesInBrackets(response_metadata.summary);
      }

      return {
        ...state,
        messageToStream,
        isDisableConversationSubmit: false,
        messages: [...updatedMessages, action.payload],
      };
    },
    // chunk-by-chunk streaming / add message chunk
    addConversationMessageChunk: (
      state,
      action: PayloadAction<MessageChunk>,
    ) => {
      const stateMessages = state.messages ? state.messages : [];
      const updatedMessages = stateMessages.map((message) => {
        if (message.message_id !== action.payload.message_id) {
          return message;
        } else {
          return {
            ...message,
            content: message.content + action.payload.content,
          };
        }
      });

      const { content } = action.payload;
      const messageToStream = removeReferencesInBrackets(content);

      return {
        ...state,
        messageToStream,
        messages: updatedMessages,
      };
    },
    // chunk-by-chunk streaming / finalize message chunk
    addConversationMessageFooter: (
      state,
      action: PayloadAction<MessageFooter>,
    ) => {
      const stateMessages = state.messages ? state.messages : [];
      let avatarContentProp = {};

      const updatedStateMessages = stateMessages.map((message) => {
        if (action.payload.message_id === message.message_id) {
          const avatarContent = message.content.trim();
          if (
            !(
              avatarContent?.endsWith('.') ||
              avatarContent?.endsWith('?') ||
              avatarContent?.endsWith('!')
            )
          ) {
            // Avatar will start speaking the last sentence if it didn't
            // once we have the content ready & finalized by the message footer.
            // Adding the period to add sentence boundary to the awaiting
            // unfinished sentence in useAvatarSpeech hook.
            avatarContentProp = { messageToStream: '.' };
          }
          // We probably don't want here the entire action.payload,
          // since it is a partial. Adding only the final flag.
          return {
            ...message,
            is_final_answer: action.payload?.is_final_answer || true,
          };
        }
        return message;
      });

      return {
        ...state,
        isDisableConversationSubmit: false,
        messages: updatedStateMessages,
        ...avatarContentProp,
      };
    },
    updateMessageFeedbackType: (
      state,
      action: PayloadAction<MessageFeedbackUpdate>,
    ) => {
      const stateMessages = state.messages ? state.messages : [];
      const updatedMessages = stateMessages.map((message: Message) => {
        if (message.message_id === action.payload.message_id) {
          return {
            ...message,
            feedback_type: action.payload.feedback_type,
          };
        }
        return message;
      });

      state.messages = updatedMessages;
    },
    updateMessageFeedback: (state, action: PayloadAction<MessageFeedback>) => {
      const stateMessages = state.messages ? state.messages : [];
      const stateTasks = state.tasks ? state.tasks : [];
      if (action.payload.taskId) {
        const updatedTasks = stateTasks.map((task) => {
          if (task.task_id === action.payload.taskId) {
            return {
              ...task,
              feedback: action.payload.feedback,
            };
          }
          return task;
        });

        state.tasks = updatedTasks;
      }
      const updatedMessages = stateMessages.map((message: Message) => {
        if (message.message_id === action.payload.message_id) {
          return {
            ...message,
            feedback: action.payload.feedback,
          };
        }
        return message;
      });

      state.messages = updatedMessages;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, () => {
        return initialState;
      })
      .addMatcher(
        conversationApi.endpoints.getUserConversation.matchFulfilled,
        (state, action) => {
          return action.payload;
        },
      )
      .addMatcher(
        conversationApi.endpoints.clearUserConversation.matchFulfilled,
        () => {
          return initialState;
        },
      );
  },
});

export const conversationState = (state: RootState) =>
  state.conversation as ConversationState;

export const {
  addConversation,
  updateConversationId,
  addConversationMessage,
  createConversationMessage,
  replaceConversationMessage,
  appendConversationMessage,
  setDisableConversationSubmit,
  addConversationMessageChunk,
  addConversationMessageFooter,
  addConversationMessageByConversationId,
  updateMessageFeedbackType,
  setMessageToStream,
  updateMessageFeedback,
} = conversationSlice.actions;

export default conversationSlice.reducer;
