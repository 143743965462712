import { useMemo } from 'react';
import classNames from 'classnames';
import { Action, TaskState, ActionStatue } from 'src/types';
import { ProgressCircle } from 'src/components/ProgressCircle';
import { TaskStatusIcon } from 'src/components/TaskStatusIcon';
import { LatestProgressAction } from 'src/pages/ManageTasksChatPage/components/TaskScratchpad/components/LatestProgressAction';
import { TaskProgressStatusTitle } from '../TaskProgressStatusTitle';

type TaskProgressIndicatorProps = {
  messageActions?: Action[];
  taskState: TaskState;
  taskId?: string;
};

export const TaskProgressIndicator = ({
  messageActions = [],
  taskState,
  taskId,
}: TaskProgressIndicatorProps) => {
  const completedActionsLength = useMemo(
    () =>
      messageActions.filter((item) => item.status === ActionStatue.COMPLETED)
        .length,
    [messageActions],
  );

  const progressPercent = useMemo(
    () =>
      completedActionsLength > 0 && messageActions.length > 0
        ? Math.ceil((completedActionsLength / messageActions.length) * 100)
        : 0,
    [completedActionsLength, messageActions.length],
  );

  const statusClasses = classNames('nj-task-progress-indicator--status-icon', {
    warning: taskState === TaskState.BLOCKED || taskState === TaskState.FAILED,
    active: taskState === TaskState.DONE,
  });

  return (
    <>
      {taskState === TaskState.IN_PROGRESS ? (
        <div className="nj-task-progress-indicator">
          <div className="nj-task-progress-indicator--bar">
            <ProgressCircle percent={progressPercent} />
          </div>

          <LatestProgressAction actions={messageActions} />
        </div>
      ) : (
        <div className="nj-task-progress-indicator">
          <div className={statusClasses}>
            <TaskStatusIcon taskState={taskState} />
          </div>

          <TaskProgressStatusTitle taskState={taskState} taskId={taskId} />
        </div>
      )}
    </>
  );
};
