import { useMemo } from 'react';
import classNames from 'classnames';
import { useSession, useTheme } from 'src/hooks';
import { ChatModeSidePanel, type DeepResearchResult } from 'src/types';
import type { ReferencedModelSummary } from 'src/types/models/ReferencedModelSummary';

interface SettingsMessageResearchCodeCardProps {
  cardType: 'researcher' | 'chit-chat' | 'chit-chat-card';
  deepResearchData?: DeepResearchResult;
  referencedModelSummary?: ReferencedModelSummary;
  isStreaming?: boolean;
}

// TODO(olha): this is a temporary component until we implement a new approach with expanding user search/coder result
export const SettingsMessageResearchCodeCard = ({
  cardType,
  deepResearchData,
  referencedModelSummary,
  isStreaming,
}: SettingsMessageResearchCodeCardProps) => {
  const {
    updateChatModeSidePanel,
    appUser: { settings },
  } = useSession();
  const { toggleSettingsPanelExpanded } = useTheme();

  const joinStringWithOr = (arr: string[]): string => {
    switch (arr.length) {
      case 0:
        return '';

      case 1:
        return arr[0];

      default:
        return `${arr.slice(0, -1).join(', ')} or ${arr[arr.length - 1]}`;
    }
  };

  const shouldSuggestDeepResearch = useMemo(
    () => !deepResearchData && !!settings?.research?.deep_research?.is_capable,
    [deepResearchData, settings],
  );

  const externalModelsOptions = useMemo(() => {
    const options: string[] = [];

    if (
      !referencedModelSummary?.references &&
      !!settings?.external_models?.is_capable
    ) {
      return options.concat(['OpenAI', 'Amazon Bedrock models', 'Gemini']);
    }

    if (
      !!referencedModelSummary?.references &&
      !!settings?.external_models?.is_capable
    ) {
      const { references } = referencedModelSummary || {};

      const models: string[] = Object.values(references || {})
        .map((item) => item.model || '')
        .filter((item) => !!item);

      if (models.every((item) => !item.includes('bedrock'))) {
        options.push('Amazon Bedrock models');
      }

      if (models.every((item) => !item.includes('openai'))) {
        options.push('OpenAI');
      }

      if (models.every((item) => !item.includes('gemini'))) {
        options.push('Gemini');
      }

      return options;
    }

    return options;
  }, [referencedModelSummary, settings]);

  const settingsOptions: string[] = useMemo(() => {
    const options: string[] = [];

    if (shouldSuggestDeepResearch && cardType === 'researcher') {
      options.push('Deep Research');
    }

    if (externalModelsOptions.length > 0) {
      return options.concat(externalModelsOptions);
    }

    return options;
  }, [externalModelsOptions, shouldSuggestDeepResearch, cardType]);

  const shouldSuggestExternalModels = useMemo(
    () => externalModelsOptions.length > 0,
    [externalModelsOptions],
  );

  const title = useMemo(
    () => `Enable ${joinStringWithOr(settingsOptions)} in the `,
    [settingsOptions],
  );

  const handleNinjaAgentsClick = () => {
    updateChatModeSidePanel(ChatModeSidePanel.NINJA_AGENTS);
    toggleSettingsPanelExpanded(true);
  };

  const handleExternalModelsClick = () => {
    updateChatModeSidePanel(ChatModeSidePanel.EXTERNAL_MODELS);
    toggleSettingsPanelExpanded(true);
  };

  if (settingsOptions.length === 0 || isStreaming || cardType === 'chit-chat') {
    return null;
  }

  return (
    <>
      <hr className="divider" />

      <div
        className={classNames('nj-upsell-message-research-code-card', {
          'with-padding-top': cardType === 'researcher', // todo check this
        })}
      >
        <span>
          {title}
          {shouldSuggestDeepResearch && cardType === 'researcher' && (
            <button
              type="button"
              onClick={handleNinjaAgentsClick}
              className="nj-upsell-message-research-code-card--settings-button"
            >
              Ninja agents
            </button>
          )}
          {shouldSuggestDeepResearch &&
            cardType === 'researcher' &&
            shouldSuggestExternalModels && <span>, </span>}
          {shouldSuggestExternalModels && (
            <button
              type="button"
              onClick={handleExternalModelsClick}
              className="nj-upsell-message-research-code-card--settings-button"
            >
              External models settings
            </button>
          )}
          .
        </span>
      </div>

      <hr className="divider" />
    </>
  );
};
