import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { GTMEvent } from 'src/types';
import { sendGTMEvent } from 'src/utils';

type UpgradeButtonProps = {
  onClick?: () => void;
  title?: string;
};

export const UpgradeButton = ({ onClick, title = 'Upgrade' }: UpgradeButtonProps) => {
  const { onToggleVisibleUpgradeModal } = useContext(SessionContext);

  const handleUpgradeClick = () => {
    onToggleVisibleUpgradeModal(true);
    if (onClick) {
      onClick();
    }
    sendGTMEvent(GTMEvent.UPGRADE_BUTTON_CLICKS);
  };

  return (
    <button className="nj-upgrade-button" onClick={handleUpgradeClick}>
      {title}
    </button>
  );
};
