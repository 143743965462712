import { useContext } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import { useThreads } from 'src/hooks';
import { Message } from 'src/types';
import {
  performanceMarkerEnd,
  performanceMarkerStart,
} from 'src/utils/performanceMarkers';

export const useFetchUserInput = () => {
  const { debugMode } = useContext(DebugContext);

  const { addMessageToThread } = useThreads();

  // TODO(olha): Check audioLocale after refactoring ChatForm
  const fetchAiData = async (newMessage: Message, audioLocale?: string) => {
    performanceMarkerStart('ce-response-time');
    await addMessageToThread(newMessage);
    performanceMarkerEnd('ce-response-time', undefined, debugMode);
  };

  return { fetchAiData };
};
