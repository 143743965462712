import classNames from 'classnames';

type ButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  isSubmit?: boolean;
  className?: string;
  selected?: boolean;
  children?: React.ReactNode;
  rest?: { [key: string]: React.ReactNode };
  onClick?: () => void;
};

/**
 * Button component creates a button with styles,
 * has selected state, can have children components.
 */
export const Button = ({
  isSubmit,
  className,
  selected,
  children,
  onClick,
  ...rest
}: ButtonProps) => {
  const buttonClasses = classNames(className, {
    selected: selected,
  });
  return isSubmit ? (
    <button type="submit" className={buttonClasses} {...rest}>
      <div className="container">{children}</div>
    </button>
  ) : (
    <button className={buttonClasses} {...rest} onClick={onClick}>
      <div className="container">{children}</div>
    </button>
  );
};
