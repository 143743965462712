import { CurrentPlanLabel } from 'src/components/CurrentPlanLabel';
import { UserImage } from 'src/components/UserImage';
import { SVG_SIZE_XXL } from 'src/constants';
import { useSession } from 'src/hooks';
import { getFullName } from 'src/utils';
import styles from './UserMenuMainInfo.module.scss';

export const UserMenuMainInfo = () => {
  const { appUser, isOpenTier } = useSession();
  const userName = getFullName(appUser);

  return (
    <div className={styles.root}>
      <UserImage size={SVG_SIZE_XXL} />

      <div className={styles.content}>
        <p className={styles.name}>{isOpenTier ? 'Guest' : userName}</p>
        {!isOpenTier && appUser.email && (
          <p className={styles.email}>{appUser.email}</p>
        )}
      </div>

      <CurrentPlanLabel />
    </div>
  );
};
