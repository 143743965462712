import { Modal } from 'src/components/Modal';
import { RenewEarlyConfirmationContent } from 'src/components/RenewEarlyButton/components/RenewEarlyConfirmationContent';
import { useGetUserSubscriptionInfoQuery } from 'src/store/services';
import { useSession } from 'src/hooks';
import { useContext } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { UserTier } from 'src/types/models/UserTier';

export const UpdatePlanModal = () => {
  const { isVisibleUpdatePlanModal, onChangeIsVisibleUpdatePlanModal } =
    useContext(SessionContext);
  const { appUser } = useSession();

  const { data } = useGetUserSubscriptionInfoQuery(
    {
      user_id: appUser.user_id,
    },
    {
      skip: !appUser || !appUser.user_id || appUser.tier_id !== UserTier.PAID,
    },
  );

  const handleCloseConfirmationModal = () => {
    onChangeIsVisibleUpdatePlanModal(false);
  };

  return (
    <Modal
      open={isVisibleUpdatePlanModal}
      onClose={handleCloseConfirmationModal}
      className="nj-renew-early-confirmation-modal"
    >
      <RenewEarlyConfirmationContent
        onCloseModal={handleCloseConfirmationModal}
        subscriptionInfo={data}
      />
    </Modal>
  );
};
