import { Link } from 'react-router-dom';
import { useBreakpoint } from 'src/hooks';

export const SignupHeader = () => {
  const { isMobile } = useBreakpoint();

  return (
    <div className="nj-auth-header-right-side">
      {!isMobile && <span>Already have a Beta account?</span>}
      <Link
        to="/login"
        className="nj-button nj-auth-sign-in-up-button nj-auth-login-button"
      >
        Log in
      </Link>
    </div>
  );
};
