import { ManageAccountContentPage } from '../ManageAccountContentPage/ManageAccountContentPage';
import { ActivityTable } from '../ActivityTable';
import './SubscriptionActivity.scss';

export const SubscriptionActivity = () => {
  return (
    <ManageAccountContentPage title="Activity">
      <div className="nj-subscriptions-activity">
        <p className="nj-subscriptions-activity--header">
          <span className="nj-subscriptions-activity--title">
            Account activity
          </span>

          <span className="nj-subscriptions-activity--subtitle">
            Review your activity and tasks that resulted in credit usage.
          </span>
        </p>

        <div className="nj-subscriptions-activity--table-wrapper">
          <ActivityTable />

          <hr className="divider" />
        </div>
      </div>
    </ManageAccountContentPage>
  );
};
