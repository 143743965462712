import { useRef } from 'react';
import { useReadMessageByScrolling } from 'src/hooks';

interface NewMessagesLineProps {
  // anchor for auto-scrolling to specific task
  anchorId?: string;
}

// Anchor component for scrolling to specific point by clicking
export const ThreadDividerLine = ({ anchorId = '' }: NewMessagesLineProps) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const { isTaskRequiresAttention } = useReadMessageByScrolling(
    anchorRef,
    anchorId || '',
  );

  if (!anchorId) {
    return null;
  }

  return (
    // anchorId for auto-scroll to specific task by clicking on the task from task list
    <div id={anchorId} className="nj-thread-divider--new-message">
      {isTaskRequiresAttention && (
        <div
          className="nj-thread-divider--new-message-anchor"
          // anchor for "reading" message while scrolling
          ref={anchorRef}
        />
      )}
    </div>
  );
};
