import { useMemo } from 'react';
import { useSession } from 'src/hooks/useSession';
import { ChatMode } from 'src/types';
import { AvatarQueueBanner } from './components/AvatarQueueBanner';
import { AvatarIdleBanner } from './components/AvatarIdleBanner';

/**
 * AvatarBanner is a singleton component offering only
 * one of the two(+) possible banners: AvatarQueueBanner or AvatarIdleBanner.
 * Avatar banner stays hidden, yet reacts to the state of the avatar queue/or session.
 */
export const AvatarBanner = () => {
  const { chatMode, avatarQueue } = useSession();

  // avatar mode is defined by chat mode and readiness of the avatar
  const isAvatarMode = useMemo(
    () => chatMode === ChatMode.AVATAR && avatarQueue?.ready,
    [chatMode, avatarQueue?.ready],
  );

  return !isAvatarMode ? <AvatarQueueBanner /> : <AvatarIdleBanner />;
};
