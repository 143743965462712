import { get_access_token } from 'src/utils';
import fetchRetry from 'fetch-retry';
import log from 'src/utils/logger';

interface CustomFetchOptions extends RequestInit {
  retries?: number;
  retryDelay?: (attempt: number, error?: Error, response?: Response) => number;
  retryOn?: Array<number>;
}

const fetchWithRetry = fetchRetry(fetch);

export async function customFetchWithRetry<T>(
  url: string,
  options: CustomFetchOptions = {},
): Promise<T> {
  const token = await get_access_token();

  const defaultOptions: CustomFetchOptions = {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    referrerPolicy: 'no-referrer',
    cache: 'no-cache',
    retries: 3,
    retryDelay: (attempt) => Math.pow(2, attempt) * 1000,
    retryOn: [504],
  };

  const finalOptions = { ...defaultOptions, ...options } as RequestInit & {
    retries: number;
    retryDelay: (attempt: number) => number;
    retryOn: Array<number>;
  };

  try {
    const response = await fetchWithRetry(url, finalOptions);
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`Request failed with status ${response.status}`);
    }
  } catch (error) {
    log.error(
      `An error occurred: ${
        error instanceof Error ? error.message : 'Unknown error'
      }`,
    );
    throw error;
  }
}
