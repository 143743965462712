import { useBreakpoint } from 'src/hooks';
import { Link } from 'react-router-dom';

export const LoginHeader = () => {
  const { isMobile } = useBreakpoint();

  return (
    <div className="nj-auth-header-right-side no-gap">
      {!isMobile && <span>Don't have an account? &nbsp;</span>}
      <Link to="/sign-up" className="nj-button nj-auth-link">
        Sign up
      </Link>
    </div>
  );
};
