import { useBreakpoint, useSession } from 'src/hooks';
import { useBanner } from 'src/hooks/useBanner';
import classNames from 'classnames';
import { Warning, X } from '@phosphor-icons/react';
import { UserTierStatus } from 'src/types/models/UserTierStatus';
import { useGetUserBudgetQuotaInfoQuery } from 'src/store/services';
import { useContext, useEffect } from 'react';
import SessionContext from 'src/contexts/SessionContext';

const SVG_SIZE = 24;
const SVG_SIZE_MOBILE = 20;

export const LowCreditsBanner = () => {
  const { isProTier, appUser } = useSession();
  const { onChangeIsVisibleUpdatePlanModal } = useContext(SessionContext);

  const { data: quotaData } = useGetUserBudgetQuotaInfoQuery({
    user_id: appUser.user_id,
  });

  const { isTabletAndUp } = useBreakpoint();
  const { updateBannerType } = useBanner();

  useEffect(() => {
    if (
      isProTier &&
      appUser?.tier_status === UserTierStatus.OK &&
      !quotaData?.is_low_balance
    ) {
      updateBannerType(null);
    }
  }, [
    quotaData?.is_low_balance,
    isProTier,
    appUser?.tier_status,
    updateBannerType,
  ]);

  if (
    !isProTier ||
    appUser?.tier_status !== UserTierStatus.OK ||
    !quotaData?.is_low_balance
  ) {
    return null;
  }

  const handleRenewEarlyClick = () => {
    onChangeIsVisibleUpdatePlanModal(true);
  };

  return (
    <div
      className={classNames('nj-common-banner', {
        info: true,
      })}
    >
      <div className="nj-common-banner--left-side-wrapper">
        <Warning size={SVG_SIZE} weight="fill" />
        <div className="nj-common-banner--left-side-wrapper--notification-wrapper">
          <h5 className="nj-common-banner--title">
            {isTabletAndUp ? 'Credits are running low!' : 'Low credits'}
          </h5>
          {isTabletAndUp && (
            <p className="nj-common-banner--description ellipsis">
              Renew your subscription early to continue access to PRO features.
            </p>
          )}
        </div>
      </div>
      <div className="nj-common-banner--right-side-wrapper">
        <button
          type="button"
          className="nj-button nj-banner--renew-button"
          onClick={handleRenewEarlyClick}
          disabled={!!appUser?.stripe_info?.renew_early_pending_subscription}
        >
          Add credits
        </button>
        <button
          className="nj-common-banner--button-close"
          onClick={() => updateBannerType(null)}
        >
          <X size={isTabletAndUp ? SVG_SIZE : SVG_SIZE_MOBILE} />
        </button>
      </div>
    </div>
  );
};
