import { useContext } from 'react';
import { Bug } from '@phosphor-icons/react';
import { useHotkeys } from 'react-hotkeys-hook';
import DebugContext from 'src/contexts/DebugContext';
import { CommonNavigationButton } from '../CommonNavigationButton';

export const DebugButton = () => {
  const { debugMode, toggleDebugMode } = useContext(DebugContext);

  useHotkeys('ctrl+d', () => {
    toggleDebugMode();
  });

  return (
    <CommonNavigationButton
      dataE2E="debug-button"
      title="Debug"
      onClick={toggleDebugMode}
      isButtonSelected={debugMode}
      IconComponent={Bug}
    />
  );
};
