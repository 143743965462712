import { useAvatarPreferences } from 'src/hooks';
import { AvatarImage } from 'src/components/AvatarImage';
import { ResponseTag } from 'src/components/FlatAppearance/components/ResponseTag';
import { CommunicationStyle } from 'src/constants';
import { SkillLabel } from 'src/components/SkillLabel';
import { useMemo } from 'react';
import { ApiTaskSelectable } from 'src/types';

const AVATAR_SIZE = 24;

interface TagsSectionProps {
  currentTask: Partial<ApiTaskSelectable>;
}

export const TagsSection = ({ currentTask }: TagsSectionProps) => {
  const { communicationTonesOptions } = useAvatarPreferences();

  const persona = currentTask?.persona;

  const communicationTone = useMemo(
    () =>
      communicationTonesOptions.find(
        (item) => item.id === persona?.communication_tone_id,
      )?.title,
    [communicationTonesOptions, persona],
  );

  return (
    <div className="nj-task-view-header--tags">
      <AvatarImage persona={persona} size={AVATAR_SIZE} />
      {communicationTone && <ResponseTag title={communicationTone} />}
      {persona?.conversational_style && (
        <ResponseTag
          title={
            `Length: ${CommunicationStyle[persona.conversational_style]}` || ''
          }
        />
      )}
      <SkillLabel skill={currentTask?.skill} size="medium" />
    </div>
  );
};
