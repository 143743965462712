import { useEffect, useContext, RefObject } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { useThreads } from 'src/hooks';

export const useScrollToBottomThread = (
  endOfMessagesRef: RefObject<HTMLElement>,
) => {
  const { shouldScrollToBottom, onToggleScrollToBottom } =
    useContext(SessionContext);
  const { chatMessages } = useThreads();

  useEffect(() => {
    if (chatMessages.length > 0 && shouldScrollToBottom) {
      endOfMessagesRef.current?.scrollIntoView();
      onToggleScrollToBottom(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollToBottom, chatMessages.length]);
};
