import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from 'src/pages/AuthPages/LoginPage';
import { SignupPage } from 'src/pages/AuthPages/SignupPage';
import { ErrorLoginPage } from 'src/pages/AuthPages/ErrorLoginPage';
import { IS_SIGN_UP_REDIRECT } from 'src/utils';
import { LoginWithEmailPage } from 'src/pages/AuthPages/LoginWithEmailPage';
import { SignupWithEmailPage } from 'src/pages/AuthPages/SignupWithEmailPage';
import { ForgotPasswordPage } from 'src/pages/AuthPages/ForgotPasswordPage';
import { ResetPasswordPage } from 'src/pages/AuthPages/ResetPasswordPage';
import { VerificationFailedPage } from 'src/pages/AuthPages/VerificationFailedPage';
import { PasswordResetFailedPage } from 'src/pages/AuthPages/PasswordResetFailedPage';
import { IS_USER_PASSED_PASSWORD_PAGE } from 'src/constants';
import { TemporaryPasswordScreen } from 'src/pages/AuthPages/components/TemporaryPasswordScreen';
import { ManageTasksChatPage } from 'src/pages/ManageTasksChatPage';
import { VerifiedPage } from 'src/pages/AuthPages/VerifiedPage';
import { env } from 'src/env';
// import { CompleteAccountSetupPage } from 'src/pages/AuthPages/CompleteAccountSetupPage';

export const PublicContent = () => {
  const isOnboardingRedirect = localStorage.getItem(IS_SIGN_UP_REDIRECT);
  const isUserPassedPassPage =
    localStorage.getItem(IS_USER_PASSED_PASSWORD_PAGE) === '1' ||
    env.REACT_APP_ENVIRONMENT === 'production';

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />}></Route>
      <Route path="/login-with-email" element={<LoginWithEmailPage />}></Route>
      <Route path="/sign-up" element={<SignupPage />}></Route>
      <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
      <Route path="/reset-password" element={<ResetPasswordPage />}></Route>
      <Route
        path="/verification-failed"
        element={<VerificationFailedPage />}
      ></Route>
      <Route path="/verified" element={<VerifiedPage />}></Route>
      <Route
        path="/password-reset-failed"
        element={<PasswordResetFailedPage />}
      ></Route>
      <Route
        path="/sign-up-with-email"
        element={<SignupWithEmailPage />}
      ></Route>
      {/*todo uncomment with future implementation*/}
      {/*<Route*/}
      {/*  path="/complete-account-setup"*/}
      {/*  element={<CompleteAccountSetupPage />}*/}
      {/*></Route>*/}
      <Route path="/login-error" element={<ErrorLoginPage />}></Route>
      <Route
        path="/"
        element={
          isUserPassedPassPage ? (
            <ManageTasksChatPage />
          ) : (
            <TemporaryPasswordScreen />
          )
        }
      ></Route>
      <Route
        path="*"
        element={
          <Navigate to={isOnboardingRedirect ? '/sign-up' : '/'} replace />
        }
      />
    </Routes>
  );
};
