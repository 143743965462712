import { Modal } from 'react-responsive-modal';
import { XCircle } from '@phosphor-icons/react';
import { ANIMATION_TIMEOUT } from 'src/constants';
import classNames from 'classnames';
import { ZendeskTicketForm } from 'src/components/ZendeskSupport';
import { useContext } from 'react';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import SessionContext from 'src/contexts/SessionContext';

const SVG_SIZE = 24;

export const SupportModal = () => {
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { onChangeIsOpenSupportModal, isOpenSupportModal } =
    useContext(SessionContext);

  return (
    <Modal
      open={isOpenSupportModal}
      onClose={() => onChangeIsOpenSupportModal(false)}
      showCloseIcon={false}
      closeIcon={<XCircle size={SVG_SIZE} weight="fill" />}
      animationDuration={ANIMATION_TIMEOUT}
      container={rootContainerRef?.current || document.body}
      role="dialog"
      classNames={{
        root: 'nj-modal--root',
        overlay: 'nj-modal--overlay',
        modal: classNames(
          'nj-modal',
          'nj-modal--form',
          'nj-modal--form-zendesk',
        ),
      }}
      center
    >
      <ZendeskTicketForm onClose={() => onChangeIsOpenSupportModal(false)} />
    </Modal>
  );
};
