import { ThreadCodeSources } from 'src/components/FlatAppearance/components/ThreadCodeCard/components/ThreadCodeSources';
import type { CodeContent } from 'src/types/models/CodeContent';

interface CodeCardProps {
  codeCardSnippets?: Record<string, CodeContent>;
}

export const CodeCard = ({ codeCardSnippets }: CodeCardProps) => {
  if (!codeCardSnippets) {
    return null;
  }

  return (
    <div className="nj-task-scratchpad--code-card">
      <ThreadCodeSources codeSnippets={codeCardSnippets} />
    </div>
  );
};
