import { createApi } from '@reduxjs/toolkit/query/react';
import { ModelFeedback } from 'src/types';
import { baseFeedbackQuery } from './config';

export const feedbackApi = createApi({
  reducerPath: 'feedbackApi',
  baseQuery: baseFeedbackQuery,
  endpoints: (build) => ({
    sendFeedback: build.mutation<void, ModelFeedback>({
      query: (body) => ({
        url: '/internal_feedback',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useSendFeedbackMutation } = feedbackApi;
