import dayjs from 'dayjs';
import { ApiTaskSelectable } from 'src/types';
import { DEFAULT_DATE_FORMAT } from 'src/constants';

export const groupNotificationsByDate = (
  data: Partial<ApiTaskSelectable>[],
): Record<string, Partial<ApiTaskSelectable>[]> => {
  if (!data) {
    return {};
  }

  const sortData = [...data].sort((a, b) => {
    if (!a.created_at || !b.created_at) {
      return 0;
    }
    return b.created_at.localeCompare(a.created_at);
  });

  const acc: Record<string, Partial<ApiTaskSelectable>[]> = {};

  sortData.forEach((item) => {
    if (!item.created_at) {
      return;
    }

    const groupedDate = dayjs(item.created_at).format(DEFAULT_DATE_FORMAT);

    if (!acc[groupedDate]) {
      acc[groupedDate] = [item];
    } else {
      acc[groupedDate] = [...acc[groupedDate], item];
    }
  });

  return acc;
};
