import { createApi } from '@reduxjs/toolkit/query/react';
import { baseMatchmakerQuery } from './config';
import { MatchMakerQueryResponse, isAvatarQueue } from 'src/types';

export const matchmakerApi = createApi({
  reducerPath: 'matchmakerApi',
  baseQuery: baseMatchmakerQuery,
  endpoints: (builder) => ({
    getQueue: builder.query<MatchMakerQueryResponse, string>({
      query: (userId) => '/signallingserver?userID=' + userId,
      transformResponse: (response: MatchMakerQueryResponse) => {
        // typeguarding and converting the response to the right type
        // todo: add a proper application-wide error handling
        // to move out of throwing errors at some point
        if (!isAvatarQueue(response)) {
          throw new Error(
            `Invalid matchmaker api response: ${JSON.stringify(response)}`,
          );
        }
        return response;
      },
    }),
  }),
});

export const { useLazyGetQueueQuery } = matchmakerApi;
