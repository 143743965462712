import classNames from 'classnames';

interface CounterProps {
  number: number;
  className?: string;
}

export const Counter = ({ className, number }: CounterProps) => {
  return (
    <span className={classNames('nj-counter', className)} data-testid="counter">
      {number}
    </span>
  );
};
