import { useMemo } from 'react';
import classNames from 'classnames';
import {
  Archive,
  Check,
  PauseCircle,
  Prohibit,
  Rewind,
  Warning,
} from '@phosphor-icons/react';
import { TaskState } from 'src/types';
import { InProgressIcon } from 'src/components/InProgressIcon';

interface TaskStatusIconProps {
  taskState?: TaskState;
  size?: number;
  className?: string;
}

export const TaskStatusIcon = ({
  taskState,
  size = 20,
  className,
}: TaskStatusIconProps) => {
  const classes = classNames('nj-task-card--icon', className, {
    warning: taskState === TaskState.BLOCKED || taskState === TaskState.FAILED,
    accent: taskState === TaskState.UNDO_IN_PROGRESS,
    blinking:
      taskState === TaskState.HALTING || taskState === TaskState.STOPPING,
  });

  const statusIcon = useMemo(() => {
    switch (taskState) {
      case TaskState.IN_PROGRESS:
        return <InProgressIcon size={size} />;

      case TaskState.DONE:
        return <Check data-testid="check-icon" size={size} />;

      case TaskState.HALTING:
      case TaskState.STOPPING:
        return <Prohibit data-testid="prohibit-icon" size={size} />;

      // TODO(olha): add this case when status will be updated
      // case TaskState.HALTED:
      // return <Prohibit data-testid="prohibit-icon" size={size} />;

      case TaskState.ARCHIVED:
        return <Archive data-testid="archive-icon" size={size} />;

      case TaskState.UNDO_IN_PROGRESS:
        return <Rewind data-testid="archive-icon" size={size} />;

      case TaskState.FAILED:
        return <Warning data-testid="archive-icon" size={size} />;

      case TaskState.STOPPED:
        return <Prohibit data-testid="prohibit-icon" size={size} />;

      case TaskState.BLOCKED:
        return <PauseCircle data-testid="pause-icon" size={size} />;

      default:
        return <PauseCircle data-testid="pause-icon" size={size} />;
    }
  }, [taskState, size]);

  return (
    <span data-e2e={`status-icon-${taskState}`} className={classes}>
      {statusIcon}
    </span>
  );
};
