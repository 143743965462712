import { useMemo } from 'react';
import classNames from 'classnames';
import { MessageType, TaskSkill } from 'src/types';
import { getSkillFromMessage, uppercaseFirstLetter } from 'src/utils';

interface ResponseTagProps {
  title?: string;
  messageType?: MessageType;
  taskSkill?: TaskSkill;
}
export const ResponseTag = ({
  title,
  messageType,
  taskSkill,
}: ResponseTagProps) => {
  const skill = useMemo(
    () => messageType && getSkillFromMessage(messageType, taskSkill),
    [messageType, taskSkill],
  );

  const skillTitle = useMemo(
    () =>
      messageType === MessageType.CONVERSATION ? `${skill}: chit chat` : skill,
    [skill, messageType],
  );

  if (!title && !messageType) {
    return null;
  }

  return (
    <div className={classNames('nj-response-tag', skill)}>
      {skillTitle ? uppercaseFirstLetter(skillTitle) : title || ''}
    </div>
  );
};
