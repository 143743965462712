import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import {
  Bug,
  DotsThreeOutlineVertical,
  ThumbsDown,
  ThumbsUp,
  X,
} from '@phosphor-icons/react';
import { FeedbackType, TaskSkill } from 'src/types';
import React, { useContext } from 'react';
import DebugContext from 'src/contexts/DebugContext';
import {
  useCheckIsNinjaEmployee,
  useSession,
  useTheme,
  useVisible,
} from 'src/hooks';
import { Feedback } from 'src/types/models/Feedback';
import { useFeedback } from 'src/hooks/useFeedback';
import { Modal } from 'react-responsive-modal';
import ForwardRefContext from 'src/contexts/ForwardRefContext';
import { NegativeFeedbackAdditionalForm } from 'src/components/FlatAppearance/components/NegativeFeedbackAdditionalForm';

const ICON_PROPS = {
  size: 20,
  color: 'currentColor',
};

const SVG_SIZE = 24;

type TaskViewMenuProps = {
  taskId: string;
  taskFeedback?: Feedback;
  messageId: string;
  conversationId: string;
  taskSkill?: TaskSkill;
};

export const TaskViewMenu = ({
  taskId,
  taskFeedback,
  messageId,
  conversationId,
  taskSkill,
}: TaskViewMenuProps) => {
  const { isTasksListExpanded } = useTheme();
  const { appUser } = useSession();
  const { toggleDebugMode } = useContext(DebugContext);
  const { rootContainerRef } = useContext(ForwardRefContext);
  const { isVisible, handleVisibilityToggle, handleVisibilityRemove } =
    useVisible();
  const { onThumbsClick } = useFeedback({
    conversationId,
    messageId,
    taskId,
    feedback: taskFeedback,
    isShowNegativeFeedbackForm: isVisible,
    onToggleNegativeFeedbackForm: handleVisibilityToggle,
  });
  const isUserNinjaEmployee = useCheckIsNinjaEmployee();

  const negativeFeedbackObject = {
    feedback_id: taskFeedback?.feedback_id || '',
    user_id: appUser.user_id,
    conversation_id: conversationId || '',
    message_id: messageId || '',
    feedback_type: taskFeedback?.feedback_type,
    task_id: taskId,
  };

  return (
    <>
      <Menu
        gap={8}
        menuClassName="nj-multi-menu"
        menuButton={
          <MenuButton>
            <DotsThreeOutlineVertical {...ICON_PROPS} alt="Task menu" />
          </MenuButton>
        }
        align="end"
        transition
      >
        {isUserNinjaEmployee && (
          <MenuItem data-testid="rename-hash-btn" onClick={toggleDebugMode}>
            <Bug {...ICON_PROPS} /> Debug
          </MenuItem>
        )}

        <MenuItem
          data-testid="rename-hash-btn"
          onClick={() => {
            onThumbsClick(FeedbackType.POSITIVE);
          }}
        >
          <ThumbsUp
            weight={
              taskFeedback?.feedback_type === FeedbackType.POSITIVE
                ? 'fill'
                : 'regular'
            }
            {...ICON_PROPS}
          />
          <span>Good response</span>
        </MenuItem>

        <MenuItem
          data-testid="rename-hash-btn"
          onClick={() => {
            onThumbsClick(FeedbackType.NEGATIVE);
          }}
        >
          <ThumbsDown
            weight={
              taskFeedback?.feedback_type === FeedbackType.NEGATIVE
                ? 'fill'
                : 'regular'
            }
            {...ICON_PROPS}
          />
          <span>Bad response</span>
        </MenuItem>
      </Menu>
      <Modal
        open={isVisible}
        onClose={handleVisibilityRemove}
        container={rootContainerRef?.current || document.body}
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay',
          modal: `nj-task-negative-feedback-modal ${
            isTasksListExpanded ? 'withMargin' : ''
          }`,
        }}
        closeIcon={<X size={SVG_SIZE} />}
        center
      >
        <div className="nj-task-negative-feedback-modal-content">
          <h4 className="nj-task-negative-feedback-modal-title">
            Tell us more about this rating
          </h4>
          <NegativeFeedbackAdditionalForm
            onClose={handleVisibilityRemove}
            taskSkill={taskSkill}
            feedback={negativeFeedbackObject}
          />
        </div>
      </Modal>
    </>
  );
};
