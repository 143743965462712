import { baseUsersV2Query } from './config';
import { Conversation, Message, Tag } from '../../types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'reduxjs-toolkit-persist';

// TODO(ella): cache is not in use, please move the logic to conversationSlice
// extraReducer macher functions. Cache shows persistence issues in task and
// thread view, plus combus logic (one way flow where we receive into slices varied updates)
// works better with slices, this concerns only tasks and chat conversation,
// we are reducing endpoints calls per combus design.
export const conversationApi = createApi({
  reducerPath: 'conversationApi',
  baseQuery: baseUsersV2Query,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === REHYDRATE) {
      return action.payload?.[reducerPath];
    }
  },
  endpoints: (builder) => ({
    // https://tasks-api.beta.myninja.ai/v2/users/<user_id>/main_conversation
    getUserConversation: builder.query<Conversation, string>({
      query: (userId) => ({
        url: `/${userId}/main_conversation`,
      }),
      keepUnusedDataFor: 24 * 60 * 1000,
      transformResponse: (response: Conversation) => {
        if (Array.isArray(response.messages)) {
          const transformedMessages = response.messages?.map(
            (message: Message) => {
              // attention: adding tag for backwards compatibility for now
              return {
                ...message,
                conversation_id: response.conversation_id,
                tag: message?.tag || message?.message_type || Tag.CONVERSATION,
              };
            },
          );

          return { ...response, messages: transformedMessages };
        }

        return { ...response, messages: [] };
      },
    }),
    // https://tasks-api.beta.myninja.ai/v2/users/<user_id>/clear_conversation
    clearUserConversation: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/${userId}/clear_conversation`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetUserConversationQuery, useClearUserConversationMutation } =
  conversationApi;
