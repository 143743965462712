import { useMemo } from 'react';
import dayjs from 'dayjs';
import {
  tasksApi,
  userTasksState,
  markMessagesIsRead,
  useAppSelector,
  useAppDispatch,
} from 'src/store';
import { ApiTaskSelectable, MatchingItem, TaskState } from 'src/types';
import { getTaskDisplayDate, escapeRegExp } from 'src/utils';
import { DEFAULT_DATE_FORMAT } from 'src/constants';
import { useSession } from 'src/hooks';
import log from 'src/utils/logger';

export const useTasks = () => {
  const { tasks, pageToken } = useAppSelector(userTasksState);
  const { appUser } = useSession();

  const dispatch = useAppDispatch();

  const [updateTask] = tasksApi.useUpdateTaskMutation();
  const [updateTaskField] = tasksApi.useUpdateTaskFieldMutation();
  const [markAllReadTaskMessages] =
    tasksApi.useMarkAllReadTaskMessagesMutation();
  const [markAllUnreadTaskMessages] =
    tasksApi.useMarkAllUnreadTaskMessagesMutation();

  const sortedTasks = useMemo(() => {
    const tmp: Partial<ApiTaskSelectable>[] = Array.isArray(tasks)
      ? [...tasks]
      : [];
    const sorted = tmp.sort(
      (a: Partial<ApiTaskSelectable>, b: Partial<ApiTaskSelectable>) =>
        new Date(b.created_at || new Date()).getTime() -
        new Date(a.created_at || new Date()).getTime(),
    );
    return sorted;
  }, [tasks]);

  const selectTaskById = (taskId: string) => {
    if (taskId === 'default') return;
    return tasks.find(
      (task: Partial<ApiTaskSelectable>) => task.task_id === taskId,
    );
  };

  const getMatchingTasks = (search = '') => {
    // Items need to be sorted by date in order to display correctly in helper items
    const hashedList: MatchingItem[] = sortedTasks.map(
      (task: Partial<ApiTaskSelectable>) => {
        const createdDate = dayjs(
          task.created_at || new Date().toDateString(),
        ).format(DEFAULT_DATE_FORMAT);
        return {
          value: `${task.task_hash?.replace(/^#/, '')}`,
          option: `${task.task_hash?.replace(/^#/, '')}`,
          date: getTaskDisplayDate(createdDate),
        };
      },
    );

    if (search === '') return hashedList.slice(0, 5);

    const tasksList = hashedList
      .filter((item: MatchingItem) => {
        const regExp = new RegExp(
          `^${escapeRegExp(search).toLowerCase()}`,
          'gi',
        );
        return item.value.toLowerCase().match(regExp);
      })
      .slice(0, 5);

    return tasksList.length > 0 ? tasksList : hashedList.slice(0, 5);
  };

  const markTaskMessagesIsRead = (taskId: string, isRead: boolean) => {
    if (isRead) {
      markAllReadTaskMessages({
        taskId: taskId,
        userId: appUser.user_id,
      });
    } else {
      markAllUnreadTaskMessages({
        taskId: taskId,
        userId: appUser.user_id,
      });
    }
    dispatch(
      markMessagesIsRead({
        task_id: taskId,
        is_read: isRead,
        user_id: appUser.user_id,
      }),
    );
  };

  const markTaskAsDone = async (taskId: string) => {
    try {
      await updateTaskField({
        userId: appUser.user_id,
        taskId: taskId,
        task: {
          state: TaskState.HALTING,
        },
      });
    } catch (error: unknown) {
      log.error(error);
    }
  };

  const archiveTask = async (taskId: string) => {
    try {
      await updateTaskField({
        userId: appUser.user_id,
        taskId: taskId,
        task: {
          state: TaskState.ARCHIVED,
        },
      });
    } catch (error: unknown) {
      log.error(error);
    }
  };

  return {
    tasks: sortedTasks,
    tasksQuantity: sortedTasks.length,
    pageToken,
    selectTaskById,
    updateTask,
    getMatchingTasks,
    updateTaskField,
    markTaskMessagesIsRead,
    markTaskAsDone,
    archiveTask,
  };
};
