import { useEffect } from 'react';

export const useDocumentHeightChange = () => {
  useEffect(() => {
    // Workaround for issue "CSS3 100vh is not constant in the mobile browser"
    // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser
    function handleDocumentHeightChange() {
      const doc = document.documentElement;
      doc.style.setProperty('--vh', window.innerHeight * 0.01 + 'px');
    }

    window.addEventListener('resize', handleDocumentHeightChange);
    window.addEventListener('load', handleDocumentHeightChange);

    handleDocumentHeightChange();
    return () => {
      window.removeEventListener('resize', handleDocumentHeightChange);
      window.removeEventListener('load', handleDocumentHeightChange);
    };
  }, []);
};
