import { ConversationRole, Message } from 'src/types';
import { useSession } from 'src/hooks';

interface MessageAuthorNameProps {
  message: Message;
}

export const MessageAuthorName = ({ message }: MessageAuthorNameProps) => {
  const { agent } = useSession();

  const { role } = message;

  return (
    <span className="nj-message-author-name">
      {role === ConversationRole.USER ? 'You' : agent.first_name}
    </span>
  );
};
