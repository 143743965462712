import { useSession, useTasks } from 'src/hooks';
import { ApiTask } from 'src/types';
import log from 'src/utils/logger';

export const useRenameTask = (taskId?: string) => {
  const { appUser } = useSession();

  const { updateTaskField } = useTasks();

  const handleRenameTask = async (value: string) => {
    if (!taskId) {
      return;
    }

    const updatedTask: Partial<ApiTask> = {
      task_hash: value,
    };

    try {
      await updateTaskField({
        userId: appUser.user_id,
        taskId,
        task: updatedTask,
      }).unwrap();
    } catch (error) {
      log.error(error);
    }
  };

  return {
    onRenameTask: handleRenameTask,
  };
};
