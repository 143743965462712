import { ReactNode, useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { Prohibit } from '@phosphor-icons/react';
import { TaskState } from 'src/types';
import { StopTaskConfirmationModal } from 'src/pages/ManageTasksChatPage/components/StopTaskConfirmationModal';
import { useVisible } from 'src/hooks';
import { removeHashFromString } from 'src/utils';
import { ANIMATION_TIMEOUT } from 'src/constants';

const SVG_SIZE = 16;

interface StopTaskButtonProps {
  taskId: string;
  taskState: TaskState;
  taskHash?: string;
  buttonName?: string | ReactNode;
  className?: string;
  onStop?: () => void;
}

export const StopTaskButton = ({
  taskId,
  taskState,
  taskHash,
  buttonName,
  className,
  onStop,
}: StopTaskButtonProps) => {
  const { isVisible, handleVisibilityRemove, handleVisibilitySet } =
    useVisible();

  const containerRef = useRef<HTMLButtonElement>(null);

  const isStopButtonVisible = useMemo(
    () =>
      taskState === TaskState.IN_PROGRESS || taskState === TaskState.BLOCKED,
    [taskState],
  );

  const taskName = removeHashFromString(taskHash || '');

  return (
    <>
      <CSSTransition
        in={isStopButtonVisible}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-fade-out"
        unmountOnExit
        nodeRef={containerRef}
      >
        <button
          ref={containerRef}
          type="button"
          onClick={handleVisibilitySet}
          className={classNames(
            'nj-button nj-thread-combined-card--action-button',
            className,
          )}
        >
          {buttonName ? buttonName : <Prohibit size={SVG_SIZE} />}
        </button>
      </CSSTransition>

      <StopTaskConfirmationModal
        isOpen={isVisible}
        onClose={handleVisibilityRemove}
        taskId={taskId}
        taskName={taskName}
        onStop={onStop}
      />
    </>
  );
};
