import { useState, useContext, Fragment } from 'react';
import classNames from 'classnames';
import { Modal } from 'react-responsive-modal';
import ForwardRefContext, {
  ForwardRefContextType,
} from 'src/contexts/ForwardRefContext';
import { Button } from 'src/components/Button';
import { X, BugBeetle } from '@phosphor-icons/react';
import { ZendeskTicketForm } from 'src/components/ZendeskSupport';
import { useSession } from 'src/hooks';
import { ANIMATION_TIMEOUT } from 'src/constants';

const SVG_SIZE = 20;

type ReportIssueToZendeskButtonProps = {
  taskId?: string;
  messageId?: string;
  tags?: string[];
  buttonName?: string;
};

export const ReportIssueToZendeskButton = ({
  buttonName = 'Report the issue',
  ...rest
}: ReportIssueToZendeskButtonProps) => {
  const { isOpenTier } = useSession();
  const { rootContainerRef } =
    useContext<ForwardRefContextType>(ForwardRefContext);
  const [openZendeskModal, setOpenZendeskModal] = useState<boolean>(false);

  return (
    <Fragment>
      <Button
        className="nj-button"
        aria-label={buttonName}
        onClick={() => setOpenZendeskModal(true)}
        disabled={isOpenTier}
      >
        <BugBeetle size={SVG_SIZE} />
      </Button>
      <Modal
        open={openZendeskModal}
        onClose={() => setOpenZendeskModal(false)}
        showCloseIcon={false}
        closeIcon={<X size={SVG_SIZE} />}
        animationDuration={ANIMATION_TIMEOUT}
        container={rootContainerRef?.current || document.body}
        role="dialog"
        classNames={{
          root: 'nj-modal--root',
          overlay: 'nj-modal--overlay',
          modal: classNames(
            'nj-modal',
            'nj-modal--form',
            'nj-modal--form-zendesk',
          ),
        }}
        center
      >
        <ZendeskTicketForm
          {...rest}
          onClose={() => setOpenZendeskModal(false)}
        />
      </Modal>
    </Fragment>
  );
};
