import { createApi } from '@reduxjs/toolkit/query/react';
import { baseTasksQuery } from './config';
import { AvatarVideosList } from 'src/types/models/AvatarVideosList';
import { CreateAvatarVideoRequest } from 'src/types';

enum OfflineRecordingTags {
  AvatarVideos = 'AvatarVideos',
}

export const offlineRecordingApi = createApi({
  reducerPath: 'offlineRecordingApi',
  tagTypes: [OfflineRecordingTags.AvatarVideos],
  baseQuery: baseTasksQuery,
  endpoints: (build) => ({
    getAvatarVideos: build.query<AvatarVideosList, string>({
      query: (id) => ({
        url: `avatar/videos?user_id=${id}&sort_order=asc&limit=1000`,
      }),
      providesTags: () => [OfflineRecordingTags.AvatarVideos],
    }),

    deleteVideoById: build.mutation<{ video_id: string }, string>({
      query: (videoId) => ({
        url: `avatar/videos/${videoId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(videoId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          offlineRecordingApi.util.updateQueryData(
            'getAvatarVideos',
            videoId,
            (draft) => ({
              ...draft,
              data: (draft.data || []).filter(
                (item) => item.video_id !== videoId,
              ),
            }),
          ),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    generateOfflineVideo: build.mutation<
      { video_id: string },
      CreateAvatarVideoRequest
    >({
      query: (body) => ({
        url: 'avatar/videos/render',
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({ ...body, preview: false }),
      }),
    }),
  }),
});

export const {
  useGetAvatarVideosQuery,
  useGenerateOfflineVideoMutation,
  useDeleteVideoByIdMutation,
} = offlineRecordingApi;
