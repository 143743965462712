import { useCollapse } from 'react-collapsed';
import { CollapsedButton } from 'src/components/FlatAppearance/components/CollapsedButton';
import { StreamingText } from 'src/components/StreamingText';
import { ProgressBar } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/ProgressBar';

interface CodeSourceProps {
  title: string;
  content: string;
  icon: JSX.Element;
  isExpandedByDefault: boolean;
  isStreaming?: boolean;
}

export const CodeSource = ({
  title,
  content,
  icon,
  isExpandedByDefault,
  isStreaming,
}: CodeSourceProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: isExpandedByDefault,
  });

  return (
    <li className="nj-thread-research-card--source nj-thread-research-card--code-source">
      <div
        className="nj-thread-research-card--source-header"
        {...getToggleProps()}
      >
        <h6 className="nj-thread-research-card--source-title">
          {icon}
          <span>{title}</span>
        </h6>

        <CollapsedButton isExpanded={isExpanded} />
      </div>

      <div {...getCollapseProps()}>
        {content.length > 0 ? (
          <div>
            <StreamingText
              content={content}
              isStreaming={isStreaming}
              isExpanded={isExpanded}
            />
          </div>
        ) : (
          <ProgressBar count={3} />
        )}
      </div>
    </li>
  );
};
