import { CircleNotch } from '@phosphor-icons/react';
import classNames from 'classnames';

const SVG_SIZE = 24;

type SpinnerProps = {
  inline?: boolean;
  visibility?: boolean;
  size?: number;
};

export const Spinner = ({
  inline = false,
  visibility = true,
  size = SVG_SIZE,
}: SpinnerProps) => {
  return (
    <div className={classNames('nj-spinner', { inline, visibility })}>
      <CircleNotch size={size} />
      {!inline && <span>Loading...</span>}
    </div>
  );
};
