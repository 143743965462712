import log from 'src/utils/logger';
import { useRenewSubscriptionMutation } from 'src/store/services';
import { useSession } from 'src/hooks';
import {
  RENEW_EARLY_ANNUALLY_OPTIONS,
  RENEW_EARLY_MONTHLY_OPTIONS,
} from 'src/constants';
import { PlanOption } from 'src/components/UpgradeModal/components/PlanOption';
import './RenewEarllyConfirmationContent.scss';
import { PaymentPlanInterval, PaymentsSubscriptionInfo } from 'src/types';
import { MonthlyAnnuallySwitcher } from 'src/components/RenewEarlyButton/components/RenewEarlyConfirmationContent/components/MonthlyAnnuallySwitcher';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

type RenewEarlyConfirmationContentProps = {
  onCloseModal: () => void;
  subscriptionInfo?: PaymentsSubscriptionInfo;
};

export const RenewEarlyConfirmationContent = ({
  onCloseModal,
  subscriptionInfo,
}: RenewEarlyConfirmationContentProps) => {
  const [isAnnually, setIsAnnually] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<
    string | null
  >(null);
  const { appUser, setRenewEarlyPending } = useSession();
  const [renewSubscription, { isLoading: isRenewSubscriptionLoading }] =
    useRenewSubscriptionMutation();

  useEffect(() => {
    if (subscriptionInfo?.price_info?.lookup_key) {
      setSelectedSubscription(subscriptionInfo?.price_info?.lookup_key);
    }
  }, [subscriptionInfo?.price_info?.lookup_key]);

  useEffect(() => {
    if (subscriptionInfo?.interval) {
      if (
        subscriptionInfo?.interval === PaymentPlanInterval.MONTHLY &&
        isAnnually
      ) {
        setIsAnnually(false);
      }
      if (
        subscriptionInfo?.interval === PaymentPlanInterval.ANNUALLY &&
        !isAnnually
      ) {
        setIsAnnually(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionInfo?.interval]);

  const handleRenewEarlyClick = async () => {
    try {
      await renewSubscription({
        user_id: appUser.user_id,
        lookup_key: selectedSubscription,
      }).unwrap();
      setRenewEarlyPending();
      onCloseModal();
      toast('Processing plan update');
    } catch (error) {
      log.error(error);
    }
  };

  const options = isAnnually
    ? RENEW_EARLY_ANNUALLY_OPTIONS
    : RENEW_EARLY_MONTHLY_OPTIONS;

  return (
    <div className="nj-renew-early-confirmation-modal-content">
      <h3 className="nj-renew-early-confirmation-modal-title">Update plan</h3>

      <p className="nj-renew-early-confirmation-modal-description">
        Your updated subscription begins today and we will charge your credit
        card accordingly. Any available credits will be carried over to your new
        plan.
      </p>

      <div className="nj-renew-early-confirmation-modal-switcher-wrapper">
        <MonthlyAnnuallySwitcher
          isAnnually={isAnnually}
          onChangeValue={setIsAnnually}
        />
      </div>

      <div className="nj-renew-early-confirmation-modal-plans-wrapper">
        {options.map((item) => (
          <PlanOption
            key={item.lookup_key}
            option={item}
            isActive={item.lookup_key === selectedSubscription}
            onClick={() => {
              setSelectedSubscription(item.lookup_key);
            }}
          />
        ))}
      </div>

      <p className="nj-renew-early-confirmation-modal-hint">
        *Estimated task amount depends on the complexity of the task
      </p>

      <div className="nj-renew-early-confirmation-modal-button-wrapper">
        <button type="button" className="cancel" onClick={onCloseModal}>
          Cancel
        </button>
        <button
          type="button"
          className="renew"
          onClick={handleRenewEarlyClick}
          disabled={isRenewSubscriptionLoading || selectedSubscription === null}
        >
          Update now
        </button>
      </div>
    </div>
  );
};
