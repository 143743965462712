/* eslint-disable @typescript-eslint/no-unused-vars */
import { CodeSourceTypes } from 'src/types';
import type { CodeContent } from 'src/types';
import { useSession } from 'src/hooks';
import { CodeSource } from 'src/components/FlatAppearance/components/ThreadCodeCard/components/CodeSource';
import { sourcesSet } from './constants/sourcesSet';
import { SettingsMessageResearchCodeCard } from 'src/components/FlatAppearance/components/ThreadCombinedCard/components/SettingsMessageResearchCodeCard';

interface ThreadResearchSourcesProps {
  codeSnippets?: Record<string, CodeContent>;
  isStreaming?: boolean;
}

// TODO(olha): should be deprecated
export const ThreadCodeSources = ({
  codeSnippets,
  isStreaming,
}: ThreadResearchSourcesProps) => {
  const { isOpenTier } = useSession();

  if (!codeSnippets) {
    return null;
  }

  const sources = Object.entries(codeSnippets).map(([key, value]) => ({
    key,
    content: value.raw_text,
    ...sourcesSet[key as CodeSourceTypes],
    title:
      value.model?.human_friendly_name ||
      sourcesSet[key as CodeSourceTypes].title,
  }));

  const shouldShowSettingsMessage =
    !isOpenTier && sources.every(({ ninjaModel }) => ninjaModel);

  return (
    <>
      <ul>
        {sources
          .slice()
          .sort((a, b) => a.order - b.order)
          .map(({ key, ...item }, index) => (
            <CodeSource
              key={key}
              isExpandedByDefault={index === 0}
              {...item}
              isStreaming={isStreaming}
            />
          ))}
      </ul>

      {/* {shouldShowSettingsMessage && (
        <SettingsMessageResearchCodeCard cardType="coder" />
      )} */}
    </>
  );
};
