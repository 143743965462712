import { useSearchParams } from 'react-router-dom';
import { QueryParams } from 'src/types';

export const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  const params: Partial<QueryParams> = {};

  searchParams.forEach((value, key) => {
    params[key as keyof QueryParams] = value;
  });

  return params;
};
