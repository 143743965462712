interface ProgressCircleProps {
  percent?: number;
  size?: number;
  strokeWidth?: number;
}

export const ProgressCircle = ({
  percent = 0,
  size = 40,
  strokeWidth = 3,
}: ProgressCircleProps) => {
  const progressRadius = size / 2;
  const circleRadius = progressRadius - strokeWidth / 2;

  const circumference = 2 * Math.PI * circleRadius;
  const strokeDashoffset = circumference - (circumference * percent) / 100;

  return (
    <div className="nj-progress-circle" style={{ width: size, height: size }}>
      <svg
        className="nj-progress-circle--svg"
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          className="nj-progress-circle--back"
          cx={progressRadius}
          cy={progressRadius}
          r={circleRadius}
          style={{
            strokeWidth,
          }}
        />
        <circle
          className="nj-progress-circle--bar"
          cx={progressRadius}
          cy={progressRadius}
          r={circleRadius}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset,
            strokeWidth,
          }}
        />
      </svg>
      <span className="nj-progress-circle--text">{`${percent}%`}</span>
    </div>
  );
};
