/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ResearchLlmOpenAiModel {
  GPT_3_5_TURBO_1106 = 'gpt-3.5-turbo-1106',
  GPT_4_1106_PREVIEW = 'gpt-4-1106-preview',
}
